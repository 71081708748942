import useAxios, { globalConfig } from './useAxios';
import { REFRESH_TOKEN_URL } from '../api/urlConfig';
import { RootState } from '../redux/store';
import { useAppSelector } from './useStore';
// import useCustomAxios from './useCustomAxios';
import customAxiosApi from '../api/axios';
import Crypto from "../utils/crypto";
import { decryptReduxValue, encryptData } from '../utils/functions';

const useRefreshToken = () => {
    const authRedux: any = useAppSelector((state: RootState) => (state.auth));

    const refresh = async () => {
        
        const response = await customAxiosApi.post(REFRESH_TOKEN_URL!, 
            encryptData( { ky: decryptReduxValue(authRedux.authAccountNumber)} ),
            {
                ...globalConfig, 
            });
                
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
