import { useRef } from "react";
import { IConfirmationPageProps } from "../../../../interfaces/auth";
import { NAIRA_SYMBOL, SERVICE_TYPES, SYMBOL_TYPES } from "../../../../utils/constants";
import { capitalize, formatKoboValue, formatTableAmount, removeCommas } from "../../../../utils/functions";
import "./styles/confirm.scss";

function Confirmation(props: IConfirmationPageProps) { 
  const amountRef = useRef<any>();
  amountRef.current = "";
    
  // Set the amount to two decimal places if present
  if(typeof props.amount !== "undefined"){
    amountRef.current = formatTableAmount(props.amount);
  }

  if(props.operationType === SERVICE_TYPES.AIRTIME){
    return (<>
      <h3 className="text-5 fw-400 mb-3 mb-sm-4">Confirm Airtime Purchase</h3>
      <hr className="mx-n3 mx-sm-n5 mb-4" />
      <p className="text-4 fw-400 mb-0">{">"} Source</p>
        <p className="mb-1 ms-3">{ props.sourceAccount }</p>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Beneficiary</p>
      <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Beneficiary number<span className="text-3 float-end">{ props.number }</span></p>
      <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Network<span className="text-3 float-end">{ props.network }</span></p>
      <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Airtime Amount <span className="text-3 float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
      <hr />
      <p className="text-4 fw-500"><span className="invisible">{"> "}&nbsp;</span>Total<span className="float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
     </>
    )
  }else if(props.operationType === SERVICE_TYPES.DATA){
    amountRef.current = formatTableAmount((props.datapack?.split("=")[0])?.slice(1) || "");

    return (<>
        <h3 className="text-5 fw-400 mb-3 mb-sm-4">Confirm Data Purchase</h3>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Source</p>
        <p className="mb-1 ms-3">{ props.sourceAccount }</p>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Beneficiary</p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Beneficiary number<span className="text-3 float-end">{ props.number }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Network:<span className="text-3 float-end">{ props.network }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Datapack: <span className="text-3 float-end">{ props.datapack?.split("=")[1] }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Amount: <span className="text-3 float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
        <hr />
        <p className="text-4 fw-500"><span className="invisible">{"> "}&nbsp;</span>Total<span className="float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
      </>
    )
  }else if(props.operationType === SERVICE_TYPES.WALLET_TRANSFER){
    return (<>
        <h3 className="text-5 fw-400 mb-3 mb-sm-4">Confirm Transaction</h3>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Source</p>
        <p className="mb-1 ms-3">{ props.sourceAccount }</p>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Beneficiary</p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Name:<span className="text-3 float-end">{" " + capitalize(props.beneficiaryName!) }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Account Number:<span className="text-3 float-end">{ props.number }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Amount:<span className="text-3 float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
        <hr />
        <p className="text-4 fw-500"><span className="invisible">{"> "}&nbsp;</span>Total<span className="float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
      </>
    )
  }else if(props.operationType === SERVICE_TYPES.BANK_TRANSFER){
    return (<>
        <h3 className="text-5 fw-400 mb-3 mb-sm-4">Confirm Bank Transaction</h3>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Source</p>
        <p className="mb-1 ms-3">{ props.sourceAccount }</p>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Beneficiary</p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Name:<span className="text-3 float-end">{capitalize(props.beneficiaryName as string) }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Account Number:<span className="text-3 float-end">{ props.number }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Bank Name:<span className="text-3 float-end">{ props.bankName }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Amount:<span className="text-3 float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
        <hr />
        <p className="text-4 fw-500"><span className="invisible">{"> "}&nbsp;</span>Total<span className="float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
      </>
    )
  }
  else if(props.operationType === SERVICE_TYPES.CABLETV_SUBSCRIPTION){
    return (<>
        <h3 className="text-5 fw-400 mb-3 mb-sm-4">Confirm CableTv Subscription</h3>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Source</p>
        <p className="mb-1 ms-3">{ props.sourceAccount }</p>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Beneficiary</p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>CableTv Provider:<span className="text-3 float-end">{ props.cableTvProvider }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Package:<span className="text-3 float-end">{ props.package }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Account Holder:<span className="text-3 float-end">{capitalize(props.beneficiaryName as string) }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Smartcard Number: <span className="text-3 float-end">{ props.cardnumber }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Subscription Amount: <span className="text-3 float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
        <hr />
        <p className="text-4 fw-500"><span className="invisible">{"> "}&nbsp;</span>Total<span className="float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
      </>
    )
  }
  else if(props.operationType === SERVICE_TYPES.ELECTRICITY_PAYMENT){
    return (<>
        <h3 className="text-5 fw-400 mb-3 mb-sm-4">Confirm Electricity Payment</h3>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Source</p>
        <p className="mb-1 ms-3">{ props.sourceAccount }</p>
        <hr className="mx-n3 mx-sm-n5 mb-4" />
        <p className="text-4 fw-400 mb-0">{">"} Beneficiary</p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Account Holder:<span className="text-3 float-end mod-capitalize">{ props.beneficiaryName as string }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Meter Number:<span className="text-3 float-end">{ props.number }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Disco:<span className="text-3 float-end">{ props.disco }</span></p>
        <p className="mb-1"><span className="invisible">{"> "}&nbsp;</span>Amount: <span className="text-3 float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
        <hr />
        <p className="text-4 fw-500"><span className="invisible">{"> "}&nbsp;</span>Total<span className="float-end">{NAIRA_SYMBOL}{ amountRef.current }</span></p>
      </>
    )
  }
  else{
    return <>
      <h3 className="text-5 fw-400 mb-3 mb-sm-4">Confirm transaction</h3>
      <hr className="mx-n3 mx-sm-n5 mb-4" />
    </>
  }
  
   
}

export default Confirmation;
