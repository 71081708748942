export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_ACCESS_URL = process.env.REACT_APP_BASE_ACCESS_URL;
export const CABLE_SINGLE_PAYMENT_URL =
  process.env.REACT_APP_CABLE_SINGLE_PAYMENT_URL;
export const ELECTRICITY_SINGLE_PAYMENT_URL =
  process.env.REACT_APP_ELECTRICITY_SINGLE_PAYMENT_URL;
export const AIRTIME_SELF_PURCHASE_URL =
  process.env.REACT_APP_AIRTIME_SELF_PURCHASE_URL;
export const AIRTIME_OTHERS_PURCHASE_URL =
  process.env.REACT_APP_AIRTIME_OTHERS_PURCHASE_URL;
export const GLO_DATA_PURCHASE_URL =  
  process.env.REACT_APP_GLO_DATA_PURCHASE_URL;
export const OFFNET_DATA_PURCHASE_URL =
  process.env.REACT_APP_OFFNET_DATA_PURCHASE_URL;
export const OFFNET_DATA_BUNDLE_LIST_URL = process.env.REACT_APP_OFFNET_DATA_BUNDLE_LIST_URL;
export const GLO_DATA_BUNDLE_LIST_URL = process.env.REACT_APP_GLO_DATA_BUNDLE_LIST_URL;
export const BANK_LIST_URL = process.env.REACT_APP_BANK_LIST_URL;
export const ACCOUNT_LOOKUP_URL = process.env.REACT_APP_ACCOUNT_LOOKUP_URL;
export const CABLE_TV_PACKAGES_URL =
  process.env.REACT_APP_CABLE_TV_PACKAGES_URL;
export const CABLE_TV_CUSTOMER_LOOKUP_URL =
  process.env.REACT_APP_CABLE_TV_CUSTOMER_LOOKUP_URL;
export const ELECTRICITY_DISCOS_URL =
  process.env.REACT_APP_ALL_ELECTRICITY_DISCOS_URL;
export const ELECTRICITY_CUSTOMER_LOOKUP_URL =
  process.env.REACT_APP_ELECTRICITY_CUSTOMER_LOOKUP_URL;
export const WALLET_TRANSACTION_HISTORY_URL =
  process.env.REACT_APP_WALLET_TRANSACTION_HISTORY_URL;
export const NON_WALLET_TRANSACTION_HISTORY_URL =
  process.env.REACT_APP_NON_WALLET_TRANSACTION_HISTORY_URL;
export const VIEW_PROFILE_URL = process.env.REACT_APP_VIEW_PROFILE_URL;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const CHECK_IF_REGISTERED_URL =
  process.env.REACT_APP_CHECK_IF_REGISTERED_URL;
export const CREATE_ACCOUNT_URL = process.env.REACT_APP_CREATE_ACCOUNT_URL;
export const SEND_OTP_SMS_URL = process.env.REACT_APP_SEND_OTP_SMS_URL;
export const TOKENS_URL = process.env.REACT_APP_TOKENS_URL;
export const REFRESH_TOKEN_URL = process.env.REACT_APP_REFRESH_TOKEN_URL;
export const VALIDATE_USSD_PIN_URL =
  process.env.REACT_APP_VALIDATE_USSD_PIN_URL;
export const SAVINGS_TO_WALLET_TRANSFER_URL =
  process.env.REACT_APP_SAVINGS_TO_WALLET_TRANSFER_URL;
export const LOG_LEVEL_URL = process.env.REACT_APP_LOG_LEVEL_URL;
export const CHECK_ACCOUNT_VALIDITY =
  process.env.REACT_APP_CHECK_ACCOUNT_VALIDITY;
export const VALIDATE_OTP_URL = process.env.REACT_APP_VALIDATE_OTP_URL;
export const GET_BENEFICIARY_PROFILE =
  process.env.REACT_APP_GET_BENEFICIARY_PROFILE;
export const VALIDATE_BVN_DETAILS_URL =
  process.env.REACT_APP_VALIDATE_BVN_DETAILS_URL;
export const SECURITY_QUESTIONS_URL =
  process.env.REACT_APP_SECURITY_QUESTIONS_URL;
export const VALIDATE_CUSTOMER_DETAILS_URL =
  process.env.REACT_APP_VALIDATE_CUSTOMER_DETAILS_URL;
export const RESET_PASSWORD_URL = process.env.REACT_APP_RESET_PASSWORD_URL;
export const SEND_DIGITAL_ACCESS_CODE =
  process.env.REACT_APP_SEND_DIGITAL_ACCESS_CODE;
export const VALIDATE_DIGITAL_ACCESS_CODE =
  process.env.REACT_APP_VALIDATE_DIGITAL_ACCESS_CODE;
export const VALIDATE_ACCOUNT_AND_SEND_OTP =
  process.env.REACT_APP_VALIDATE_ACCOUNT_AND_SEND_OTP;
export const VALIDATE_OTP_TO_ACCOUNT =
  process.env.REACT_APP_VALIDATE_OTP_TO_ACCOUNT;
export const GKALA_ACCOUNT_TRANSFER_URL =
  process.env.REACT_APP_GKALA_ACCOUNT_TRANSFER_URL;
export const OTHER_BANK_TRANSFER_URL =
  process.env.REACT_APP_OTHER_BANK_TRANSFER_URL;
export const VALIDATE_RESET_BY_SA = process.env.REACT_APP_VALIDATE_RESET_BY_SA;
export const VALIDATE_RESET_BY_BVN =
  process.env.REACT_APP_VALIDATE_RESET_BY_BVN;
export const UPDATE_PIN_URL = process.env.REACT_APP_UPDATE_PIN_URL;
export const UPDATE_NIN_URL = process.env.REACT_APP_UPDATE_NIN_URL;
export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
