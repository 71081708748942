import { APP_RESPONSES, RESPONSE_CODES } from "./constants";
import { toastFailure, toastSuccess } from "./toasts";
import Crypto from "./crypto";

const { decryptValue } = Crypto;

const formatAmount = (amount: string): typeof NaN | string => {
  let newValue = amount.replace(/,/g, "");

  if (newValue.length === 0) {
    return "";
  }

  if (isNaN(Number(newValue))) {
    return NaN;
  }

  let absoluteValue = Math.abs(Number(newValue));

  let formattedAmount = String(absoluteValue).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  return formattedAmount;
};

const formatTableAmount = (amount: string | number): string => {
  let newValue =
    amount.toString().indexOf(",") > -1
      ? amount.toString().replace(/,/g, "")
      : amount.toString();

  if (newValue.length === 0) {
    return "";
  }

  let nairaAndKobo =
    newValue.indexOf(".") > -1 ? newValue.split(".") : newValue;

  let nairaValue = nairaAndKobo[0];

  let koboValue = nairaAndKobo[1];

  let absoluteValue: any = Array.isArray(nairaAndKobo)
    ? Math.abs(Number(nairaValue))
    : nairaAndKobo;

  // absoluteValue = Array.isArray(nairaAndKobo) ? `${absoluteValue}.${koboValue}` : absoluteValue;

  absoluteValue =
    Array.isArray(nairaAndKobo) && isProperKoboValue(koboValue.toString())
      ? `${absoluteValue}.${koboValue}`
      : Array.isArray(nairaAndKobo) && !isProperKoboValue(koboValue.toString())
      ? `${absoluteValue}.0${koboValue}`
      : Number(absoluteValue).toFixed(2);

  return String(absoluteValue).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const isProperKoboValue = (value: string) => {
  return !isNaN(Number(value)) && value.length === 2;
};

const capitalize = (title: string): string => {
  const allTitles = title.indexOf(" ") !== -1 ? title.split(" ") : [title];

  return allTitles
    .map((singleTitle: string) =>
      singleTitle.replace(/^(\w{1})/, ($1) => $1.toUpperCase())
    )
    .join(" ");
};

const removeCommas = (str: string): string => str.replace(/,/g, "");

const generateRandomNumber = () =>
  Date.now() + Math.floor(Math.random() * (99999 - 10000)) + 10000;

const handleServerResponse = (
  response: any,
  updateBalanceInStore: (balance: string) => void
) => {
  const { responsecode, responseCode } = response.data;

  if (successResponse([responseCode, responsecode])) {
    toastSuccess(response.data.responseMessage);

    updateBalanceInStore(response.data.walletbalance);
  } else {
    toastFailure(
      response.data.resultdescription || response.data.responseMessage
    );
  }
};

const getAbortSignalConfig = (signal: AbortSignal) => {
  return { signal };
};

const successResponse = (responsecodes: string[]) => {
  return !!responsecodes.find(
    (responsecode: string) => responsecode === RESPONSE_CODES.SUCCESS
  );
};

const prependCountryCode = (number: string) => {
  return `234${number.length === 11 ? number.slice(1) : number}`;
};

const encryptData = (data: any) => {
  const dataString = typeof data === "string" ? data : JSON.stringify(data);

  return Crypto.encryptValue(dataString);
};

const decimalPointFound = (input: string) => {
  return input.indexOf(".") !== -1;
};

const decimalPointMiddle = (input: string) => {
  const dotIndex = input.split("").indexOf(".");

  return dotIndex > 0 && dotIndex < input.length - 1;
};

const decimalPointEnd = (input: string) => {
  return input.split("").pop() === ".";
};

const hasNoValue = (variable: string | undefined) => {
  return (
    typeof variable === "undefined" ||
    variable.length === 0 ||
    variable === null
  );
};

const classNames = (...classes: any[]) => {
  return classes.filter(Boolean).join(" ");
};

const decryptReduxValue = (value: string) => {
  let reduxValue: any;

  try {
    reduxValue = JSON.parse(Crypto.decryptValue(value));
  } catch (err) {
    reduxValue = Crypto.decryptValue(value);
  }

  return reduxValue;
};

const getAllAccounts = (accounts: string) => {
  try {
    let response = decryptValue(accounts);

    return JSON.parse(response);
  } catch (error) {
    return [];
  }
};

const prependZero = (value: any) => {
  return String(value).length === 1 ? `0${value}` : value;
};

const addOneHourToDate = (date: Date) => {
  return new Date(date.getTime() + 60 * 60 * 1000);
};

const formatKoboValue = (value: any) => {
  return Number(value).toFixed(2);
};

export {
  formatAmount,
  capitalize,
  removeCommas,
  generateRandomNumber,
  handleServerResponse,
  getAbortSignalConfig,
  successResponse,
  prependCountryCode,
  encryptData,
  decimalPointFound,
  decimalPointMiddle,
  decimalPointEnd,
  hasNoValue,
  classNames,
  decryptReduxValue,
  getAllAccounts,
  formatTableAmount,
  prependZero,
  addOneHourToDate,
  formatKoboValue,
};
