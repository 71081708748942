import { createSlice } from "@reduxjs/toolkit";
import Crypto from "../../utils/crypto";

const initialState = {
    startDate: "",
    endDate: ""
}

const datePickerSlice = createSlice({
    name: "datePicker",
    initialState,
    reducers: {
        updateStartDate: (state, payload) => {
            state.startDate = Crypto.encryptValue(JSON.stringify(payload.payload))
            return state;
        },
        updateEndDate: (state, payload) => {
            state.endDate = Crypto.encryptValue(JSON.stringify(payload.payload))
            return state;
        }
    }
});

export const datePickerReducer = datePickerSlice.reducer;

export const { updateStartDate, updateEndDate } = datePickerSlice.actions;