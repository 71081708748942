import { toast } from "react-toastify";
import { APP_RESPONSES } from "./constants";

export const toastFailure = (message: string) => {
    // If message contains "XXX" like string, display default error message instead
    const toastMessage = message?.split("").every(char => char === "X") 
      ? APP_RESPONSES.ERROR_PROCESSING_REQUEST
      : message

    toast.warn(toastMessage, {
        autoClose:10000,
        draggable: true,
        hideProgressBar: true,
        position: toast.POSITION.TOP_RIGHT,
        className: "bg-danger text-white",
      });
}

export const toastSuccess = (message: string) => {
    toast.success(message, {
        autoClose:10000,
        draggable: true,
        hideProgressBar: true,
        position: toast.POSITION.TOP_RIGHT,
        className: "bg-success text-white",
      });
}