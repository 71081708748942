import { Link } from "react-router-dom";
import "./styles/airtime-data-home.scss";

function AirtimeDataHome() {
  return (
      <div className="p-4 mb-4 w-100 container-padding airtime-data">
            <div className="row gy-4 profile-completeness w-100 justify-content-center mx-0">
              <div className="col-5 col-sm-6 col-md-3">
                <Link to="airtime">
                  <div className="text-center px-3 py-4" id="airtime-box"> <span className="d-block text-10 text-light mt-2 mb-3"><i className="fas fa-bolt"></i></span> <span className="text-5 d-block text-success mt-4 mb-3"><i className="fas fa-check-circle"></i></span>
                    <p className="mb-0">Airtime</p>
                  </div>
                </Link>
              </div>
              <div className="col-5 col-sm-6 col-md-3">
                <Link to="data">
                  <div className="text-center px-3 py-4" id="data-box"> <span className="d-block text-10 text-light mt-2 mb-3"><i className="fas fa-wifi"></i></span> <span className="text-5 d-block text-success mt-4 mb-3"><i className="fas fa-check-circle"></i></span>
                    <p className="mb-0">Data</p>
                  </div>
                </Link>
              </div>
            </div>
      </div>
  );
}

export default AirtimeDataHome;
