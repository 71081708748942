import { Link } from "react-router-dom";
import "./styles/paybills.scss";

function PayBills() {
  return (
      <div className="p-4 mb-4 w-100 container-padding bill-payments">
            <div className="row gy-4 profile-completeness w-100 justify-content-center mx-0">
              <div className="col-5 col-sm-6 col-md-3">
                <Link to="cable-tv">
                  <div className="border rounded text-center px-3 py-4" id="tv-box"> <span className="d-block text-10 text-light mt-2 mb-3"><i className="fas fa-tv"></i></span> <span className="text-5 d-block text-success mt-4 mb-3"><i className="fas fa-check-circle"></i></span>
                    <p className="mb-0">Cable TV</p>
                  </div>
                </Link>
              </div>
              <div className="col-5 col-sm-6 col-md-3">
                <Link to="electricity">
                  <div className="border rounded text-center px-3 py-4" id="electricity-box"> <span className="d-block text-10 text-light mt-2 mb-3"><i className="fas fa-lightbulb"></i></span> <span className="text-5 d-block text-success mt-4 mb-3"><i className="fas fa-check-circle"></i></span>
                    <p className="mb-0">Electricity</p>
                  </div>
                </Link>
              </div>
            </div>
      </div>
  );
}

export default PayBills;
