import { useEffect, useRef, useState } from "react";
import { Power } from "react-bootstrap-icons";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { LOGOUT } from "../../redux/actions";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SiteLogo from "../../assets/mmpsb-logo.png";
import "./styles/topnavbar.scss";
import { routes } from "../../utils/constants";
import { toast } from "react-toastify";
import { decryptReduxValue } from "../../utils/functions";
import useAuth from "../../hooks/useAuth";

function TopNavbar() {
  const authRedux: any = useAppSelector((state: RootState) => (state.auth));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const logoutUser = () => {
    logout();
    toast.dismiss();
    //navigate('/login');
  }

  useEffect(() => {
    const navDrawerToggler = document.querySelector(".navbar-toggler");

    if(!navDrawerToggler) return

    const clickListener = () => {
      
      document.querySelector(".mobile-sub-menu")?.classList.toggle("mobile-active-sub-menu")
    
    }

    navDrawerToggler.addEventListener('click', clickListener);

    return () => { navDrawerToggler.removeEventListener('click', clickListener) }
  }, [])

  return (
    <header id="header-dashboard">
      
        <div className="header-row dashboard-header d-flex justify-content-between">

        <div className="header-column d-flex justify-content-between">
            <div className="logo logo-dashboard">
              {" "}
              <Link className="d-flex" to={decryptReduxValue(authRedux.user).agentname ? "/dashboard" : "/"} title="MoneyMaster">
                <img src={SiteLogo} className="img-logo-dashboard mt-0" alt="MoneyMaster" />
              </Link>{" "}
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" 
              aria-controls="navbarSupportedContent" 
              aria-expanded="false" 
              aria-label="Toggle navigation"
            >
              {" "}
              <span></span> <span></span> <span></span>{" "}
            </button>

            {/* <div className="collapse navbar-collapse w-100 bg-white" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 py-2" id="mobile-nav">
              {
                routes.map((route) => (<li key={route.main} className="nav-item w-100 text-center mb-2">
                  <Link to={route.path} className="p-2 mb-2 text-center">
                  <i className={`fas fa-${route.icon}`}></i> &nbsp; {route.icon === "bolt" ? <>&nbsp;</>: ''}{ route.main }
                  <span></span>
                  </Link>
                  </li>))
              }
            </ul>
            </div> */}
          </div>
        
       
          <div className="header-column justify-content-end d-flex align-items-center">
                  {
                    !decryptReduxValue(authRedux.user).agentname &&
                    <Link className="btn btn-primary signup-button" to="/signup/existing-user">
                      Sign Up
                    </Link>
                  }
                  
                  {
                    authRedux.isLoggedIn && 
                    <Power className="fs-4 text-success logout" onClick={ logoutUser } style={{ cursor: 'pointer' }} />
                  }
            
          </div>
        </div>
  
    </header>
  );
}

export default TopNavbar;
