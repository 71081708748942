import ReactDOM from "react-dom/client";
import Home from "./pages/Home/Home";
// import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import ProtectedRoutes from "./components/ProtectedRoutes";
import AirtimePurchase from "./pages/Dashboard/pages/AirtimePurchase/AirtimePurchase";
import DashboardHome from "./pages/Dashboard/pages/DashboardHome/Home";
import Dashboard from "./pages/Dashboard/Dashboard";
import AirtimeDataHome from "./pages/Dashboard/pages/AirtimeDataHome/AirtimeDataHome";
import DataPurchase from "./pages/Dashboard/pages/DataPurchase/DataPurchase";
import SendMoneyHome from "./pages/Dashboard/pages/SendMoneyHome/SendMoneyHome";
import Wallet from "./pages/Dashboard/pages/Wallet/Wallet";
import BankTransfer from "./pages/Dashboard/pages/BankTransfer/BankTransfer";
import PayBillsHome from "./pages/Dashboard/pages/PayBillsHome/PayBillsHome";
import CableTv from "./pages/Dashboard/pages/CableTv/CableTv";
import Electricity from "./pages/Dashboard/pages/Electricity/Electricity";
import TransactionsHistory from "./pages/Dashboard/components/TransactionsHistory/TransactionsHistory";
import NotFound from "./components/NotFound/NotFound";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import ErrorBoundary from "./pages/Dashboard/components/ErrorBoundary/ErrorBoundary";
import BVNSignup from "./pages/BVNSignup/BVNSignup";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import log from "loglevel";
import remote from "loglevel-plugin-remote";
import { BASE_ACCESS_URL, LOG_LEVEL_URL } from "./api/urlConfig";
import App from "./App";
import { OPERATION_HEADERS } from "./utils/constants";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import SelfServices from "./pages/Dashboard/pages/SelfServices/SelfServices";
import ResetPin from "./pages/Dashboard/pages/ResetPin/ResetPin";
import UpdateNin from "./pages/Dashboard/pages/UpdateNin/UpdateNin";

// Disable console log
if (process.env.REACT_APP_NODE_ENV === "prod") {
  if (!window.console) (window.console as any) = {};
  var methods = ["trace", "log", "debug", "warn", "error"];
  for (var i = 0; i < methods.length; i++) {
    (console as any)[methods[i]] = function () {};
  }
}
// End disable console log

const customJSON = (log: any) => ({
  "ib-error-log": log.message,
});

const customPlain = (log: any) => `ib-error-log: ${log.message}`;

log.enableAll();

remote.apply(log, {
  url: `${BASE_ACCESS_URL}${LOG_LEVEL_URL}`,
  method: "POST",
  format: customPlain,
});

export const routes = [
  {
    path: "/",
    element: (
      <ReactErrorBoundary FallbackComponent={ErrorBoundary}>
        <App />
      </ReactErrorBoundary>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
        breadcrumb: "Home",
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup/existing-user",
        element: <BVNSignup />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "privacy",
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: "/dashboard",
    // element: <ProtectedRoutes><Dashboard /></ProtectedRoutes>,
    element: (
      <ReactErrorBoundary FallbackComponent={ErrorBoundary}>
        <ProtectedRoutes>
          <Dashboard />
        </ProtectedRoutes>
      </ReactErrorBoundary>
    ),
    children: [
      {
        path: "",
        element: <DashboardHome />,
        breadcrumb: "Dashboard",
      },
      {
        path: "airtime-data",
        element: <AirtimeDataHome />,
        breadcrumb: "Airtime/Data",
      },
      {
        path: "airtime-data/airtime",
        element: <AirtimePurchase />,
        breadcrumb: "Airtime purchase",
      },
      {
        path: "airtime-data/data",
        element: <DataPurchase />,
        breadcrumb: "Data purchase",
      },
      {
        path: "send-money",
        element: <SendMoneyHome />,
        breadcrumb: "Transfer",
      },
      {
        path: "send-money/wallet",
        element: <Wallet />,
        breadcrumb: OPERATION_HEADERS.GKALA_TRANSFER,
      },
      {
        path: "send-money/bank-transfer",
        element: <BankTransfer />,
        breadcrumb: "Other Banks Transfer",
      },
      {
        path: "pay-bills",
        element: <PayBillsHome />,
        breadcrumb: "Pay Bills",
      },
      {
        path: "pay-bills/cable-tv",
        element: <CableTv />,
        breadcrumb: "Cable TV",
      },
      {
        path: "pay-bills/electricity",
        element: <Electricity />,
        breadcrumb: "Electricity",
      },
      {
        path: "transactions-history",
        element: <TransactionsHistory />,
        breadcrumb: "Transactions History",
      },
      {
        path: "self-services",
        element: <SelfServices />,
        breadcrumb: "Self Services",
      },
      {
        path: "self-services/reset-pin",
        element: <ResetPin />,
        breadcrumb: "Reset PIN",
      },
      {
        path: "self-services/update-nin",
        element: <UpdateNin />,
        breadcrumb: "Update NIN",
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router} />
      <ToastContainer />
    </PersistGate>
  </Provider>
);
