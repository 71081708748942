import "./styles/footerhome.scss";

function FooterHome() {
  return (
    <footer id="footer">
      <div className="container">
       
        <div className="text-end small">
       
              <p>
                {/* Copyright &copy; 2022 <a href="#">G-Kala</a>. All Rights
                Reserved. */}
                Powered By MoneyMaster PSB
              </p>

        </div>
        </div>
    
    </footer>
  );
}

export default FooterHome;
