import { createSlice } from "@reduxjs/toolkit";
import Crypto from "../../utils/crypto";

const initialState = {
    transactionEndpoint: "",
    transactionHistory: "",
    transactionHistoryAcctType: "",
    queryAcct: "",
    defaultHistoryAccount: "",
    isDefaultPage: ""
}

const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        setTranQueryAccount: (state, payload) => {
            return {
                ...state,
                defaultHistoryAccount: Crypto.encryptValue(payload.payload.accountNumber),
                isDefaultPage: Crypto.encryptValue(payload.payload.isDefaultPage)
            };
        },
        resetTranHistory: (state) => {
            return initialState;
        },
        logout: (state) => {
            return initialState;
        },
        setTranEndpoint: (state, payload) => {
            state.transactionEndpoint = payload.payload;
            return state;
        },
        transactionReset: (state, payload) => ({ ...initialState })
    }
});

export const transactionReducer = transactionSlice.reducer;

export const { logout, resetTranHistory, setTranEndpoint, setTranQueryAccount, transactionReset } = transactionSlice.actions;