export const SIGN_IN = 'SIGN_IN'
export const SIGN_UP = 'SIGN_UP'
export const LOGOUT = 'LOGOUT'
export const UPDATE_ACCESS_TOKEN = 'UPDATE_ACCESS_TOKEN'
export const STORE_OTP_CODE = 'STORE_OTP_CODE'
export const REGISTER_TOKEN = 'REGISTER_TOKEN'
export const CLEAR_REDUX_STORE = 'CLEAR_REDUX_STORE'
export const SAVE_ACCOUNT_DETAILS = 'SAVE_ACCOUNT_DETAILS'
export const UPDATE_STORE_BALANCE = 'UPDATE_STORE_BALANCE'
export const SET_TRANSACTION_ENDPOINT = 'SET_TRANSACTION_ENDPOINT'
export const SET_TRANSACTION_HISTORY = 'SET_TRANSACTION_HISTORY'
export const SET_TRANSACTION_QUERY_ACCOUNT = 'SET_TRANSACTION_QUERY_ACCOUNT'
export const RESET_TRANSACTION_HISTORY = 'RESET_TRANSACTION_HISTORY'
export const SESSION_TIMEOUT = 'SESSION_TIMEOUT'
export const AUTH_ACCOUNT_NUMBER = 'AUTH_ACCOUNT_NUMBER'