import { ReactElement, useLayoutEffect } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const ProtectedRoutes = ({ children }: { children: ReactElement }) => {
  const { userIsAuth } = useAuth();

  if (!userIsAuth()) {
    return <Navigate to="/login" />;
  } else if (userIsAuth()) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoutes;
