import { AxiosResponse } from "axios";
import useAxiosPrivate from "./useAxiosPrivate";
import { toastFailure } from "../utils/toasts";
import { 
    CABLE_SINGLE_PAYMENT_URL, 
    ELECTRICITY_SINGLE_PAYMENT_URL,
    AIRTIME_OTHERS_PURCHASE_URL,
    AIRTIME_SELF_PURCHASE_URL,
    GLO_DATA_PURCHASE_URL,
    OFFNET_DATA_PURCHASE_URL,
    GKALA_ACCOUNT_TRANSFER_URL,
    OTHER_BANK_TRANSFER_URL
 } from "../api/urlConfig";
import { globalConfig } from "../api/axios";
import { encryptData } from "../utils/functions";

interface IAirtimeSelf{
    agentcode: string,
    amount: string,
    pin: string,
    network?: string,
    otp?: string | undefined,
    destination: string,
    sourceAccountLabel?: string
}

export interface IAirtimeOthers extends IAirtimeSelf{
}

interface IDataSelf{
    agentcode: string,
    amount:string,
    network: string,
    pin: string,
    datapack?: string,
    destination?: string, 
}

interface IDataOthers extends IDataSelf{}

function useTransactions(){
    const axiosPrivate = useAxiosPrivate();

    // airtime self purchase
    const selfPurchase = async (requestPayload: IAirtimeSelf) => {
        
        return axiosPrivate.post(AIRTIME_SELF_PURCHASE_URL!, encryptData(requestPayload), globalConfig);

    }

    // airtime others purchase
    const othersPurchase = async (requestPayload: IAirtimeOthers) => {
        
        return axiosPrivate.post(AIRTIME_OTHERS_PURCHASE_URL!, encryptData(requestPayload), globalConfig);

    }

    const gloDataPurchase = async (requestPayload: IDataSelf | null) => {

        return axiosPrivate.post(GLO_DATA_PURCHASE_URL!, encryptData(requestPayload), globalConfig);

    }

    const offnetDataPurchase = async (requestPayload: IDataOthers | null) => {
        
        return axiosPrivate.post(OFFNET_DATA_PURCHASE_URL!, encryptData(requestPayload), globalConfig);

    }

    const transferToGKalaAccount = async (requestPayload:any) => {

        return axiosPrivate.post(GKALA_ACCOUNT_TRANSFER_URL!, encryptData(requestPayload), globalConfig);

    }

    const transferToBankAccount = async (requestPayload:any) => {
        // console.log('req bank:', requestPayload);
        return axiosPrivate.post(OTHER_BANK_TRANSFER_URL!, encryptData(requestPayload));

    }

    const bankToBankTransfer = async (requestPayload:any, bankToBankEndpoint: string) => {

        return axiosPrivate.post(bankToBankEndpoint, encryptData(requestPayload), globalConfig);

    }

    const cableSinglePayment = async (requestPayload:any) => {
        
        return axiosPrivate.post(CABLE_SINGLE_PAYMENT_URL!, encryptData(requestPayload), globalConfig);

    }

    const electricitySinglePayment = async (requestPayload:any) => {

        return axiosPrivate.post(ELECTRICITY_SINGLE_PAYMENT_URL!, encryptData(requestPayload), globalConfig);

    }

    return {
        selfPurchase,
        othersPurchase,
        gloDataPurchase,
        offnetDataPurchase,
        transferToGKalaAccount,
        transferToBankAccount,
        cableSinglePayment,
        electricitySinglePayment,
        bankToBankTransfer
    }
}

export default useTransactions;