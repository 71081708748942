import React, { useEffect, useRef, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useFilters,
  useAsyncDebounce,
  useGlobalFilter,
  TableInstance,
} from "react-table";
import { CSVLink, CSVDownload } from "react-csv";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./styles/table.scss";

import { APP_RESPONSES, DATE_PICKER_INPUT } from "../../../../utils/constants";

import { toastFailure } from "../../../../utils/toasts";

import DatePicker from "react-datepicker";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";

import "react-datepicker/dist/react-datepicker.css";
import { Button, PageButton } from "../Button/Button";
import {
  addOneHourToDate,
  generateRandomNumber,
} from "../../../../utils/functions";
import { toast } from "react-toastify";

interface ITableProps {
  columns: any[];
  data: any[];
  isLoading: boolean;
  fetchTransactionHistory: (a: Date | null, b: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (a: Date | null) => void;
  setEndDate: (e: Date | null) => void;
  setIsDateRangeRequest: ({
    startDate,
    endDate,
  }: {
    startDate: Date | null | string;
    endDate: Date | null | string;
  }) => void;
  // printTableToExcel: (printState: boolean) => boolean;
}

export default function Table({
  columns,
  data,
  isLoading,
  fetchTransactionHistory,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: ITableProps) {
  const defaultColumn: any = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter,
    selectedFlatRows,
    page,
    //rows,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 } as any,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;
  //const page = rows.slice(0, 10)

  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [endDate, setEndDate] = useState<Date | null>(null);
  const [downloadHistory, setDownloadHistory] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  /**
   * This function adds padding to each row of the transaction history table
   */
  const padRows = () => {
    const rowToPad = document.querySelectorAll("tbody tr");

    if (!rowToPad) return;

    rowToPad.forEach((row: any) => {
      row.style.lineHeight = 2.5;
    });
  };

  useEffect(() => {
    padRows();
  }, [page]);

  useEffect(() => {
    setLoadingData(isLoading);
  }, [isLoading]);

  const resetRowsPerPage = () => {
    setPageSize(10);
  };

  // Watches the state of the start and end date and triggers an api call to fetch the transaction history on change
  useEffect(() => {
    if (!startDate && !endDate) return;

    // callFetchTransactionHistory(startDate, endDate);
  }, [startDate, endDate]);

  // Downloads the history into an excel file
  useEffect(() => {
    if (!downloadHistory) return;

    // Click on the main download button
    const downloadButton: HTMLButtonElement | null = document.querySelector(
      "#test-table-xls-button"
    );

    if (downloadButton) downloadButton.click();

    // Reset the downloadHistory value
    setDownloadHistory(false);
  }, [pageSize, downloadHistory]);

  const listAllHistoryInTable = (ev: any) => {
    setPageSize(100000);
    setDownloadHistory(true);
  };

  // Datepicker styles
  const datePickerContainerStyles =
    data.length === 0
      ? {
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: "0.5rem",
        }
      : {};

  const rowContainerStyles =
    data.length === 0
      ? {
          color: "#006010",
          borderRadius: "4px",
          fontWeight: 500,
          paddingRight: "0rem",
        }
      : {};

  return (
    <div className="table-container container-padding w-100 bg-white">
      <div
        className="row justify-content-between features"
        style={rowContainerStyles}
      >
        <div
          className="left-section col-md-6 col-sm-12 d-flex mb-2 px-0"
          style={datePickerContainerStyles}
        >
          {/* Start Date and End Date Datepicker */}
          <section
            id="date-pickers"
            className="date-range d-flex justify-content-start align-items-center px-0"
            style={{ gap: "1rem" }}
          >
            <div className="d-flex">
              <strong className="col-auto d-flex justify-content-end">
                From:
              </strong>
              <DatePicker
                id="start-date"
                selected={startDate}
                maxDate={new Date()}
                dateFormat={"dd-MM-yyyy"}
                onChange={(date) => {
                  toast.dismiss();

                  if (date!.getTime() > endDate!.getTime()) {
                    toastFailure(APP_RESPONSES.DATE_PICKER_INVALID_START_DATE);

                    return;
                  }

                  setStartDate(date);

                  fetchTransactionHistory(date, endDate);
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>

            <div className="d-flex">
              <strong className="col-auto d-flex justify-content-end">
                To:
              </strong>
              <DatePicker
                id="end-date"
                selected={endDate}
                maxDate={new Date()}
                dateFormat={"dd-MM-yyyy"}
                onChange={(date) => {
                  toast.dismiss();

                  if (date!.getTime() < startDate!.getTime()) {
                    toastFailure(APP_RESPONSES.DATE_PICKER_INVALID_END_DATE);

                    return;
                  }

                  setEndDate(date);

                  fetchTransactionHistory(startDate, date);
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </section>
        </div>

        {data.length > 0 && (
          <div className="right-section col-md-6 col-sm-12 d-flex pe-0">
            <div className="col-md-6 col-sm-12 mb-2 pe-0">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>

            <div className="col-sm-12 col-md-6 px-0 d-grid mb-2 position-relative">
              <button
                className="btn btn-primary position-absolute w-100 download-in-excel"
                onClick={listAllHistoryInTable}
              >
                Download Statement
              </button>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-primary"
                table="table-to-xls"
                filename={`Statement-${generateRandomNumber()}`}
                sheet="tablexls"
                buttonText="Download Statement"
              />
            </div>
          </div>
        )}
      </div>

      {/* <hr /> */}

      {data.length > 0 && (
        <div className="mod-mt-2 mod-flex mod-flex-col">
          <div className="-mod-my-2 mod-overflow-x-auto -mod-mx-4 sm:-mod-mx-6 lg:-mod-mx-8">
            <div className="mod-py-2 mod-align-middle mod-inline-block mod-min-w-full sm:mod-px-6 lg:mod-px-4">
              <div className="mod-shadow mod-overflow-hidden mod-border-b mod-border-gray-200 sm:mod-rounded-lg">
                <table
                  {...getTableProps()}
                  id="table-to-xls"
                  className="table table-bordered table-striped mod-min-w-full mod-divide-y mod-divide-gray-200 mt-3"
                >
                  <thead className="transaction-title py-2 px-4 mod-bg-gray-50">
                    {headerGroups.map(
                      (headerGroup: {
                        getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLTableRowElement> &
                          React.HTMLAttributes<HTMLTableRowElement>;
                        headers: any[];
                      }) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, index) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              key={index}
                              className="mod-px-3 mod-py-3 mod-text-left mod-text-xs mod-font-medium mod-text-gray-500 mod-uppercase mod-tracking-wider mod-relative"
                            >
                              <div className="w-100 d-flex justify-content-between">
                                <span>{column.render("Header")}</span>
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? " 🔽"
                                      : " 🔼"
                                    : ""}
                                </span>
                                <i
                                  className="fa fa-filter position-relative mod-right-[0.1rem] mod-top-[0.2rem]"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </th>
                          ))}
                        </tr>
                      )
                    )}
                  </thead>
                  <tbody
                    {...getTableBodyProps()}
                    className="mod-bg-white mod-divide-y mod-divide-gray-200"
                  >
                    {page.map(
                      (
                        row: {
                          getRowProps: () => JSX.IntrinsicAttributes &
                            React.ClassAttributes<HTMLTableRowElement> &
                            React.HTMLAttributes<HTMLTableRowElement>;
                          cells: any[];
                        },
                        i: any
                      ) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} key={i}>
                            {row.cells.map((cell, index) => {
                              return (
                                <td
                                  label={row.cells[index].column.Header}
                                  {...cell.getCellProps()}
                                  className="mod-px-3 mod-py-4 mod-whitespace-nowrap text-sm"
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* New Pagination setup */}
      {data.length > 0 && (
        <div className="mod-py-3 d-flex mod-items-center mod-justify-between">
          <div className="mod-flex-1 d-flex justify-content-between sm:mod-hidden">
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          </div>
          <div className="d-none sm:mod-flex-1 sm:mod-flex sm:mod-items-center sm:mod-justify-between">
            <div className="d-flex mod-gap-x-2 mod-text-sm mod-text-gray-700 mod-items-baseline">
              Page{" "}
              <span className="mod-font-medium">{state.pageIndex + 1}</span> of{" "}
              <span className="mod-font-medium">{pageOptions.length}</span>
              <select
                className="mod-mt-1 mod-block mod-w-full mod-rounded-md mod-border-gray-300 mod-shadow-sm focus:mod-border-indigo-300 focus:mod-ring focus:mod-ring-indigo-200 focus:mod-ring-opacity-50 mod-p-2"
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <nav
                className="mod-relative mod-z-0 mod-inline-flex mod-rounded-md mod-shadow-sm -mod-space-x-px"
                aria-label="Pagination"
              >
                <PageButton
                  className="mod-rounded-l-md"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeftIcon
                    className="mod-h-5 mod-w-5"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon
                    className="mod-h-5 mod-w-5"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon
                    className="mod-h-5 mod-w-5"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  className="mod-rounded-r-md"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRightIcon
                    className="mod-h-5 mod-w-5"
                    aria-hidden="true"
                  />
                </PageButton>
              </nav>
            </div>
          </div>
        </div>
      )}

      {/* Displayed if no transaction history is found */}
      {!isLoading && data.length === 0 && (
        <h3 className="w-100 text-center mt-4">No transaction found</h3>
      )}
    </div>
  );
}

export const Checkbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) => {
  const count = preGlobalFilteredRows && preGlobalFilteredRows.length;

  return (
    <span className="h-100">
      {" "}
      <input
        id="filter-box"
        className="mod-block mod-w-full mod-rounded-md mod-border-gray-300 mod-shadow-sm mod-focus:border-indigo-300 mod-focus:ring mod-focus:ring-indigo-200 mod-focus:ring-opacity-50 form-control"
        // style={{ display: 'inline', padding: "0.4rem 2rem", verticalAlign: "middle"}}
        value={globalFilter || ""}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined);
        }}
        placeholder={`${count} total records...`}
      />
    </span>
  );
};

export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}: any) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};
