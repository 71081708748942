import { useEffect, useLayoutEffect, useState } from "react";
import parse from "html-react-parser";

const PrivacyPolicy = () => {
  useEffect(() => {
    /* Change the font size of all h5 */
    document.querySelectorAll("h5").forEach(function (header) {
      header.style.fontSize = "0.87rem";
      header.style.marginBottom = "0.2rem";
    });

    // Reduce the line height of every paragraph tag
    const paragraphs = document.querySelectorAll("p");
    paragraphs.forEach(function (paragraph) {
      paragraph.style.lineHeight = "1.2";
      paragraph.style.marginBottom = "0.2rem";
    });

    /* Display all text using opacity */
    const main = document.getElementById("main-container")!;

    if (main) main.style.opacity = "1";
  }, []);

  return (
    <div
      id="main-container"
      style={{ width: "90%", margin: "0 auto", opacity: "0" }}
    >
      <p
        style={{
          marginTop: "0cm",
          marginRight: "519.5pt",
          marginBottom: ".0001pt",
          marginLeft: "0cm",
          textAlign: "left",
          textIndent: "0cm",
          lineHeight: "160%",
        }}
      >
        <b>
          <span style={{ fontSize: "18.0pt", lineHeight: "160%" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </b>
      </p>
      <h5 style={{ marginLeft: "20.6pt", textIndent: "-21.35pt" }}>
        <span>
          1.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span>INTRODUCTION </span>
      </h5>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "13.9pt",
          marginLeft: "21.85pt",
        }}
      >
        <span>
          MoneyMaster Payment Service Bank (MMPSB) Limited respects your
          concerns about privacy and this Privacy Policy is in accordance with
          Nigerian Data Protection Regulations (NDPR) and outlines our privacy
          practices as it applies to information we collect from you on our
          websites, submitted documents and forms or through any other channel.{" "}
        </span>
      </p>
      <h5 style={{ marginLeft: "20.6pt", textIndent: "-21.35pt" }}>
        <span>
          2.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span>PURPOSE </span>
      </h5>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.2pt",
          marginLeft: "21.85pt",
        }}
      >
        <span>The Privacy Policy explains the following: </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.15pt",
          marginLeft: "36.0pt",
          textIndent: "-14.65pt",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span>
          How personally identifiable information collected from you through the
          website or other channel is used and with whom it may be shared.{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.35pt",
          marginLeft: "36.0pt",
          textIndent: "-14.65pt",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span>
          The rights available to you regarding the use of your data.{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.35pt",
          marginLeft: "36.0pt",
          textIndent: "-14.65pt",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span>
          The security procedures in place to protect your information.{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "14.05pt",
          marginLeft: "36.0pt",
          textIndent: "-14.65pt",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span>
          How you can correct any inaccuracies in the information we have.{" "}
        </span>
      </p>
      <h5 style={{ marginLeft: "17.25pt", textIndent: "-18.0pt" }}>
        <span>
          3.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span>SCOPE </span>
      </h5>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "13.85pt",
          marginLeft: "21.85pt",
        }}
      >
        <span>
          This policy applies to all users including all customers, employees of
          MoneyMaster Payment Service Bank (MMPSB) Limited and anyone accessing
          the Bank’s websites, digital platform and services.{" "}
        </span>
      </p>
      <h5 style={{ marginLeft: "20.6pt", textIndent: "-21.35pt" }}>
        <span>
          4.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span>POLICY </span>
      </h5>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.2pt",
          marginLeft: "21.85pt",
        }}
      >
        <span>
          This policy (together with our Terms and Conditions) sets out the
          basis on which any personal data we collect from you, or that you
          provide to us, will be processed and managed by us.&nbsp;{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "13.95pt",
          marginLeft: "21.85pt",
        }}
      >
        <span>
          By continuing to visit and use our website, mobile or digital
          platforms and other services we provide, you accept and consent to the
          practices described in this policy.{" "}
        </span>
      </p>
      <h5
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.7pt",
          marginLeft: "39.6pt",
          textIndent: "-21.6pt",
        }}
      >
        <span>
          4.1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
        </span>
        <span>INFORMATION COLLECTION, USE, AND SHARING </span>
      </h5>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: ".0001pt",
          marginLeft: "38.25pt",
          display: "flex",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          i.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          We at Money Master Payment Service Bank (MMPSB) Limited are
          responsible for the personal information collected or provided on our
          websites or via other channels. We only have access to collect
          information that you voluntarily give us on our websites, information
          provided via forms, email or other direct contact with you. We will
          not sell or rent this information to anyone.{" "}
        </span>
      </p>

      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: ".0001pt",
          marginLeft: "38.25pt",
          display: "flex",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          ii.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          We will use your personal identifiable information to provide services
          to you and to continue to improve, update, administer or execute those
          services for which you contacted us.&nbsp;{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: ".0001pt",
          marginLeft: "38.25pt",
          display: "flex",
        }}
      >
        <span>iii.</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style={{ fontFamily: '"Arial",sans-serif' }}> </span>
        <span>
          We will not share your personal information/data with any third party
          outside of our organization, other than as may be necessary to fulfill
          your request and as may be required or permitted by law and
          regulations.{" "}
        </span>
      </p>

      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: ".0001pt",
          marginLeft: "38.25pt",
          display: "flex",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          iv.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          We may contact you via the contact information you have provided in
          the future to tell you about new products, use of our services, or
          changes to this privacy policy.{" "}
        </span>
      </p>

      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "5.65pt",
          marginLeft: "38.25pt",
          display: "flex",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          v.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          Our websites use “cookies” to optimize functionality and to make your
          banking experience a good one. Some information we&nbsp; get are
          obtained by automated means when you visit our sites and may include
          the IP address of the device you use to connect to our services and
          the web pages you visited.{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: ".0001pt",
          marginLeft: "38pt",
        }}
      >
        <span>
          The information the cookies collect assists us to enhance your
          experience on our website and digital platforms.{" "}
        </span>
      </p>

      <h5 style={{ marginLeft: "39.6pt", textIndent: "-21.6pt" }}>
        <span>
          4.2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
        </span>
        <span>YOUR PRIVACY RIGHTS </span>
      </h5>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "12.05pt",
          marginLeft: "39.35pt",
        }}
      >
        <span>
          As an individual and data subject, you have some rights to your
          personal data and they include the following:{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "12.05pt",
          marginLeft: "38.25pt",
          display: "flex",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>The right to access data we have about you. </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "12.05pt",
          marginLeft: "71.25pt",
          textIndent: "-32.4pt",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          The right to rectify any inaccurate data we have about you.{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "12.05pt",
          marginLeft: "71.25pt",
          textIndent: "-32.4pt",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          The right to be informed of the actual or intended processing
          activity.{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "12.05pt",
          marginLeft: "71.25pt",
          textIndent: "-32.4pt",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>Request to have us delete personal data we have about you. </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "12.1pt",
          marginLeft: "71.25pt",
          textIndent: "-32.4pt",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>Express any concern you have about our use of your data. </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "12.05pt",
          marginLeft: "71.25pt",
          textIndent: "-32.4pt",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          Request the restriction of the processing of your Personal Data.{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.35pt",
          marginLeft: "71.25pt",
          textIndent: "-32.4pt",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          •
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          Request that personal data be transferred to a third party
          organization.{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "14.1pt",
          marginLeft: "39.35pt",
        }}
      >
        <span>
          However, these rights may be limited where applicable laws and
          regulations dictate otherwise.{" "}
        </span>
      </p>
      <h5 style={{ marginLeft: "39.6pt", textIndent: "-21.6pt" }}>
        <span>
          4.3.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
        </span>
        <span>SECURITY OF INFORMATION </span>
      </h5>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "12.75pt",
          marginLeft: "39.35pt",
        }}
      >
        <span>
          We take precautions to protect your information. When you submit
          sensitive information via the website, your information is protected
          both online and offline.{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: ".0001pt",
          marginLeft: "38.25pt",
          display: "flex",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          i.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          Wherever we collect sensitive information (such as personal data)
          online and that information is encrypted and transmitted to us in a
          secure way.{" "}
        </span>
      </p>

      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: ".0001pt",
          marginLeft: "38.25pt",
          display: "flex",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          ii.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          While we use encryption to protect sensitive information transmitted
          online, we also protect your information offline by ensuring
          confidentiality and integrity is maintained and access control is
          enforced.&nbsp;{" "}
        </span>
      </p>

      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: ".0001pt",
          marginLeft: "38.25pt",
          display: "flex",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          iii.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          The information technology infrastructure in which we store personally
          identifiable information are kept in a secure environment.{" "}
        </span>
      </p>

      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: ".0001pt",
          marginLeft: "38.25pt",
          display: "flex",
        }}
      >
        <span style={{ lineHeight: "103%" }}>
          iv.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>
          In the case where access and your use of our services requires your
          identification and authentication, you shall be responsible for the
          security and safety of your credential(s) such as User Name and
          Password, Personal Identification Number (PIN), One Time Passwords
          (OTP) and Tokens.{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "1.8pt",
          marginLeft: "0cm",
          textAlign: "left",
          textIndent: "0cm",
          lineHeight: "107%",
        }}
      >
        <span>&nbsp;</span>
      </p>
      <h5 style={{ marginLeft: "39.6pt", textIndent: "-21.6pt" }}>
        <span>
          4.4.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
        </span>
        <span>CONTACT INFORMATION </span>
      </h5>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.35pt",
          marginLeft: "39.35pt",
        }}
      >
        <span>
          Contact us for requests, questions or comments about this privacy
          policy or any other information via:&nbsp;{" "}
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.35pt",
          marginLeft: "39.35pt",
        }}
      >
        <span>Telephone: 0915 555 5995 or&nbsp; </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "12.3pt",
          marginLeft: "39.35pt",
        }}
      >
        <span>Email address: support@moneymasterpsb.com</span>
      </p>
      <p
        style={{
          margin: "0cm",
          marginBottom: ".0001pt",
          textAlign: "left",
          textIndent: "0cm",
          lineHeight: "107%",
        }}
      >
        <span>&nbsp;</span>
        <span
          style={{
            fontSize: "12.0pt",
            lineHeight: "107%",
            fontFamily: '"Times New Roman",serif',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "35.95pt",
          marginLeft: "0cm",
          textAlign: "left",
          textIndent: "0cm",
          lineHeight: "107%",
        }}
      >
        <span>&nbsp;</span>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
