import { Link } from "react-router-dom";
import SiteLogo from "../../assets/mmpsb-logo.png";
import "./styles/topnavbarhome.scss";

function TopNavbarHome() {

  return (
    <header id="header">
      <div className="container">
        <div className="header-row">
          <div className="header-column justify-content-start">
            <div className="logo me-3">
              {" "}
              <Link className="d-flex" to="/" title="MoneyMaster">
                <img src={SiteLogo} className="img-logo"  alt="MoneyMaster" />
              </Link>{" "}
            </div>

         
          </div>
          <div className="header-column justify-content-end">
                 
                    <Link className="btn btn-primary signup-button" to="/signup/existing-user">
                      <i className="fas fa-user-plus me-2 align-self-center"></i> Sign Up
                    </Link>
                  
                  
            
          </div>
        </div>
      </div>
    </header>
  );
}

export default TopNavbarHome;
