import { Link } from "react-router-dom";
import { IGlobalNotification } from "../../../../interfaces/auth";
import "./styles/notifications.scss";

const Notifications = ({
  notification,
}: {
  notification: IGlobalNotification;
}) => {
  return notification.canDisplay ? (
    <div
      className="row"
      style={{ justifyContent: "end", padding: "0rem 1.8rem" }}
    >
      <div className="col-12">
        <Link to={notification.link}>
          <button className="notification-btn btn btn-outline-success animate-bg-yellow-green">
            {notification.text} <i className="fas fa-sort-amount-up ms-2"></i>
          </button>
        </Link>
      </div>
    </div>
  ) : null;
};

export default Notifications;
