import {
  ChangeEvent,
  FormEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import SiteLogo from "../../assets/mmpsb-logo.png";
import useAuth from "../../hooks/useAuth";
import CryptoEncrypt from "../../utils/crypto";
import { RootState } from "../../redux/store";
import {
  APP_CONSTANTS,
  APP_RESPONSES,
  NIN_STATUS,
  RESPONSE_CODES,
} from "../../utils/constants";
import {
  AUTH_ACCOUNT_NUMBER,
  CLEAR_REDUX_STORE,
  LOGOUT,
  REGISTER_TOKEN,
  SIGN_IN,
  STORE_OTP_CODE,
  UPDATE_ACCESS_TOKEN,
} from "../../redux/actions";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { toastFailure } from "../../utils/toasts";
import { AxiosResponse } from "axios";
import "./styles/login.scss";
import { capitalize, successResponse } from "../../utils/functions";
import { toast } from "react-toastify";
import useDataInfo from "../../hooks/useDataInfo";
import {
  setAuthAccountNumber,
  setNinRegistered,
  signIn,
  updateAccessToken,
} from "../../redux/features/authSlice";
import PasswordReveal from "../Dashboard/components/PasswordReveal/PasswordReveal";

function Login() {
  const [formValues, setFormValues] = useState({
    accountNumber: "",
    password: "",
    encryptedPassword: "",
    otpCode: "",
  });

  const [loadSpinner, setLoadSpinner] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [loginStage, setLoginStage] = useState(0);
  const { loginUser, userIsAuth, getAccountProfile, logout } = useAuth();
  const { userAccounts: userAccountsRedux, auth: authRedux } = useAppSelector(
    (state: RootState) => state
  );
  // const userAccountsRedux = useMemo(() => (userAccounts), [userAccounts]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [initTokens, setInitTokens] = useState(false);
  const { getAllUserAccounts } = useDataInfo();
  const [displaySessionTimeoutMessage, setDisplaySessionTimeoutMessage] =
    useState(false);

  const clearStore = () => {
    dispatch({ type: CLEAR_REDUX_STORE });
  };

  useLayoutEffect(() => {
    setInitTokens(false);

    // clears all timeouts
    const highestId = window.setTimeout(() => {
      for (let i = highestId; i >= 0; i--) {
        window.clearTimeout(i);
      }
    }, 0);
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const formValid = () => {
    return (
      !isNaN(Number(formValues.accountNumber)) &&
      formValues.accountNumber.length === 10 &&
      formValues.password !== ""
    );
  };

  const filterProfileData = (profileData: Record<string, any>) => {
    delete profileData.responsecode;
    delete profileData.responseMessage;

    return profileData;
  };

  const resetLoading = () => {
    setIsSigningIn(false);
    setLoadSpinner(false);
  };

  // Navigates to the dashboard once accounts have been saved in redux store
  useEffect(() => {
    // If the accounts string does not have a value, return
    if (userAccountsRedux.accounts === "") return;

    navigate("/dashboard");
  }, [userAccountsRedux.accounts]);

  const getNinRegisteredStatus = (nin: string) => {
    return nin !== "" ? NIN_STATUS.REGISTERED : NIN_STATUS.UNREGISTERED;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isSigningIn) return;

    if (!formValid()) {
      toast.dismiss();
      toastFailure(APP_RESPONSES.INVALID_LOGIN_CREDENTIALS);
      return;
    }

    displaySpinner();

    // Save the account number the user logged in with to the redux store
    dispatch(setAuthAccountNumber(formValues.accountNumber));

    const cipherText = CryptoEncrypt.encryptValue(formValues.password);

    const loginCredentials = {
      userId: formValues.accountNumber,
      password: cipherText,
    };

    const controller = new AbortController();

    try {
      // Send user credentials to the login endpoint
      const loginResponse = await loginUser(loginCredentials);

      // Update redux store with new access token
      dispatch(updateAccessToken(loginResponse.data.accessToken));

      setTimeout(() => {
        const { responsecode, responseCode } = loginResponse.data;

        if (successResponse([responsecode, responseCode])) {
          if (!userIsAuth()) {
            getAccountProfile(formValues.accountNumber)
              .then((retrievedProfile: any) => {
                const { responsecode, responseCode } = retrievedProfile.data;

                if (successResponse([responsecode, responseCode])) {
                  const userProfile = filterProfileData(retrievedProfile.data);

                  const userPayloadObj = {
                    ...userProfile,
                    agentname: capitalize(userProfile.wallet_accts.agentname),
                  };

                  // Save user profile to redux store
                  dispatch(signIn({ ...userPayloadObj }));

                  // Save user nin registered status
                  dispatch(
                    setNinRegistered(getNinRegisteredStatus(userProfile.nin))
                  );

                  // Extract and save all user accounts to the redux store
                  getAllUserAccounts({ ...userPayloadObj });
                } else {
                  toast.dismiss();
                  toastFailure(retrievedProfile.data.resultdescription);
                  resetLoading();
                }
              })
              .catch((err: any) => {
                toast.dismiss();
                toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);
                resetLoading();
              });
          } else {
            navigate("/dashboard");
          }
        } else {
          toast.dismiss();
          toastFailure(loginResponse.data.responseMessage);
          resetLoading();
        }
      }, 2000);
    } catch (err: any) {
      //console.log(err);

      toast.dismiss();
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);
      resetLoading();
    }
  };

  const displaySpinner = () => {
    if (!loadSpinner) setLoadSpinner(true);
    setIsSigningIn(true);
  };

  // This useEffect hook monitors the autoLogout value in the redux store
  useEffect(() => {
    if (authRedux.autoLogout === false) return;
    setDisplaySessionTimeoutMessage(true);
  }, [authRedux.autoLogout]);

  return (
    <>
      <div id="main-wrapper">
        <div id="content" className="container-padding-x">
          <div className="container-fluid px-0">
            <div className="row g-0 min-vh-100">
              <div className="col-md-6 img-div">
                <div className="hero-wrap d-flex align-items-center h-100">
                  <div
                    className="hero-bg hero-bg-scroll"
                    style={{
                      backgroundImage: "url('./images/bg/image-8.jpg')",
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="container my-4">
                  <div className="row g-0">
                    <div className="col-10 col-lg-9 col-xl-8 mx-auto no-margin-mob container-width">
                      <div className="w-100 text-center">
                        <Link to="/" className="site-logo">
                          <img src={SiteLogo} className="w-50 my-4" />
                        </Link>
                      </div>
                      <h3 className="fw-400 mb-4">Log In</h3>

                      {/* Displayed on auto logout */}
                      {displaySessionTimeoutMessage && (
                        <div className="mb-3">
                          <label
                            htmlFor="autoLogout"
                            className="form-label py-1 px-2 text-white mod-rounded-md mod-bg-red-500/80"
                          >
                            {APP_RESPONSES.AUTO_LOGOUT_MESSAGE}
                          </label>
                        </div>
                      )}

                      {loginStage === 0 && (
                        <form
                          id="loginForm"
                          method="post"
                          onSubmit={handleSubmit}
                          autoComplete="off"
                        >
                          <div className="mb-3">
                            <label
                              htmlFor="accountNumber"
                              className="form-label"
                            >
                              Account Number
                            </label>
                            <input
                              type="text"
                              maxLength={10}
                              className="form-control"
                              id="accountNumber"
                              name="accountNumber"
                              required
                              placeholder="Enter Your Account Number"
                              value={formValues.accountNumber}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="loginPassword"
                              className="form-label"
                            >
                              Password
                            </label>

                            <PasswordReveal id="#password">
                              <input
                                type="password"
                                className="form-control tw-z-10"
                                id="password"
                                name="password"
                                placeholder="Password"
                                required
                                value={formValues.password}
                                onChange={handleChange}
                              />
                            </PasswordReveal>

                            {/* <div className="w-100 d-flex flex-column align-items-end justify-content-center">
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              name="password"
                              placeholder="Password"
                              required
                              value={formValues.password}
                              onChange={handleChange}
                            />
                            <PasswordReveal id={"#password"} />
                          </div> */}
                          </div>
                          <div className="row mb-3">
                            <div className="col-sm"></div>
                            <div className="col-sm text-end">
                              <Link className="btn-link" to="/forgot-password">
                                Forgot Password ?
                              </Link>
                            </div>
                          </div>
                          <div className="d-grid mb-3">
                            <button
                              id="login-btn"
                              className="btn btn-primary d-flex align-items-center justify-content-center"
                              type="submit"
                            >
                              {loadSpinner ? (
                                <span className="d-flex justify-content-center w-auto">
                                  <Spinner />
                                </span>
                              ) : (
                                <span className="d-flex">
                                  <i className="fas fa-sign-in-alt me-2 align-self-center"></i>{" "}
                                  Login
                                </span>
                              )}
                            </button>
                          </div>
                        </form>
                      )}
                      <p className="text-center text-muted">
                        <Link
                          className="btn-link w-100"
                          to="/signup/existing-user"
                        >
                          <span
                            className="btn btn-default text-primary w-100"
                            style={{
                              boxShadow: ".5px .5px 5px 1px rgba(0,0,0,0.2)",
                            }}
                          >
                            <i className="fas fa-user-plus me-2 align-self-center"></i>{" "}
                            Register on internet banking
                          </span>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
