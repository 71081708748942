import {
    ChangeEvent,
  FormEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./styles/reset-pin.scss";
import {
    APP_RESPONSES,
  OPERATION_HEADERS,
  RESET_PIN_STAGE,
  SELF_SERVICES,
} from "../../../../utils/constants";
import OperationHeader from "../../components/OperationHeader/OperationHeader";
import DatePicker from "react-datepicker";
import Spinner from "../../../../components/Spinner/Spinner";
import Continue from "../../../../components/ButtonsLabel/Continue";
import { toast } from "react-toastify";
import { toastFailure } from "../../../../utils/toasts";
import { useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import {
  VALIDATE_RESET_BY_BVN,
  VALIDATE_RESET_BY_SA,
} from "../../../../api/urlConfig";
import useDataInfo from "../../../../hooks/useDataInfo";
import { decryptReduxValue, encryptData, successResponse } from "../../../../utils/functions";
import TransactionResponse from "../../components/TransactionResponse/TransactionResponse";

const ResetPin = () => {
  const ACCESS_VALIDATION = useMemo(() => {
    return {
      SECURITY_ANSWER: "with Security Answer",
      BVN: "with BVN",
    };
  }, []);
  const ERROR_MESSAGES = useMemo(() => {
    return {
      SECURITY_ANSWER: "Please provide a Security Answer",
      BVN: "Please provide your BVN",
      DOB: "Please select your Date Of Birth"
    };
  }, []);
  const [formValues, setFormValues] = useState({
    securityAnswer: "",
    bvn: "",
    dob: undefined,
    pin: "",
    confirmPin: "",
    resetMessage: "",
    resetSuccessful: false
  });
  const [resetPinStage, setResetPinStage] = useState("");
  const [selectedValidationType, setSelectedValidationType] = useState(ACCESS_VALIDATION.SECURITY_ANSWER);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const { auth: authRedux } = useAppSelector((state: RootState) => state);
  const { verifyAccessValidation, doResetPin } = useDataInfo();

  // Hook initializes the Reset Pin stage
  useLayoutEffect(() => {
    setResetPinStage(RESET_PIN_STAGE.ACCESS_VALIDATION);
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isSecurityAnswer = () =>
    selectedValidationType === ACCESS_VALIDATION.SECURITY_ANSWER;

  const setDateOfBirth = (date: any) => {
    setFormValues((prev) => ({
      ...prev,
      dob: date,
    }));
  };

  const addOneHourToDate = (date: Date) => {
    return new Date(date.getTime() + 60 * 60 * 1000);
  };

  const accessValidationFormValid = () => {
    // If the selected access validation type is Security Answer, check the formValue for security answer
    // and same for BVN. If empty, set in errors object, the error message.

    const { securityAnswer, bvn, dob } = formValues;

    let formValid = true;

    let errors = {
        securityAnswer: "",
        bvn: "",
        dob: ""
    }

    switch (selectedValidationType) {
      case ACCESS_VALIDATION.SECURITY_ANSWER:
        errors ={
          securityAnswer:
            securityAnswer === "" ? ERROR_MESSAGES.SECURITY_ANSWER : "",
          dob: dob == undefined ? ERROR_MESSAGES.DOB : "",
          bvn: "",
        };

        formValid = securityAnswer !== "";
        break;
      case ACCESS_VALIDATION.BVN:
        errors = {
          bvn: bvn === "" ? ERROR_MESSAGES.BVN : "",
          dob: dob == undefined ? ERROR_MESSAGES.DOB : "",
          securityAnswer: "",
        };

        formValid = bvn !== "";
        break;
    }

    // Check if Date of birth has been selected
    errors = {
        ...errors,
        dob: dob == undefined ? ERROR_MESSAGES.DOB : "",
    };

    formValid = formValid && (dob != undefined);

    if(!formValid) toastFailure(mergeErrorMessages(errors));

    return formValid;
  };

  const mergeErrorMessages = (errors: any) => {
    return Object.values(errors)
      .filter((value) => value !== "")
      .join(" -> ");
  };

  const displaySpinner = () => setLoadSpinner(true);

  const hideSpinner = () => setLoadSpinner(false);

  const getAccessReqPayload = () => {
    let payload: any;

    switch (selectedValidationType) {
      case ACCESS_VALIDATION.SECURITY_ANSWER:
        payload = {
          securityAnswer: formValues.securityAnswer,
          dob: (formValues.dob as unknown as Date)!.toISOString().slice(0, 10),
          accountNumber: String(decryptReduxValue(authRedux.authAccountNumber)),
          clientType : "SELFCARE"
        };
        break;
      case ACCESS_VALIDATION.BVN:
        payload = {
          bvn: formValues.bvn,
          dob: (formValues.dob as unknown as Date)!.toISOString().slice(0, 10),
          accountNumber: String(decryptReduxValue(authRedux.authAccountNumber)),
          clientType : "SELFCARE"
        };
        break;
    }

    return payload;
  };

  const switchToEnterNewPIN = async () => {
    toast.dismiss();
    displaySpinner();

    if (!accessValidationFormValid()) {
      hideSpinner();
      return;
    }

    const URL_ENDPOINT =
      selectedValidationType === ACCESS_VALIDATION.SECURITY_ANSWER
        ? VALIDATE_RESET_BY_SA
        : VALIDATE_RESET_BY_BVN;

    try {
        // Make the call to the API to validate the user input
        const response = await verifyAccessValidation(getAccessReqPayload(), URL_ENDPOINT!);

        const { responsecode, responseCode, responseMessage } = response.data;
        
        hideSpinner();

        if(!successResponse([responsecode, responseCode])){
            toastFailure(responseMessage);
            return;
        }
        
        setResetPinStage(RESET_PIN_STAGE.NEW_PIN);    
    } catch (error: any) {
        toastFailure(error.response?.data?.responseMessage);
        hideSpinner();
    }
  };

  const handlePinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    if(isNaN(Number(value))) return;

    setFormValues((prev) => ({
        ...prev,
        [name] : value
    }))
  }

  const validatePin = () => {
    const { pin, confirmPin } = formValues;

    // Validate pin length
    if(pin.length !== 4 ){
        toastFailure(APP_RESPONSES.INVALID_PIN);
        hideSpinner();
        return false;
    }

    // Validate pin equals confirm pin
    if(pin !== confirmPin){
        toastFailure(APP_RESPONSES.PIN_MISMATCH);
        hideSpinner();
        return false;
    }

    return true;
  }

  const getPinResetPayload = () => {
    return {
        "pin": encryptData(formValues.pin),
        "accountNumber": String(decryptReduxValue(authRedux.authAccountNumber)),
        "clientType":"SELFCARE"
    }
  }

  const resetPin = async () => {
    toast.dismiss();

    displaySpinner();

    const isValidPin = validatePin();

    if(!isValidPin) return;

    try {        
        const response = await doResetPin(getPinResetPayload());

        // Display Final Response screen
        const { responsecode, responseCode, responseMessage } = response.data;
        
        hideSpinner();

        setFormValues({
            ...formValues,
            resetMessage: responseMessage,
            resetSuccessful: successResponse([responsecode, responseCode])
        })

        setResetPinStage(RESET_PIN_STAGE.FINAL_RESET_PIN_RESPONSE);
    } catch (error: any) {
        toastFailure(error.response?.data?.responseMessage);
        hideSpinner();
    }
    
  }

  return (
    <div className="row w-100 mt-5 justify-content-center align-items-center flex-column mb-3 container-padding">
      <div className="col-md-9 col-lg-7 col-xl-7 mx-auto container-padding">
        <form
          id="form-reset-pin"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          { (resetPinStage !== RESET_PIN_STAGE.FINAL_RESET_PIN_RESPONSE) && <OperationHeader title={OPERATION_HEADERS.RESET_PIN} />}

          {resetPinStage === RESET_PIN_STAGE.ACCESS_VALIDATION && (
            <>
              <div className="mb-3">
                <label htmlFor="access-validation" className="form-label">
                  Access Validation
                </label>
                <select
                  className="form-select"
                  name="access-validation"
                  required
                  onChange={(e) => setSelectedValidationType(e.target.value)}
                >
                  {Object.keys(ACCESS_VALIDATION).map(
                    (validationType: string) => (
                      <option
                        value={Object.create(ACCESS_VALIDATION)[validationType]}
                      >
                        {Object.create(ACCESS_VALIDATION)[validationType]}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="" className="form-label">
                  {isSecurityAnswer() ? "Enter Security Answer" : "Enter BVN"}
                </label>
                <input
                  type="text"
                  placeholder=""
                  className="form-control"
                  aria-label=""
                  name={`${isSecurityAnswer() ? "securityAnswer" : "bvn"}`}
                  required
                  value={`${
                    isSecurityAnswer()
                      ? formValues.securityAnswer
                      : formValues.bvn
                  }`}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="" className="form-label">
                  Date of birth
                </label>
                <br />
                <DatePicker
                  id="dob"
                  className="form-control w-100"
                  selected={formValues.dob}
                  maxDate={new Date()}
                  dateFormat={"yyyy-MM-dd"}
                  onChange={(date: any) =>
                    setDateOfBirth(addOneHourToDate(date!))
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>

              <div className="d-grid">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={switchToEnterNewPIN}
                >
                  {loadSpinner ? (
                    <span className="d-flex justify-content-center w-auto">
                      <Spinner />
                    </span>
                  ) : (
                    <Continue />
                  )}
                </button>
              </div>
            </>
          )}

          {
            resetPinStage === RESET_PIN_STAGE.NEW_PIN &&
            <>
                <div className="mb-3">
                    <label htmlFor="" className="form-label">
                        Enter New PIN
                    </label>
                    <input
                    type="password"
                    maxLength={4}
                    placeholder=""
                    className="form-control"
                    aria-label="pin"
                    name="pin"
                    required
                    value={formValues.pin}
                    onChange={handlePinChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="" className="form-label">
                        Confirm New PIN
                    </label>
                    <input
                    type="password"
                    maxLength={4}
                    placeholder=""
                    className="form-control"
                    aria-label="confirm pin"
                    name="confirmPin"
                    required
                    value={formValues.confirmPin}
                    onChange={handlePinChange}
                    />
                </div>
                <div className="d-grid">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={resetPin}
                >
                  {loadSpinner ? (
                    <span className="d-flex justify-content-center w-auto">
                      <Spinner />
                    </span>
                  ) : (
                    <span className="d-flex justify-content-center"><i className="fas fa-user-cog mb-015 me-2 align-self-center"></i> Reset PIN</span> 
                  )}
                </button>
              </div>
            </>
          }

          {
            resetPinStage === RESET_PIN_STAGE.FINAL_RESET_PIN_RESPONSE &&
            <TransactionResponse 
                operationType={SELF_SERVICES.RESET_PIN} 
                walletbalance={""} 
                success={formValues.resetSuccessful} 
                serverResponse={formValues.resetMessage} />
          }
        </form>
      </div>
    </div>
  );
};

export default ResetPin;
