import { Link } from "react-router-dom";
import "./styles/self-services.scss";

function SelfServicesHome() {
  return (
    <div className="p-4 mb-4 w-100 container-padding self-services">
      <div className="row gy-4 profile-completeness w-100 justify-content-center mx-0">
        <div className="col-5 col-sm-6 col-md-3">
          <Link to="reset-pin">
            <div className="text-center px-3 py-4" id="self-services-box">
              {" "}
              <span className="d-block text-10 text-light mt-2 mb-3">
                <i className="fas fa-user-cog"></i>
              </span>{" "}
              <span className="text-5 d-block text-success mt-4 mb-3">
                <i className="fas fa-check-circle"></i>
              </span>
              <p className="mb-0">Reset PIN</p>
            </div>
          </Link>
        </div>

        <div className="col-5 col-sm-6 col-md-3">
          <Link to="update-nin">
            <div className="text-center px-3 py-4" id="self-services-box">
              {" "}
              <span className="d-block text-10 text-light mt-2 mb-3">
                <i className="fas fa-sort-amount-up"></i>
              </span>{" "}
              <span className="text-5 d-block text-success mt-4 mb-3">
                <i className="fas fa-check-circle"></i>
              </span>
              <p className="mb-0">Update NIN</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SelfServicesHome;
