
import { useState, ChangeEvent, useRef, useEffect, FormEvent } from "react";
import { useNavigate, Form, Link } from "react-router-dom";
import useTransactions from "../../../../hooks/useTransactions";
import { toastFailure, toastSuccess } from "../../../../utils/toasts";
import CryptoJS from "../../../../utils/crypto";
import { useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import Spinner from "../../../../components/Spinner/Spinner";
import { ACCOUNT_TYPES, APP_RESPONSES, NETWORKS, RESPONSE_CODES, SERVICE_TYPES } from "../../../../utils/constants";
import Confirmation from "../../components/ConfirmationPage/ConfirmationPage";
import { PURCHASE_STAGE } from "../../../../utils/constants";
import TransactionResponse from "../../components/TransactionResponse/TransactionResponse";
import useDataInfo from "../../../../hooks/useDataInfo";
import OperationHeader from "../../components/OperationHeader/OperationHeader";
import useOTP from "../../../../hooks/useOTP";
import { decryptReduxValue, formatAmount, removeCommas, successResponse } from "../../../../utils/functions";
import "./styles/data.scss";
import { toast } from "react-toastify";
import Continue from "../../../../components/ButtonsLabel/Continue";
import PayNow from "../../../../components/ButtonsLabel/PayNow";
import RenderAllUserAccountOptions from "../../components/RenderAllUserAccountOptions/RenderAccountOptions";

function DataPurchase() {
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [isPurchasingData, setIsPurchasingData] = useState(false);
  const [purchaseStage, setPurchaseStage] = useState(0);
  const { gloDataPurchase, offnetDataPurchase } = useTransactions();
  const navigate = useNavigate();
  const formError = useRef([] as string[]);
  const authRedux: any = useAppSelector((state: RootState) => (state.auth))
  const [allDataPacks, setAllDataPacks] = useState<any[]>([]);
  const [networkDataPacks, setNetworkDataPacks] = useState<any[]>([]);
  const { getDataPacksList, updateBalanceInStore, getSourceAccount } = useDataInfo();
  const [formValues, setFormValues] = useState({
    "agentcode": "",
    "destination":"",
    "pin":"",
    "network":"GLO",
    "datapack":"",
    "dataPackDesc": "",
    "amount":"",
    "otp": "",
    "sourceAccountLabel": ""
  });

  const [activeAccType, setActiveAccType] = useState(ACCOUNT_TYPES.SELF);
  const [transactionResponse, setTransactionResponse] = useState({
    success: false,
    walletbalance: "",
    responseMessage: "",
    resultdescription: ""
  });
  const { sendOTP, validateOTPReq } = useOTP();
  const sourceAccountRef = useRef<HTMLSelectElement>(null);

  // Set the phone number of the user using the agent code in redux store
  // and the source account
  useEffect(() => {
    if(!sourceAccountRef.current) return;
    const {options, selectedIndex} = sourceAccountRef.current;

    setFormValues((prev) => ({
      ...prev, 
      "agentcode": options[selectedIndex].value!,
      "destination": decryptReduxValue(authRedux.user).wallet_accts.agentcode,
      "sourceAccountLabel": options[selectedIndex].innerHTML!
    }))
  }, [sourceAccountRef.current]);

  // Fetches all the data packs from the server
  useEffect(() => {
    const controller = new AbortController();

    getDataPacksList(controller.signal, formValues.network).then((response) => {
      setAllDataPacks(response.data);
      //changeNetworkDataPacks();
    }).catch((err) => {
      toast.dismiss();

      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);
    })

    return () => {
      controller.abort();
    }
  },[formValues.network])

  // If network is changed, set its corresponding data packs
  useEffect(() => {

    changeNetworkDataPacks();

  }, [allDataPacks])

  const handleChange = (event: ChangeEvent<any> ) => {
      setFormValues({
          ...formValues,
          [event.target.name]: event.target.value
      })
  }

  const handleOTPChange = (event: any) => {
    const { value } = event.target;

    if(value.length > 0 && Number.isNaN(Number(value))){
      return;
    }

    setFormValues((prev) => ({ ...prev, otp: value }))
  }

  const handleDataPackChange = (event: ChangeEvent<any>) => {
    const selectedDataPack = JSON.parse(event.target.value);

    setFormValues({
      ...formValues,
      amount: selectedDataPack.AMOUNT,
      datapack: selectedDataPack.DATA_PLAN,
      dataPackDesc: selectedDataPack.DATAPLAN_DESC
    })
  }

  const resetFormValues = (accountType: string) => {

    setFormValues((prev) => ({
      ...prev,
      "agentcode": prev.agentcode,
      "destination": accountType === ACCOUNT_TYPES.SELF ? decryptReduxValue(authRedux.user).wallet_accts.agentcode : "",
      "pin":"",
      "network":"GLO",
      "datapack":"",
      "dataPackDesc": "",
      "amount":"",
      "otp": "",
      "sourceAccountLabel": sourceAccountRef.current?.selectedOptions[0].innerHTML!
    }))

  }

  const resetPurchaseStage = () => {
    setPurchaseStage(0);
  }

  const toggleAccountType = (accountType: string) => {
    
    setActiveAccType((prev) => accountType);
    
    resetFormValues(accountType);
    
    resetPurchaseStage();

    changeNetworkDataPacks();

  }

  const resetFormErrors = () => {
    formError.current = [];
  } 

  const displayErrors = (errors: string[]) => {
    toast.dismiss();
    toastFailure(["Please fill in the form below correctly.", ...errors].join(" -> "));
    resetFormErrors();
    setLoadSpinner(false);
  }

  const getRequestPayLoad = () => {    
    let requestPayload = null;

    switch(formValues.network) {
      case NETWORKS.GLO:
        requestPayload = {
          "agentcode": formValues.agentcode,
          "destination": formValues.destination,
          "pin": CryptoJS.encryptValue(formValues.pin),
          "amount": removeCommas(formValues.amount),
          "network": formValues.network,
          "clientType":"SELFCARE"
        }
        break;
      case NETWORKS["9MOBILE"]:
      case NETWORKS.AIRTEL:
      case NETWORKS.MTN:
        requestPayload = {
          "agentcode": formValues.agentcode,
          "destination": formValues.destination,
          "pin": CryptoJS.encryptValue(formValues.pin),
          "network": formValues.network,
          "datapack": formValues.datapack,
          "amount": removeCommas(formValues.amount),
          "clientType":"SELFCARE"
        }
        break;
      default:
        break;
    }

    return requestPayload;
  }
  
  const purchaseData = async () => {
    const requestPayload = getRequestPayLoad();

    if(requestPayload === null) return

    try{
      const response = formValues.network === NETWORKS.GLO
      ? await gloDataPurchase(requestPayload) 
      : await offnetDataPurchase(requestPayload);
  
      handlePurchaseResponse(response);
      hideSpinner();
    }catch(err: any){
      hideSpinner();
      displayTransactionResponse({...transactionResponse, responseMessage: err.response.data, success: true})
    }
    
  }

  const displayTransactionResponse = (response: any) => {
    setTransactionResponse(response);
    setPurchaseStage(PURCHASE_STAGE.TRANSACTION_RESPONSE)
  }

  const handlePurchaseResponse = (response: any) => {
    if(response.data.responsecode === RESPONSE_CODES.SUCCESS){
      toastSuccess(response.data.resultdescription);

      updateBalanceInStore(response.data.walletbalance);
    }else{
      toast.dismiss();
      toastFailure(response.data.resultdescription);
    }

    //Display result page ()
    displayTransactionResponse({...response.data, responseMessage: response.data.resultdescription, success: response.data.responsecode === RESPONSE_CODES.SUCCESS});
  }

  const disablePurchaseButton = ()=> {
    document.querySelector('.purchase-now')?.setAttribute('disabled', 'disabled');
  }

  const handleDataPurchase: any = async (e : FormEvent) => {
    e.preventDefault();
    
    displaySpinner();

    disablePurchaseButton();
   
    setTimeout( async () => {
      purchaseData();
    }, 2000)

  }

  const displaySpinner = () => {
    if (!loadSpinner) setLoadSpinner(true);
    setIsPurchasingData(true);
  };

  const hideSpinner = () => setLoadSpinner(false);

  const validateOTP = async () => {
    toast.dismiss();

    if(formValues.otp === ""){
      toastFailure(APP_RESPONSES.INVALID_OTP_CODE);
      return;
    }

    try {
      // Validate the otp through server request validation
      const response = await validateOTPReq(formValues.otp);
      
      const { responseCode, responsecode } = response.data;

      if(!successResponse([responseCode, responsecode])){
        toastFailure(response.data.responseMessage);
        return false;
      }

      return true;
    } catch (error: any) {
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR)
      return false;
    }
  }

  const switchToPinForm = async () => {
    displaySpinner();

    const otpValid = await validateOTP();

    // If OTP is not valid return
    if(!otpValid){
      hideSpinner();
      return;
    }

    setTimeout(() => {
      setPurchaseStage(PURCHASE_STAGE.PIN_FORM);
      hideSpinner();
    }, 2000);
  }
  
  const othersFormValid = () => {
    const errors = {
      destination: "",
      datapack: "",
      network: "",
    }

    if(formValues.destination!.length !== 11){
      errors.destination = "Invalid destination Phone number";
    }
    
    if(formValues.datapack === ""){
      errors.datapack = "Invalid datapack";
    }
    
    if(formValues.network === ""){
      errors.network = "Invalid Network";
    }

    return Object.values(errors).filter(e => e !== "");
  }

  const selfFormValid = () => {
    let errors: string[] = []
    if(formValues.datapack === ""){
      errors = ["Select a datapack"];
    }
    return errors;
  }

  const switchToConfirmPage = () => {
    displaySpinner();

    if(activeAccType === ACCOUNT_TYPES.SELF){
      const errors = selfFormValid();
      if(errors.length > 0){
        displayErrors(errors);
        return
      }
    }else if(activeAccType === ACCOUNT_TYPES.OTHERS)
    {
      const errors = othersFormValid();
      if(errors.length > 0){
        displayErrors(errors);
        return
      }
    }
    
    setTimeout(() => {
      setPurchaseStage(PURCHASE_STAGE.CONFIRMATION_PAGE);
      hideSpinner();
    }, 2000);
  }

  const switchToTransactionOTPForm = () => {
    displaySpinner();

    // Send OTP to the authorized user
    sendOTP();

    setTimeout(() => {
      setPurchaseStage(PURCHASE_STAGE.OTP_FORM);
      hideSpinner();
    }, 2000);
  }
  
  const getPhoneNumber = () => {
    return activeAccType === ACCOUNT_TYPES.SELF ? decryptReduxValue(authRedux.user).wallet_accts.agentcode : formValues.destination;
  }

  const changeNetworkDataPacks = () => {
    const network = formValues.network === "" ? "GLO": formValues.network;
    //alert(allDataPacks);
    const networkPacks = allDataPacks.filter((dataPack) => (dataPack.OPERATOR === network));

    setNetworkDataPacks(networkPacks);
  }

  const setSourceAccount = (event: ChangeEvent<HTMLSelectElement>) => {
    if(event.target.value === "") return;

    const {options, selectedIndex} = event.target;

    setFormValues((prev) => ({
      ...prev,
      agentcode: event.target.value,
      sourceAccountLabel: options[selectedIndex].innerHTML
    }))

  }

  return (
    <>
      <div className="row mt-5 justify-content-center align-items-center flex-column container-padding" id="data-purchase-container">

          {/* Check points
          {
            purchaseStage === PURCHASE_STAGE.AMOUNT_FORM &&
            <div className="flex">
              <button className="btn btn-sm btn-success text-white" disabled>CHECK POINTS</button>
              <div className="fs-6"><span><i className="fas fa-envelope"></i></span></div>
            </div>
          } */}

          <div className="row mt-3 mb-5">
              <form className={`col-12`} onSubmit={(e) => e.preventDefault() }>
                  {
                    purchaseStage === PURCHASE_STAGE.AMOUNT_FORM &&
                    <>
                    <OperationHeader title={"Data Purchase"} />
                    <div className="row justify-content-between">
                      <div className="col-sm-6 ps-0">
                        <div className="d-grid">
                          <button className={`btn mb-3 ${activeAccType === ACCOUNT_TYPES.SELF ? 'btn-active' : 'btn-inactive'}`} onClick={ () => toggleAccountType(ACCOUNT_TYPES.SELF) }><i className="fas fa-user mb-015 me-2 align-self-center"></i> Self</button>
                        </div>
                      </div>
                      <div className="col-sm-6 pe-0">
                        <div className="d-grid">
                          <button className={`btn ${activeAccType === ACCOUNT_TYPES.OTHERS ? 'btn-active' : 'btn-inactive'}`} onClick={ () =>  toggleAccountType(ACCOUNT_TYPES.OTHERS) }><i className="fas fa-user-plus mb-015 me-2 align-self-center"></i> Others</button>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="sourceaccount" className="form-label">
                          Select Source Account
                        </label>
                        <select 
                          className="form-select" 
                          name="sourceaccount" 
                          required 
                          ref={sourceAccountRef}
                          onChange={(e) => {
                            setSourceAccount(e);
                          }}>
                          {/* <option value="">Source Account</option> */}
                          <RenderAllUserAccountOptions />
                        </select>
                      </div>
                    </>
                  }

                  {purchaseStage === PURCHASE_STAGE.AMOUNT_FORM && (activeAccType === ACCOUNT_TYPES.OTHERS) && 
                    <>

                    <div className="mb-3">
                      <label htmlFor="phoneNumber" className="form-label">Enter Phone Number</label>
                      <input
                          type="text"
                          placeholder="Phone number"
                          className="form-control"
                          aria-label="phoneNumber"
                          name="destination"
                          required
                          value={ formValues.destination }
                          onChange={ handleChange }
                      />
                    </div>
                    </>
                  }

                  {/* This is the list of all data bundles available for a particular network */}
                  {
                    purchaseStage === PURCHASE_STAGE.AMOUNT_FORM &&
                    <>
                    <div className="mb-3">
                      <label htmlFor="network" className="form-label">Network</label>
                      <select 
                        className="form-select" 
                        name="network" 
                        required 
                        value={formValues.network}
                        onChange={(e) => {
                          handleChange(e);
                          changeNetworkDataPacks();
                        }}>
                        <option value="GLO">GLO-NG</option>
                        <option value="MTN">MTN-NG</option>
                        <option value="AIRTEL">AIRTEL-NG</option>
                        <option value="9MOBILE">9MOBILE-NG</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="network" className="form-label">Choose data bundle</label>
                      <select className="form-select" name="datapack" required onChange={handleDataPackChange}>
                        <option>Data Bundles</option>
                        {
                          networkDataPacks.map((dataPack) => {
                            return <option 
                                      key={dataPack.DATA_PLAN} 
                                      value={JSON.stringify(dataPack)}>{ dataPack.DATAPLAN_DESC }</option>
                          })
                        }
                      </select>
                    </div>
                  </>
                  }
                  
                  {
                    purchaseStage === PURCHASE_STAGE.AMOUNT_FORM && 
                    <div className="d-grid">
                    <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToConfirmPage}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : (
                          <Continue />
                        )}
                    </button>
                    </div>
                  }
                  {
                    purchaseStage === PURCHASE_STAGE.CONFIRMATION_PAGE &&
                    <>
                    <Confirmation 
                      datapack={formValues.dataPackDesc} 
                      operationType={SERVICE_TYPES.DATA} 
                      number={getPhoneNumber() ?? ""} 
                      network={ formValues.network }
                      sourceAccount={ formValues.sourceAccountLabel }
                      />
                    <div className="d-grid">
                    <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToTransactionOTPForm}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : (
                          <Continue />
                        )}
                    </button>
                    </div>
                    </>
                  }

                  {
                    purchaseStage === PURCHASE_STAGE.OTP_FORM && 
                    <>
                    <OperationHeader title={"Data Purchase"} />
                      <div className="mb-3">
                        <label htmlFor="otp" className="form-label">Enter OTP code</label>
                        <input
                          type="text"
                          maxLength={6}
                          placeholder="OTP"
                          className="form-control"
                          aria-label="otp"
                          name="otp"
                          required
                          value={ formValues.otp }
                          onChange={ (e) => handleOTPChange(e) }
                        />
                      </div>

                    <div className="d-grid">
                    <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToPinForm}
                      >
                      {loadSpinner ? (
                        <span className="d-flex justify-content-center w-auto">
                          <Spinner />
                        </span>
                      ) : (
                        <Continue />
                      )}
                    </button>
                    </div>
                    </>
                  }

                  { purchaseStage === PURCHASE_STAGE.PIN_FORM &&
                    <>
                      <OperationHeader title={"Data Purchase"} />
                      <div className="mb-3">
                        <label htmlFor="pin" className="form-label">Enter Internet Banking PIN</label>
                        <input
                          type="password"
                          maxLength={4}
                          placeholder="PIN"
                          className="form-control"
                          aria-label="pin"
                          name="pin"
                          required
                          value={ formValues.pin }
                          onChange={ handleChange }
                        />
                      </div>

                      <div className="d-grid">
                        <button 
                        className="btn btn-primary purchase-now" 
                        type='button'
                        onClick={handleDataPurchase}
                        >
                          {loadSpinner ? (
                            <span className="d-flex justify-content-center w-auto">
                              <Spinner />
                            </span>
                          ) : (
                            <PayNow />
                          )}
                        </button>
                      </div>
                      </>
                  } 

                  {
                    purchaseStage === PURCHASE_STAGE.TRANSACTION_RESPONSE &&
                    <TransactionResponse 
                      amount={formValues.dataPackDesc.slice(1)} 
                      operationType={SERVICE_TYPES.DATA} 
                      success={transactionResponse.success}
                      walletbalance={transactionResponse?.walletbalance}
                      serverResponse={
                        transactionResponse.resultdescription 
                        ? transactionResponse.resultdescription
                        : transactionResponse.responseMessage
                      }
                      otherProps={
                        {
                          ...formValues, 
                          ...transactionResponse, 
                          activeAccType,
                          agentName: decryptReduxValue(authRedux.user).agentname
                        }
                      }
                      />
                  }
                </form>
          </div>
      </div>
    </>
  );
}

export default DataPurchase;
