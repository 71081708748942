import "./styles/home.scss";
import TopNavbarHome from "../../components/TopNavbarHome/TopNavbarHome";
import FooterHome from "../../components/FooterHome/FooterHome";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function Home() {
  useEffect(() => {
  
    document.body.classList.add('body');

    return () => {
      // 👇️ optionally remove styles when component unmounts
      document.body.classList.remove('body');
    };
  }, []);
  
  return (
    <>
      <section className="body">
      <div className="clip-image"></div>
      <TopNavbarHome />
      
          <section className="hero-wrap">
           
            <div className="hero-content d-flex fullscreen-with-header py-5">
              <div className="container my-auto">
                <div className="col">
                  <div className="col-12 col-lg-8 col-xl-7 text-center text-lg-start d-block">
                    <h2 className="text-13">The <span style={{color: '#2dbe60'}}>future</span> of banking, anytime, anywhere.</h2>
                    <p className="mb-4 container-text-white"><span style={{fontWeight: 'bold'}}>MoneyMaster</span> offers you a new and easy way to send, collect, save and invest.</p>
                      <Link to="/login" className="btn btn-primary signin-button d-flex"><i className="fas fa-sign-in-alt me-2 align-self-center"></i> <span>Login</span></Link>
                      
                    </div>
                </div>
                
              </div>
              
            </div>
            
          </section>
    
      
      <FooterHome /> 
      </section>

     
    </>
  );
}

export default Home;
