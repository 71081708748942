import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from 'redux-persist';
import accountReducer from './reducers/accountReducer';
import { transactionReducer } from './features/transactionSlice';
import { userAccountsReducer } from './features/userAccountsSlice';
import { datePickerReducer } from './features/datePickerSlice';
import { authReducer } from './features/authSlice';
import thunk from 'redux-thunk';
import { createContext } from 'react';


const persistConfig = {
  key: 'root',
  storage,
}

export const rootReducers = combineReducers({
  auth: authReducer,
  transaction: transactionReducer,
  userAccounts: userAccountsReducer,
  datePicker: datePickerReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.REACT_APP_NODE_ENV !== "prod"
});

setupListeners(store.dispatch);

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

