import { Link, useLocation } from "react-router-dom";
import TopNavbar from "../../../../components/TopNavbar/TopNavbar";
import log from "loglevel";
import { toast } from "react-toastify";
import SiteLogo from "../../../../assets/mmpsb-logo.png";
import "./styles/errorboundary.scss";
import Footer from "../../../../components/Footer/Footer";

function ErrorBoundary({error, resetErrorBoundary}: any){
  const location = useLocation();

  toast.dismiss();
  
  if(error !== null && error !== "" && Object.keys(error).length){
    log.error(JSON.stringify(error));
  }
  
  return <>
        <div id="error-page-container">
            
          <section className="main-error">
            <main>
              <img src={SiteLogo} />
              <section className="d-flex flex-column text-white">
                {/* <h3 className="text-center">G-Kala</h3> */}
                <p className="fs-6 fw-500">Oops, something went wrong</p>
              </section>

              <Link className="btn btn-lg btn-primary" to={location.pathname.includes('dashboard') ? "/dashboard" : "/"} onClick={resetErrorBoundary}>Try again</Link>
            </main>

            {/* <Footer />   */}
          </section>   
        </div>
  </>;
}

export default ErrorBoundary;