import { BASE_ACCESS_URL, BASE_URL } from "../api/urlConfig";
import { UPDATE_ACCESS_TOKEN } from "../redux/actions";
import axios, { AxiosRequestConfig } from "axios";
import { useAppDispatch, useAppSelector } from "./useStore";
import { RootState } from "../redux/store";
import useRefreshToken from "./useRefreshToken";
import useTimeout from "./useTimeout";
import { useEffect } from "react";

interface RetryConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
}

export const globalConfig: RetryConfig = {
  retry: 3,
  retryDelay: 1000,
};

const useAxios = () => {
  const authRedux: any = useAppSelector((state: RootState) => state.auth);
  const refresh = useRefreshToken();
  const dispatch = useAppDispatch();
  const {
    timeoutFunc,
  } = useTimeout();

  const customAxiosApi = axios.create({
    baseURL: BASE_ACCESS_URL,
    withCredentials: true
  });

  const customAxiosPrivateApi = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json'},
  });

  useEffect(() => {
    customAxiosApi.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config } = error;
        
        if (!config || !config.retry) {
          return Promise.reject(error);
        }
        config.retry -= 1
        const delayRetryRequest = new Promise<void>((resolve) => {
          setTimeout(() => {
            console.log("retry the request", config.url);
            // Fetch another access token
            
            resolve();
          }, config.retryDelay || 1000)
        })
        return delayRetryRequest.then(() => customAxiosApi(config));
      }
    );
    // End interceptor for token retrieval retries
    
  
    
    const requestIntercept = customAxiosPrivateApi.interceptors.request.use(
      (config) => {
       if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${authRedux?.tokens.accessToken}`;
        }
    
        return config;
      },
      (error) => Promise.reject(error)
    );
  
      // Timeout handling
    const timeoutRequestIntercept = customAxiosPrivateApi.interceptors.request.use(timeoutFunc);
    
    const responseIntercept = customAxiosPrivateApi.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { config } = error;
        
        if (!config || !config.retry) {
          return Promise.reject(error);
        }
    
        if (
          (error?.response?.status === 403 ||
            error?.response?.status === 401)
        ) {
    
          try{
            const newAccessToken = await refresh();
    
            // Save the new access token to redux store
            dispatch({type: UPDATE_ACCESS_TOKEN, payload: newAccessToken})
    
            config.headers["Authorization"] = `Bearer ${newAccessToken}`;
            
            return customAxiosPrivateApi(config);
    
          }catch(err: any){
            // if(err.response.status !== 200 && authRedux.isLoggedIn){
            //   dispatch({ type: SESSION_TIMEOUT });
            //   throw new axios.Cancel('');
            // }
    
            //return Promise.resolve();
          }
    
        }
    
        config.retry -= 1
        const delayRetryRequest = new Promise<void>((resolve) => {
          setTimeout(() => {
            console.log("retry the request", config.url);
            // Fetch another access token
            
            resolve();
          }, config.retryDelay || 1000)
        })
        return delayRetryRequest.then(() => customAxiosPrivateApi(config));
      }
    );
  
    return () => {
      customAxiosPrivateApi.interceptors.request.eject(requestIntercept);
      customAxiosPrivateApi.interceptors.response.eject(responseIntercept);
    };
  }, [authRedux, refresh])
  
  return { 
    customAxiosApi,
    customAxiosPrivateApi
  }
};

export default useAxios;
