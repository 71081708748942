import { useState, ChangeEvent, useEffect, FormEvent, useRef } from "react";
import useTransactions from "../../../../hooks/useTransactions";
import { toastFailure, toastSuccess } from "../../../../utils/toasts";
import CryptoJS from "../../../../utils/crypto";
import { useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import Spinner from "../../../../components/Spinner/Spinner";
import { APP_RESPONSES, CABLETV_PAY_STAGE, NAIRA_SYMBOL, RESPONSE_CODES, SERVICE_TYPES, SYMBOL_TYPES, TRANSACTION_TYPES } from "../../../../utils/constants";
import Confirmation from "../../components/ConfirmationPage/ConfirmationPage";
import TransactionResponse from "../../components/TransactionResponse/TransactionResponse";
import useDataInfo from "../../../../hooks/useDataInfo";
import "./styles/cabletv.scss";
import OperationHeader from "../../components/OperationHeader/OperationHeader";
import useOTP from "../../../../hooks/useOTP";
import { capitalize, decryptReduxValue, formatAmount, generateRandomNumber, handleServerResponse, removeCommas, successResponse } from "../../../../utils/functions";
import { AxiosResponse } from "axios";
import Symbols from "../../components/Symbols/Symbols";
import { toast } from "react-toastify";
import Continue from "../../../../components/ButtonsLabel/Continue";
import PayNow from "../../../../components/ButtonsLabel/PayNow";
import RenderAllUserAccountOptions from "../../components/RenderAllUserAccountOptions/RenderAccountOptions";

function CableTv() {
  const [formValues, setFormValues] = useState({
    cabletv: "",
    amount: "0",
    cardnumber: "",
    package: "",
    pin: "",
    otp:"",
    agentcode: "",
    sourceAccountLabel: ""
  })
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [transferStage, setTransferStage] = useState(CABLETV_PAY_STAGE.SELECT_CABLETV);
  const { cableSinglePayment } = useTransactions();
  const { getCableTvPackages, updateBalanceInStore, cabletvCustomerLookup, getSourceAccount } = useDataInfo();
  const authRedux: any = useAppSelector((state: RootState) => (state.auth));
  const { sendOTP, validateOTPReq } = useOTP();
  const [operationActive, setOperationActive] = useState(false);
  const [packageList, setPackageList] = useState<any[]>([]);
  const [transactionResponse, setTransactionResponse] = useState({
    walletbalance: "",
    responsecode: "",
    responseCode: "",
    resultdescription: "",
    responseMessage: "",
  });
  const [customerInformation, setCustomerInformation] = useState<{customfield: Array<{} | any>}>({customfield: []});
  const TRANSACTION_TYPE = "CableTV Subscription";
  const sourceAccountRef = useRef<HTMLSelectElement | null>(null);
  const cableTvRef = useRef<HTMLSelectElement | null>(null);

  // Sets the default sourceAccount on page load
  useEffect(() => {
    if(sourceAccountRef.current === null) return;

    const defaultOption = sourceAccountRef.current!.options[0]

    setFormValues((prev) => ({
      ...prev,
      agentcode: defaultOption.value,
      sourceAccountLabel: defaultOption.innerHTML
    }))
  },[sourceAccountRef.current])

  useEffect(() => {
    if(cableTvRef.current === null) return

    // Initialize the value of cableTv
    setFormValues({
      ...formValues,
      cabletv: cableTvRef.current!.options[0].value
    })    

    return () => { toast.dismiss() }
  },[])

  const displayErrors = (errors: string[]) => {
    toast.dismiss();
    toastFailure(["Please fill in the form below correctly.", ...errors].join(" -> "));
    setLoadSpinner(false);
  }

  const getRequestPayLoad = () => {
    return {
      "billerType": formValues.cabletv,
      "billerPackage": formValues.package ,
      "amount": formValues.amount,
      "beneficiaryAccount": formValues.cardnumber,
      "beneficiaryName": getCustomerName(),
      "msisdn": "234" + decryptReduxValue(authRedux.user).wallet_accts.agentcode.slice(1),
      "debitAccount": formValues.agentcode,
      "lookUpBasketId": getBasketId(),
      "channelSessionId": generateRandomNumber(),
      "pin": CryptoJS.encryptValue(formValues.pin),
      "clientType": "SELFCARE"
    }
  }

  const getBasketId = () => {
    return customerInformation.customfield.find((field) => (field.key === "BASKET_ID")).value;
  }
  
  const subscribeCableTv = async () => {
    try{
      const requestPayload = getRequestPayLoad();

      const response = await cableSinglePayment(requestPayload);
  
      handleTransactionResponse(response);

      hideSpinner();
    }catch(err: any){
      toast.dismiss();

      toastFailure(err.message);

      displayTransactionResponse(err.data);
      
      hideSpinner();
    }
    
  }

  const handleTransactionResponse = (response: any) => {
    handleServerResponse(response, updateBalanceInStore);

    const { responsecode,  responseCode } = response.data;

    displayTransactionResponse({
      ...response.data,
      success: successResponse([responsecode, responseCode])
    });
  }

  const displayTransactionResponse = (response : any) => {
    setTransactionResponse(response);
    setTransferStage(CABLETV_PAY_STAGE.TRANSACTION_RESPONSE)
  }

  const validateOTP = async () => {
    toast.dismiss();

    try {
      // Validate the otp through server request validation
      const response = await validateOTPReq(formValues.otp);
      
      const { responseCode, responsecode } = response.data;

      if(!successResponse([responseCode, responsecode])){
        toastFailure(response.data.responseMessage);
        return false;
      }

      return true;
    } catch (error: any) {
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR)
      return false;
    }
  }

  const disablePayNowButton = ()=> {
    document.querySelector('.pay-now')?.setAttribute('disabled', 'disabled');
  }

  const handleCableTvPayment: any = async (e : FormEvent) => {
    e.preventDefault();
    
    displaySpinner();

    disablePayNowButton();
   
    setTimeout(() => {
      subscribeCableTv();
    }, 2000)

  }

  const displaySpinner = () => {
    if (!loadSpinner) setLoadSpinner(true);
    setOperationActive(true);
  };

  const hideSpinner = () => setLoadSpinner(false);

  const switchToPinForm = async () => {
    displaySpinner();

    const otpValid = await validateOTP();

    // If OTP is not valid return
    if(!otpValid){
      hideSpinner();
      return;
    }

    setTimeout(() => {
      setTransferStage(CABLETV_PAY_STAGE.PIN_FORM);
      hideSpinner();
    }, 2000);
  }

  const validateForm = () => {
    const errors = {
      cabletv: "",
      package: "",
      cardnumber: "",
    }

    errors.cabletv = formValues.cabletv === "" ? "Specify the cabletv type": "";
    errors.cardnumber = (formValues.cardnumber === "" || formValues.cardnumber.length < 10 )? "Provide a valid smartcard number" : "";
    errors.package = formValues.package === "" ? "Choose a package" : "";

    const allErrors = Object.values(errors).filter((error) => (error !== ""));

    if(allErrors.length > 0){
      toast.dismiss();
      toastFailure(["Please fill in the form below correctly,", ...allErrors].join(" -> "));
    }

    return allErrors.length === 0;
  }

  const getCustomerDetails = async () => {
    return cabletvCustomerLookup(formValues.cardnumber);
  }

  const switchToConfirmPage = () => {
    displaySpinner();

    const formValid = validateForm();

    if(!formValid){
      hideSpinner();
      return;
    }

    getCustomerDetails().then((response: AxiosResponse) => {
      if(!response.data.success){
        toast.dismiss();

        toastFailure(APP_RESPONSES.CABLETV_ACCOUNT_NOT_FOUND);
        hideSpinner();
        return;
      }

      // Save the customer information
      setCustomerInformation(
        response.data.result.payUVasResponse.customFields
      );

      setTimeout(() => {
        setTransferStage(CABLETV_PAY_STAGE.CONFIRMATION_PAGE);
        hideSpinner();
      }, 2000);
      
    });
    
  }

  const switchToOTPForm = () => {
    displaySpinner();

    sendOTP();

    setTimeout(() => {
      setTransferStage(CABLETV_PAY_STAGE.OTP_FORM);
      hideSpinner();
    }, 2000);
  }

  const handlePinInput = (e: ChangeEvent<HTMLInputElement>) => {
       setFormValues({
      ...formValues,
      pin: e.target.value
    })
  };

  const handleChange = (event: any ) => {
    setFormValues({
        ...formValues,
        [event.target.name]: event.target.value
    })
  }

  const handleOTPChange = (event: any) => {
    const { value } = event.target;

    if(value.length > 0 && Number.isNaN(Number(value)))return;

    setFormValues((prev) => ({ ...prev, otp: event.target.value }))
  }

  const handleAmountInput = (e: ChangeEvent<HTMLInputElement>) => {
    if(loadSpinner) return

    const formattedAmount = formatAmount(e.target.value);
    
    if(Number.isNaN(formattedAmount)) return;

    setFormValues({
      ...formValues,
      amount: formattedAmount as string,
    });
  }

  // This useEffect hook is called when component is loaded 
  useEffect(() => {
    const controller = new AbortController();
    
    getPackagesList();
    
    return () => {
      controller.abort();
    }
  },[]);

  const getPackagesList = async () => {
    const packages = packageList.length > 0 ? packageList : (await getCableTvPackages()).data;

    // const allPackages = packages
    // .reduce((sortedPackages: any[], list: any) => {
    //   const newPackageObject = {
    //       amount: list._children.amount._value,
    //       bouquet_category: list._children.bouquet_category._value,
    //       bouquet_name: list._children.bouquet_name._value,
    //       id: list._children.id._value,
    //       product_key: list._children.product_key._value,
    //       product_key2: list._children.product_key2._value,
    //       product_key3: list._children.product_key3._value,
    //     }

    //     sortedPackages.push(newPackageObject);

    //     return sortedPackages;
    // },[])

    setPackageList(packages);
  }

  const handlePackageChange = (e: any) => {
    const {value} = e.target;

    if(value !== ""){
      const { bouquet_name, amount} = JSON.parse(value);
      setFormValues({...formValues, package: bouquet_name, amount });
      return
    }
      setFormValues({...formValues, package: "", amount: ""})
  }

  const handleCardNumberChange = (e: any) => {
    if(isNaN( Number(e.target.value) )) return;

    setFormValues({...formValues, cardnumber: e.target.value});
  }

  const getCustomerName = () => {
    return customerInformation.customfield.find((field: any) => ( field.key === "FIRSTNAME" )).value;
  }

  const setSourceAccount = (event: ChangeEvent<HTMLSelectElement>) => {
    const { selectedIndex, options } = event.target;
    

    setFormValues((prev) => ({
      ...prev,
      agentcode: event.target.value,
      sourceAccountLabel: options[selectedIndex].innerHTML
    }))

  }

  return (
    <>
      <div className="row w-100 mt-5 justify-content-center align-items-center flex-column container-padding">
          
          <div className="col-md-9 col-lg-7 col-xl-6 mx-auto mb-5">
              <form onSubmit={ handleCableTvPayment }>
                  {transferStage === CABLETV_PAY_STAGE.SELECT_CABLETV &&
                    <>
                    <OperationHeader title={TRANSACTION_TYPE} />

                    <div className="mb-3">
                      <label htmlFor="sourceAccount" className="form-label">
                        Select Source Account
                      </label>
                      <select 
                        className="form-select source-account-type" 
                        name="sourceAccount" 
                        required 
                        ref={sourceAccountRef}
                        onChange={(e) => setSourceAccount(e) }
                        >
                        {/* <option value="">--- Source account ---</option> */}
                        <RenderAllUserAccountOptions />
                      </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="cabletv" className="form-label">Select CableTV</label>
                        <select name="cabletv" className="form-select" onChange={handleChange} ref={cableTvRef}>
                          <option value="GOTV">GOTV</option>
                          <option value="DSTV">DSTV</option>
                        </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="cardnumber" className="form-label">Enter Smart Card Number</label>
                      <input
                        type="text"
                        placeholder="Card number"
                        className="form-control"
                        aria-label="card-number"
                        name="cardnumber"
                        required
                        value={ formValues.cardnumber }
                        onChange={ (e) => { handleCardNumberChange(e) } }
                      />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="package" className="form-label">Select Package</label>
                        <select name="package" className="form-select" onChange={handlePackageChange}>
                          <option value="">--- Select package ---</option>
                          {
                            packageList.length > 0 && 
                            packageList
                              .filter((singlePackage) => (singlePackage.bouquet_category.toUpperCase() === formValues.cabletv.toUpperCase()))
                              .map((list: any) => (<option value={ JSON.stringify(list) } key={ list.id }>{ list.bouquet_name } - {NAIRA_SYMBOL}{list.amount}</option>))
                          }
                        </select>
                    </div>

                    <div className="d-grid">
                      <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToConfirmPage}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : (
                          <Continue />
                        )}
                      </button>
                    </div>
                    </>
                  }

                  {
                    transferStage === CABLETV_PAY_STAGE.CONFIRMATION_PAGE &&
                    <>
                    <Confirmation 
                      amount={String(formValues.amount)} 
                      cardnumber={formValues.cardnumber}
                      operationType={SERVICE_TYPES.CABLETV_SUBSCRIPTION}
                      cableTvProvider={ formValues.cabletv } 
                      package={ formValues.package }
                      sourceAccount={ formValues.sourceAccountLabel }
                      beneficiaryName={ getCustomerName() } />
                    <div className="d-grid">
                    <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToOTPForm}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : (
                          <Continue />
                        )}
                    </button>
                    </div>
                    </>
                  }

                  {
                    transferStage === CABLETV_PAY_STAGE.OTP_FORM &&
                    <>
                    <OperationHeader title={TRANSACTION_TYPE} />
                    <div className="mb-3">
                      <label htmlFor="otp" className="form-label">Enter OTP code</label>
                      <input
                        type="text"
                        maxLength={6}
                        placeholder="OTP"
                        className="form-control"
                        aria-label="otp"
                        name="otp"
                        required
                        value={ formValues.otp }
                        onChange={ (e) => { handleOTPChange(e) } }
                      />
                    </div>

                    <div className="d-grid">
                      <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToPinForm}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : (
                          <Continue />
                        )}
                      </button>
                    </div>
                    </>
                  }     
                  
                  { transferStage === CABLETV_PAY_STAGE.PIN_FORM &&
                    <>
                      <OperationHeader title={TRANSACTION_TYPE} />
                      <div className="mb-3">
                        <label htmlFor="pin" className="form-label">Enter Internet Banking PIN</label>
                        <input
                          type="password"
                          maxLength={4}
                          placeholder="PIN"
                          className="form-control"
                          aria-label="pin"
                          name="pin"
                          required
                          value={ formValues.pin }
                          onChange={ handlePinInput }
                        />
                      </div>

                      <div className="d-grid">
                      <button 
                        className="btn btn-primary pay-now" 
                        type='submit'
                        >
                          {loadSpinner ? (
                            <span className="d-flex justify-content-center w-auto">
                              <Spinner />
                            </span>
                          ) : (
                            <PayNow />
                          )}
                      </button>
                      </div>
                      </>
                  }              

                  {
                    transferStage === CABLETV_PAY_STAGE.TRANSACTION_RESPONSE &&
                    <TransactionResponse 
                      amount={String(formValues.amount)} 
                      operationType={SERVICE_TYPES.CABLETV_SUBSCRIPTION} 
                      success={
                        transactionResponse?.responsecode === RESPONSE_CODES.SUCCESS ||
                        transactionResponse?.responseCode === RESPONSE_CODES.SUCCESS
                      }
                      walletbalance={transactionResponse?.walletbalance}
                      serverResponse={
                        transactionResponse?.resultdescription 
                        ? transactionResponse?.resultdescription
                        : transactionResponse?.responseMessage
                      }
                      otherProps={
                        {
                          ...formValues, 
                          ...transactionResponse,
                          agentName: decryptReduxValue(authRedux.user).agentname
                        }
                      }
                    />
                  }
                  
                </form>
          </div>
      </div>
    </>
  )
}

export default CableTv;