import { Link, useNavigate } from "react-router-dom";
import "../../dashboard.scss";
import "./styles/home.scss";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { APP_ACCT_TYPES, NAIRA_SYMBOL, SYMBOL_TYPES, TRANSACTIONS_HISTORY} from "../../../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import { toast } from "react-toastify";
import Symbols from "../../components/Symbols/Symbols";
import { resetTranHistory, setTranQueryAccount } from "../../../../redux/features/transactionSlice";
import SpentThisMonth from "../../components/SpentThisMonth/SpentThisMonth";
import { decryptReduxValue, formatAmount, getAllAccounts } from "../../../../utils/functions";
import IdleActivity from "../../components/IdleActivity/IdleActivity";
import Crypto from "../../../../utils/crypto";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';


function DashboardHome() {
  const { transaction: transactionRedux, userAccounts: userAccountsRedux, auth: authRedux }= useAppSelector((state: RootState) => state);
  // const userAccountsRedux = useMemo(() => (userAccounts), [userAccounts])
  const [showBalance, setShowBalance] = useState<{[key: string]: boolean}>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const indicatorsRef = useRef(null);
  indicatorsRef.current = null;

  const isMobileView = () => (innerWidth < 768);

  const isTabletView = () => (innerWidth >= 768 && innerWidth < 1024);

  const isDesktopView = () => (innerWidth >= 1024);

  const numVisibleSlides = useMemo(() => {
    if(isMobileView()) {
      return 2;
    }
    else if(isTabletView()){
      return 4;
    }
    else if(isDesktopView()){
      return 5;
    }
  }, []);

  // This function determines if the indicators below the account cards are displayed or not
  const displayAccCardsIndicators = () => {
    let canDisplay = false;
    
    if(isMobileView() && (getNumAccounts() > 1)){
      canDisplay = true;
    }
    else if(isTabletView() && (getNumAccounts() > 3)){
      canDisplay = true;
    }
    else if(isDesktopView() && (getNumAccounts() > 4)){
      canDisplay = true;
    }

    return canDisplay;
  }

  useLayoutEffect(() => {
    //reset the transaction history store
    resetTransactionHistory();
  }, []);

  // This populates the showBalance object with false for their initialization
  useEffect(() => {
    initializeShowBalance();
  },[]);

  const resetTransactionHistory = () => dispatch(resetTranHistory());

  const showTransactionHistory = async (accountNumber: string) => {    
    
    if(accountNumber !== ""){
      // const accNumber = Crypto.encryptValue(accountNumber);

      navigate(`transactions-history/?q=${accountNumber}`);
    }
    
  }

  const toggleShowBalance = (accountNumber: string) => {
    // This object holds the state for showing the balance of all accounts
    setShowBalance({
      ...showBalance,
      [accountNumber]: !showBalance[accountNumber]
    });
  }

  const initializeShowBalance = () => {
    const displayStates: {[key: string]: boolean} = Object.values(decryptReduxValue(userAccountsRedux.accounts)).reduce((prev: {[key: string]: boolean}, account: any) => {
      prev[account.split('|')[0]] = false;
      return prev;
    }, {})

    setShowBalance(displayStates);
  }

  const getNumAccounts = () => {
    return Object.entries(decryptReduxValue(userAccountsRedux.accounts)).length;
  }

  // This function creates a short swipe gesture to indicate the presence of other accounts cards
  const addSliderSwipeGesture = () => {
    document.querySelector(".slides-container")!.classList.toggle("move-right");
  }

  // This hook manages the interval for the swipe gesture animation
  useEffect(() => {
    if(!displayAccCardsIndicators()) return;

    const swipeGestureInterval = setInterval(() => {
      addSliderSwipeGesture()
    }, 1000)

    return () => clearInterval(swipeGestureInterval)
  },[])

  // This function is called on clicking any of the dot indicators below the carousel
  const renderDotsGroupArrows = (e: any) => {
    if(typeof e === "undefined") return;

    // The current slide is gotten from the classList of the radio button clicked on inside DotGroup component
    // setCurrentSlide(e.target.classList[2].split("--")[1]);
  }

  const getNumVisibleSlides = () => (isMobileView() ? 1 : isTabletView() ? 3 : 4)

  const canShowRightArrow = () => {
    return displayAccCardsIndicators() && (currentSlide + getNumVisibleSlides() - 1)
  }

  const canShowLeftArrow = () => {
    return displayAccCardsIndicators() && (currentSlide > 0)
  }

  // This hook is used to determine and set the visibility of indicators
  useEffect(() => {
    if(indicatorsRef.current !== null){
      // Check if you cannot display the indicators, remove from the DOM
      if(!displayAccCardsIndicators()) document.querySelector(".indicators-container")!.remove();
    }
  },[indicatorsRef])
  
  return (
    <>
      {/* Transaction history cards */}
      <div className="row d-flex position-relative">
      <div className="rounded mod-mb-2 w-100">
       <div className="row gy-4 accounts-container">

        {/* All user accounts are wrapped in this carousel */}
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={getNumAccounts()}
          visibleSlides={getNumVisibleSlides()}
          currentSlide={currentSlide}
          infinite
          className="main-carousel d-flex align-items-center flex-column mod-h-[9.375rem] mod-gap-1"
          >
          
          <Slider className="slides-container w-100 h-100 relative">
        {/* All user accounts cards are listed here */}
        {
          (Object.entries(getAllAccounts(userAccountsRedux.accounts))).map((account: any, index: number) => {
            const accountDetails = account[1].split("|");
            
            return <Slide index={index} className="single-slide h-100 md:mod-w-auto mod-w-[20rem]" key={index}>
                  <div className="card-container" key={accountDetails[0]}>
                    <div 
                      className="row text-center py-3 cards transaction-box bg-white text-grey h-100 gradient-background ps-3 pe-3" 
                      style={{ cursor: "pointer" }}
                    > 
                      
                      {/* Name and account status */}
                      {/* <section className="d-flex w-100 justify-content-center" onClick={() => showTransactionHistory(accountDetails[0]) }><span className="fullname text-uppercase fw-700">{ authRedux.user.agentname }</span>  */}
                      
                        {/* <span className="status">  
                        <i className="fas fa-circle fs-6 rounded-pill border border-success border-2 text-primary border-white me-1"></i> Active</span> */}
                        
                      {/* </section> */}

                      <div className="account-type-number-container d-flex flex-column justify-content-between">
                        {/* Account type */}
                        <section className="d-grid place-content-center text-uppercase" onClick={() => showTransactionHistory(accountDetails[0]) }>{ accountDetails[1] }</section>

                        {/* Account Balance */}
                        <section className={`account-balance d-flex justify-content-center align-items-center`} onClick={() => showTransactionHistory(accountDetails[0]) }><strong style={{ marginRight: "0.125rem", marginTop: "0.015rem" }}>{ NAIRA_SYMBOL }</strong><strong>{ showBalance[accountDetails[0]] ? `${formatAmount(accountDetails[2].split('.')[0])}.${accountDetails[2].split('.')[1]}` : "XXXX.XX" }</strong></section>
                      </div>

                      {/* Account Number and toggle balance state */}
                      <section className="d-flex w-100 justify-content-between align-items-center mt-1 px-0"><span className="account-number bg-primary text-white rounded-pill p-1 px-2" onClick={() => showTransactionHistory(accountDetails[0]) }>{ accountDetails[0] }</span> 
                      <span className="toggle-show-balance d-flex align-items-center"> 
                      {/* <span className="me-2" style={{ fontSize: "1rem" }}>Hide Balance</span>  */}
                      
                      <i onClick={() => toggleShowBalance(accountDetails[0])} className={`fas fa-toggle-${showBalance[accountDetails[0]] ? `off` : "on text-white"} fs-1 ms-1`}></i></span></section>
                    </div>
                  </div>
                    
                  </Slide>
                })
              }
            </Slider>

            <section className="indicators-container w-100 d-flex justify-content-center align-items-center mod-gap-6" ref={indicatorsRef}>
                {/* Arrow left is displayed if current slide is not first slide */}
                <ButtonBack className="border-0 bg-white">
                  <i 
                    className="fa fa-chevron-left" 
                    style={{ color: "#2dd066" }}
                    aria-hidden='true'></i>
                </ButtonBack>
         
                {/* This indicators displays all currently visible slides */}
                <DotGroup 
                  showAsSelectedForCurrentSlideOnly={false}
                  disableActiveDots={false}
                  /> 

                {/* Arrow right is displayed if current slide is not first slide */}
                <ButtonNext className="border-0 bg-white">
                  <i 
                    className="fa fa-chevron-right" 
                    style={{ color: "#2dd066" }}
                    aria-hidden='true'></i> 
                </ButtonNext>
              </section>
          </CarouselProvider>            
       </div>
       </div>
       <hr/>
       <div className="rounded mb-4 w-100 h-100">
            <div className="row gy-4 profile-completeness container-padding">
              <div className="col-5 col-md-3">
                <Link to="send-money">
                  <div className="position-relative text-center px-3 py-4 cards" id="transfer-box"> <span className="d-block text-10 text-light mt-2 mb-3"><i className="fas fa-mobile-alt"></i></span> <span className="text-5 d-block text-success mt-4 mb-3"><i className="fas fa-check-circle"></i></span>
                    <p className="mb-0">Transfer</p>
                  </div>
                </Link>
              </div>
              <div className="col-5 col-md-3">
                <Link to="airtime-data">
                  <div className="position-relative text-center px-3 py-4" id="airtime-box"> <span className="d-block text-10 text-light mt-2 mb-3"><i className="fas fa-envelope"></i></span> <span className="text-5 d-block text-success mt-4 mb-3"><i className="fas fa-check-circle"></i></span>
                    <p className="mb-0">Airtime/Data</p>
                  </div>
                </Link>
              </div>
              <div className="col-5 col-md-3">
                <Link to="pay-bills">
                  <div className="position-relative text-center px-3 py-4" id="bills-box"> <span className="d-block text-10 text-light mt-2 mb-3"><i className="fas fa-credit-card"></i></span> <span className="text-5 d-block text-light mt-4 mb-3"><i className="fas fa-check-circle"></i></span>
                    <p className="mb-0">Bill Payments</p>
                  </div>
                </Link>
              </div>
              <div className="col-5 col-md-3">
                <Link to="self-services">
                  <div className="position-relative text-center px-3 py-4" id="selfservice-box"> <span className="d-block text-10 text-light mt-2 mb-3"><i className="fas fa-university"></i></span> <span className="text-5 d-block text-light mt-4 mb-3"><i className="fas fa-check-circle "></i></span>
                    <p className="mb-0">Self Services</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default DashboardHome;
