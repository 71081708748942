import { useState, ChangeEvent, useRef, useEffect, FormEvent } from "react";
import { useNavigate, Form, Link } from "react-router-dom";
import useTransactions from "../../../../hooks/useTransactions";
import { toastFailure, toastSuccess } from "../../../../utils/toasts";
import CryptoJS from "../../../../utils/crypto";
import "./styles/airtime-data.scss";
import { useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import { IAirtimeOthers } from "../../../../hooks/useTransactions";
import Spinner from "../../../../components/Spinner/Spinner";
import {
  ACCOUNT_TYPES,
  APP_RESPONSES,
  NAIRA_SYMBOL,
  RESPONSE_CODES,
  SERVICE_TYPES,
} from "../../../../utils/constants";
import Confirmation from "../../components/ConfirmationPage/ConfirmationPage";
import { PURCHASE_STAGE } from "../../../../utils/constants";
import TransactionResponse from "../../components/TransactionResponse/TransactionResponse";
import OperationHeader from "../../components/OperationHeader/OperationHeader";
import useOTP from "../../../../hooks/useOTP";
import {
  decryptReduxValue,
  formatAmount,
  hasNoValue,
  prependCountryCode,
  removeCommas,
  successResponse,
} from "../../../../utils/functions";
import useDataInfo from "../../../../hooks/useDataInfo";
import { toast } from "react-toastify";
import Continue from "../../../../components/ButtonsLabel/Continue";
import RenderAllUserAccountOptions from "../../components/RenderAllUserAccountOptions/RenderAccountOptions";

function AirtimePurchase() {
  const [formValues, setFormValues] = useState<IAirtimeOthers>({
    agentcode: "",
    destination: "",
    amount: "0",
    network: "",
    pin: "",
    otp: "",
    sourceAccountLabel: "",
  });
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [isPurchasingAirtime, setIsPurchasingAirtime] = useState(false);
  const [purchaseStage, setPurchaseStage] = useState(0);
  const { selfPurchase, othersPurchase } = useTransactions();
  const formError = useRef([] as string[]);
  const authRedux: any = useAppSelector((state: RootState) => state.auth);

  const [activeAccType, setActiveAccType] = useState(ACCOUNT_TYPES.SELF);
  const [transactionResponse, setTransactionResponse] = useState({
    walletbalance: "",
    responsecode: "",
    resultdescription: "",
    responseMessage: "",
    responseCode: "",
    success: false,
  });
  const { sendOTP, validateOTPReq } = useOTP();
  const { updateBalanceInStore, getSourceAccount } = useDataInfo();
  const sourceAccountRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    setFormValues({
      ...formValues,
      agentcode: sourceAccountRef.current?.options[0].value!,
      destination: decryptReduxValue(authRedux.user).wallet_accts.agentcode,
      sourceAccountLabel: sourceAccountRef.current?.options[0].innerHTML,
    });
  }, []);

  const handleChange = (event: ChangeEvent<any>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const resetFormValues = (activeAccountType: string) => {
    setFormValues((prev) => ({
      ...prev,
      agentcode: prev.agentcode,
      destination:
        activeAccountType === ACCOUNT_TYPES.SELF
          ? decryptReduxValue(authRedux.user).wallet_accts.agentcode
          : "",
      amount: "",
      network: "",
      pin: "",
      otp: "",
      sourceAccountLabel:
        sourceAccountRef.current?.selectedOptions[0].innerHTML,
    }));
  };

  const resetPurchaseStage = () => {
    setPurchaseStage(0);
  };

  const toggleAccountType = (accountType: string) => {
    setActiveAccType((prev) => accountType);

    resetFormValues(accountType);

    resetPurchaseStage();
  };

  const othersFormValid = () => {
    const errors = {
      destination: "",
      amount: "",
      network: "",
    };

    errors.destination =
      Number.isNaN(Number(formValues.destination as string)) ||
      formValues.destination!.length !== 11
        ? "Invalid destination number"
        : "";

    errors.amount =
      Number.isNaN(removeCommas(formValues.amount)) ||
      Number(removeCommas(formValues.amount)) < 50
        ? "Invalid amount -> Amount should not be less than " +
          NAIRA_SYMBOL +
          "50"
        : "";

    errors.network = formValues.network === "" ? "Network not specified" : "";

    return Object.values(errors).filter((error) => error !== "");
  };

  const selfFormValid = () => {
    let errors: string[] = [];

    if (
      Number.isNaN(removeCommas(formValues.amount)) ||
      Number(removeCommas(formValues.amount)) <= 0
    ) {
      errors = ["Invalid amount"];
    }

    if (formValues.network === "") {
      errors = [...errors, "Network not selected"];
    }

    return errors;
  };

  const resetFormErrors = () => {
    formError.current = [];
  };

  const displayErrors = (errors: string[]) => {
    toast.dismiss();

    toastFailure(
      ["Please fill in the form below correctly.", ...errors].join(" -> ")
    );
    resetFormErrors();
    setLoadSpinner(false);
  };

  const purchaseAirtime = async (requestPayload: IAirtimeOthers) => {
    try {
      const response =
        activeAccType === ACCOUNT_TYPES.SELF
          ? await selfPurchase(requestPayload)
          : await othersPurchase({ ...requestPayload });

      handlePurchaseResponse(response);
      hideSpinner();
    } catch (err: any) {
      toast.dismiss();

      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);

      displayTransactionResponse(err.data);

      hideSpinner();
    }
  };

  const handlePurchaseResponse = (response: any) => {
    const { responsecode, responseCode } = response.data;

    const successful = successResponse([responseCode, responsecode]);

    if (successful) {
      toastSuccess(response.data.resultdescription);

      updateBalanceInStore(response.data.walletbalance);
    } else {
      toast.dismiss();

      toastFailure(response.data.resultdescription);
    }

    displayTransactionResponse({
      ...response.data,
      success: successful,
      activeAccType,
    });
  };

  const displayTransactionResponse = (response: any) => {
    setTransactionResponse((prev) => response);
    setPurchaseStage(PURCHASE_STAGE.TRANSACTION_RESPONSE);
  };

  const disableSubmitButton = () => {
    document
      .querySelector("#purchaseButton")
      ?.setAttribute("disabled", "disabled");
  };

  const handleAirtimePurchase: any = async (e: FormEvent) => {
    e.preventDefault();

    displaySpinner();

    disableSubmitButton();

    const requestPayload = {
      agentcode: formValues.agentcode,
      destination: formValues.destination,
      amount: removeCommas(formValues.amount),
      network: formValues.network,
      pin:
        formValues.pin.length === 4
          ? CryptoJS.encryptValue(formValues.pin)
          : formValues.pin,
      clientType: "SELFCARE",
    };

    setTimeout(() => {
      purchaseAirtime(requestPayload);
    }, 2000);
  };

  const displaySpinner = () => {
    if (!loadSpinner) setLoadSpinner(true);
    setIsPurchasingAirtime(true);
  };

  const validateOTP = async () => {
    toast.dismiss();

    if (hasNoValue(formValues.otp)) {
      toastFailure(APP_RESPONSES.INVALID_OTP_CODE);
      return false;
    }

    try {
      // Validate the otp through server request validation
      const response = await validateOTPReq(formValues.otp || "");

      const { responseCode, responsecode } = response.data;

      if (!successResponse([responseCode, responsecode])) {
        toastFailure(response.data.responseMessage);
        return false;
      }

      return true;
    } catch (error: any) {
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);
      return false;
    }
  };

  const hideSpinner = () => setLoadSpinner(false);

  const switchToPinForm = async () => {
    displaySpinner();

    const otpValid = await validateOTP();

    if (!otpValid) {
      hideSpinner();
      return;
    }

    setTimeout(() => {
      setPurchaseStage(PURCHASE_STAGE.PIN_FORM);
      hideSpinner();
    }, 2000);
  };

  const switchToTransactionOTPForm = () => {
    displaySpinner();

    try {
      // Send OTP to the authorized user
      sendOTP();

      setTimeout(() => {
        setPurchaseStage(PURCHASE_STAGE.OTP_FORM);
        hideSpinner();
      }, 2000);
    } catch (err) {}
  };

  const switchToConfirmPage = () => {
    displaySpinner();

    if (activeAccType === ACCOUNT_TYPES.SELF) {
      const errors = selfFormValid();
      if (errors.length > 0) {
        displayErrors(errors);
        return;
      }
    } else if (activeAccType === ACCOUNT_TYPES.OTHERS) {
      const errors = othersFormValid();

      if (errors.length > 0) {
        displayErrors(errors);
        return;
      }
    }

    setTimeout(() => {
      setPurchaseStage(PURCHASE_STAGE.CONFIRMATION_PAGE);
      hideSpinner();
    }, 2000);
  };

  const getPhoneNumber = () => {
    return activeAccType === ACCOUNT_TYPES.SELF
      ? decryptReduxValue(authRedux.user).wallet_accts.agentcode
      : formValues.destination;
  };

  const handleAmountInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (loadSpinner) return;

    const formattedAmount = formatAmount(e.target.value);

    if (Number.isNaN(formattedAmount)) return;

    setFormValues({
      ...formValues,
      amount: formattedAmount as string,
    });
  };

  const handleOTPInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (isNaN(Number(value))) return;

    setFormValues((prev) => ({ ...prev, otp: e.target.value }));
  };

  const setSourceAccount = (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "") return;

    setFormValues((prev) => ({
      ...prev,
      agentcode: event.target.value,
      sourceAccountLabel: event.target.selectedOptions[0].innerHTML,
    }));
  };

  return (
    <>
      <div
        className="row mt-5 justify-content-center align-items-center flex-column container-padding"
        id="airtime-purchase-container"
      >
        <div className="row mt-3 mb-5">
          <form className="col-12" onSubmit={handleAirtimePurchase}>
            {purchaseStage === PURCHASE_STAGE.AMOUNT_FORM && (
              <>
                <OperationHeader title={"Airtime Purchase"} />
                <div className="row justify-content-between">
                  <div className="col-sm-6 ps-0">
                    <div className="d-grid">
                      <button
                        className={`mb-3 btn ${
                          activeAccType === ACCOUNT_TYPES.SELF
                            ? "btn-active"
                            : "btn-inactive"
                        }`}
                        onClick={(e) => toggleAccountType(ACCOUNT_TYPES.SELF)}
                      >
                        <i className="fas fa-user mb-015 me-2 align-self-center"></i>{" "}
                        Self
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-6 pe-0">
                    <div className="d-grid">
                      <button
                        className={`btn ${
                          activeAccType === ACCOUNT_TYPES.OTHERS
                            ? "btn-active"
                            : "btn-inactive"
                        }`}
                        onClick={(e) => toggleAccountType(ACCOUNT_TYPES.OTHERS)}
                      >
                        <i className="fas fa-user-plus mb-015 me-2 align-self-center"></i>{" "}
                        Others
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {purchaseStage === PURCHASE_STAGE.AMOUNT_FORM && (
              <>
                <div className="mb-3">
                  <label htmlFor="sourceaccount" className="form-label">
                    Select Source Account
                  </label>
                  <select
                    className="form-select"
                    name="sourceaccount"
                    required
                    ref={sourceAccountRef}
                    onChange={(e) => {
                      setSourceAccount(e);
                    }}
                  >
                    {/* <option value="">Source Account</option> */}
                    <RenderAllUserAccountOptions />
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="amount" className="form-label">
                    Enter Amount
                  </label>
                  <input
                    type="text"
                    placeholder="Amount"
                    className="form-control"
                    aria-label="amount"
                    name="amount"
                    minLength={2}
                    required
                    value={formValues.amount}
                    onChange={handleAmountInput}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="network" className="form-label">
                    Network
                  </label>
                  <select
                    className="form-select"
                    name="network"
                    required
                    value={formValues.network}
                    onChange={handleChange}
                  >
                    <option>Choose Network</option>
                    <option value="GLO">GLO-NG</option>
                    <option value="MTN">MTN-NG</option>
                    <option value="AIRTEL">AIRTEL-NG</option>
                    <option value="9MOBILE">9MOBILE-NG</option>
                  </select>
                </div>
              </>
            )}

            {/* Amount form is the first page having the first form that is displayed on the app */}
            {purchaseStage === PURCHASE_STAGE.AMOUNT_FORM &&
              activeAccType === ACCOUNT_TYPES.OTHERS && (
                <>
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">
                      Enter Phone Number
                    </label>
                    <input
                      type="text"
                      maxLength={11}
                      placeholder="Phone number"
                      className="form-control"
                      aria-label="phoneNumber"
                      name="destination"
                      required
                      value={formValues.destination}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}

            {purchaseStage === PURCHASE_STAGE.AMOUNT_FORM && (
              <div className="d-grid">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={switchToConfirmPage}
                >
                  {loadSpinner ? (
                    <span className="d-flex justify-content-center w-auto">
                      <Spinner />
                    </span>
                  ) : (
                    <Continue />
                  )}
                </button>
              </div>
            )}

            {purchaseStage === PURCHASE_STAGE.CONFIRMATION_PAGE && (
              <>
                <Confirmation
                  amount={formValues.amount}
                  operationType={SERVICE_TYPES.AIRTIME}
                  number={getPhoneNumber()!}
                  network={formValues.network}
                  sourceAccount={formValues.sourceAccountLabel}
                />
                <div className="d-grid">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={switchToTransactionOTPForm}
                  >
                    {loadSpinner ? (
                      <span className="d-flex justify-content-center w-auto">
                        <Spinner />
                      </span>
                    ) : (
                      <Continue />
                    )}
                  </button>
                </div>
              </>
            )}

            {purchaseStage === PURCHASE_STAGE.OTP_FORM && (
              <>
                <OperationHeader title={"Airtime Purchase"} />
                <div className="mb-3">
                  <label htmlFor="otp" className="form-label">
                    Enter OTP code
                  </label>
                  <input
                    type="text"
                    maxLength={6}
                    placeholder="OTP"
                    className="form-control"
                    aria-label="otp"
                    name="otp"
                    required
                    value={formValues.otp}
                    onChange={handleOTPInput}
                  />
                </div>

                <div className="d-grid">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={switchToPinForm}
                  >
                    {loadSpinner ? (
                      <span className="d-flex justify-content-center w-auto">
                        <Spinner />
                      </span>
                    ) : (
                      <Continue />
                    )}
                  </button>
                </div>
              </>
            )}

            {purchaseStage === PURCHASE_STAGE.PIN_FORM && (
              <>
                <OperationHeader title={"Airtime Purchase"} />
                <div className="mb-3">
                  <label htmlFor="pin" className="form-label">
                    Enter Internet Banking PIN
                  </label>
                  <input
                    type="password"
                    maxLength={4}
                    placeholder="PIN"
                    className="form-control"
                    aria-label="pin"
                    name="pin"
                    required
                    value={formValues.pin}
                    onChange={handleChange}
                  />
                </div>

                <div className="d-grid">
                  <button
                    id="purchaseButton"
                    className="btn btn-primary"
                    type="submit"
                  >
                    {loadSpinner ? (
                      <span className="d-flex justify-content-center w-auto">
                        <Spinner />
                      </span>
                    ) : (
                      <span className="d-flex justify-content-center">
                        <i className="fas fa-share-square mb-015 me-2 align-self-center"></i>{" "}
                        Complete Transaction
                      </span>
                    )}
                  </button>
                </div>
              </>
            )}

            {purchaseStage === PURCHASE_STAGE.TRANSACTION_RESPONSE && (
              <TransactionResponse
                amount={formValues.amount}
                operationType={SERVICE_TYPES.AIRTIME}
                success={transactionResponse?.success}
                walletbalance={transactionResponse?.walletbalance}
                serverResponse={
                  transactionResponse.resultdescription
                    ? transactionResponse.resultdescription
                    : transactionResponse.responseMessage
                }
                otherProps={{
                  ...formValues,
                  ...transactionResponse,
                  activeAccType,
                  agentName: decryptReduxValue(authRedux.user).agentname,
                }}
              />
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default AirtimePurchase;
