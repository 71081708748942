import { useState, ChangeEvent, useEffect, FormEvent, useRef } from "react";
import useTransactions from "../../../../hooks/useTransactions";
import { toastFailure, toastSuccess } from "../../../../utils/toasts";
import CryptoJS from "../../../../utils/crypto";
import { useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import Spinner from "../../../../components/Spinner/Spinner";
import { APP_RESPONSES, ELECTRICITY_PAY_STAGE, ELECTRICITY_VEND_TYPE, RESPONSE_CODES, SERVICE_TYPES, TRANSACTION_TYPES } from "../../../../utils/constants";
import Confirmation from "../../components/ConfirmationPage/ConfirmationPage";
import TransactionResponse from "../../components/TransactionResponse/TransactionResponse";
import useDataInfo from "../../../../hooks/useDataInfo";
import "./styles/electricity.scss";
import OperationHeader from "../../components/OperationHeader/OperationHeader";
import useOTP from "../../../../hooks/useOTP";
import { decryptReduxValue, formatAmount, generateRandomNumber, handleServerResponse, removeCommas, successResponse } from "../../../../utils/functions";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import PayNow from "../../../../components/ButtonsLabel/PayNow";
import Continue from "../../../../components/ButtonsLabel/Continue";
import RenderAllUserAccountOptions from "../../components/RenderAllUserAccountOptions/RenderAccountOptions";

function Electricity() {
  const [formValues, setFormValues] = useState({
    disco: "",
    amount: "0",
    meternumber: "",
    vendType: "",
    pin: "",
    otp:"",
    agentcode: "",
    sourceAccountLabel: ""
  })
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [paymentStage, setPaymentStage] = useState(ELECTRICITY_PAY_STAGE.SELECT_DISCO);
  const { electricitySinglePayment } = useTransactions();
  const { getAllDiscos, updateBalanceInStore, electricityCustomerLookup, getSourceAccount } = useDataInfo();
  const authRedux: any = useAppSelector((state: RootState) => (state.auth));
  const { sendOTP, validateOTPReq } = useOTP();
  const [operationActive, setOperationActive] = useState(false);
  const [discosList, setDiscosList] = useState<any[]>([]);
  const [transactionResponse, setTransactionResponse] = useState({
    walletbalance: "",
    responsecode: "",
    responseCode: "",
    resultdescription: "",
    responseMessage: "",
    success: false
  });
  const [customerInformation, setCustomerInformation] = useState<any>({});
  const discoRef = useRef<HTMLSelectElement>(null);
  const sourceAccountRef = useRef<HTMLSelectElement>(null);
  const TRANSACTION_TYPE = "Electricity Payment";

  // Sets the default sourceAccount on page load
  useEffect(() => {
    if(sourceAccountRef.current === null) return;

    const defaultOption = sourceAccountRef.current!.options[0]

    setFormValues((prev) => ({
      ...prev,
      agentcode: defaultOption.value,
      sourceAccountLabel: defaultOption.innerHTML
    }))
  },[sourceAccountRef.current])

  const displayErrors = (errors: string[]) => {
    toast.dismiss();
    toastFailure(["Please fill in the form below correctly.", ...errors].join(" -> "));
    setLoadSpinner(false);
  }

  const getRequestPayLoad = () => {
    return {
      "disco": formValues.disco,
      "vendType": formValues.vendType,
      "amount": removeCommas(formValues.amount),
      "beneficiaryAccount": formValues.meternumber,
      "beneficiaryName": customerInformation.name._value,
      "msisdn": "234" + decryptReduxValue(authRedux.user).wallet_accts.agentcode.slice(1),
      "debitAccount": formValues.agentcode,
      "channelSessionId": generateRandomNumber(),
      "pin": CryptoJS.encryptValue(formValues.pin),
      "meter": formValues.meternumber,
      "email": "",
      "clientType": "SELFCARE"
    }
  }

  const displayTransactionResponse = (response : any) => {
    setTransactionResponse(response);
    setPaymentStage(ELECTRICITY_PAY_STAGE.TRANSACTION_RESPONSE)
  }

  const validateOTP = async () => {
    toast.dismiss();

    try {
      // Validate the otp through server request validation
      const response = await validateOTPReq(formValues.otp);
      
      const { responseCode, responsecode } = response.data;

      if(!successResponse([responseCode, responsecode])){
        toastFailure(response.data.responseMessage);
        return false;
      }

      return true;
    } catch (error: any) {
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR)
      return false;
    }
  }

  const displaySpinner = () => {
    if (!loadSpinner) setLoadSpinner(true);
    setOperationActive(true);
  };

  const hideSpinner = () => setLoadSpinner(false);

  const switchToPinForm = async () => {
    displaySpinner();

    const otpValid = await validateOTP();

    // If OTP is not valid return
    if(!otpValid){
      hideSpinner();
      return;
    }

    setTimeout(() => {
      setPaymentStage(ELECTRICITY_PAY_STAGE.PIN_FORM);
      hideSpinner();
    }, 2000);
  }

  const validateForm = () => {
    const errors = {
      meternumber: "",
      disco: "",
      paymenttype: "",
    }

    errors.meternumber = formValues.meternumber.length === 0 ? "Incorrect meter number": "";
    errors.disco = formValues.disco === "" ? "Choose a disco" : "";
    errors.paymenttype = formValues.vendType === "" ? "Choose a payment type" : "";

    const allErrors = Object.values(errors).filter((error) => (error !== ""));

    if(allErrors.length > 0){
      toast.dismiss();
      toastFailure(["Please fill in the form below correctly,", ...allErrors].join(" -> "));
    }

    return allErrors.length === 0;
  }

  const getCustomerDetails = async () => {
    const reqPayload = {
      "meter": formValues.meternumber,
      "disco": formValues.disco,
      "vendType": formValues.vendType
    };

    return electricityCustomerLookup(reqPayload);
  }

  const switchToConfirmPage = () => {
    displaySpinner();

    const formValid = validateForm();

    if(!formValid){
      hideSpinner();
      return;
    }

    setTimeout(() => {
      setPaymentStage(ELECTRICITY_PAY_STAGE.CONFIRMATION_PAGE);
      hideSpinner();
    }, 2000);
    
  }

  const switchToOTPForm = () => {
    displaySpinner();

    sendOTP();

    setTimeout(() => {
      setPaymentStage(ELECTRICITY_PAY_STAGE.OTP_FORM);
      hideSpinner();
    }, 2000);
  }

  const handlePinInput = (e: ChangeEvent<HTMLInputElement>) => {
       setFormValues({
      ...formValues,
      pin: e.target.value
    })
  };

  const handleChange = (event: any ) => {
    setFormValues({
        ...formValues,
        [event.target.name]: event.target.value
    })
  }

  const handleOTPChange = (event: any) => {
    const { value } = event.target;

    if(value.length > 0 && Number.isNaN(Number(value)))return;

    setFormValues((prev) => ({ ...prev, otp: event.target.value }))
  }

  const handleAmountInput = (e: ChangeEvent<HTMLInputElement>) => {
    if(loadSpinner) return

    const formattedAmount = formatAmount(e.target.value);
    
    if(Number.isNaN(formattedAmount)) return;

    setFormValues({
      ...formValues,
      amount: formattedAmount as string,
    });
  }

  // This useEffect hook is called when component is loaded 
  useEffect(() => {
    const controller = new AbortController();
    getDiscosList(controller.signal);
    return () => { controller.abort() }
  },[]);

  const getDiscosList = async (abortSignal: AbortSignal) => {
    const discos = await getAllDiscos(abortSignal);

    setDiscosList(discos.data);
  }

  const handleMeterNumberChange = (e: any) => {
    if(isNaN( Number(e.target.value) ) || e.target.value.length > 13 ) return;

    setFormValues({...formValues, meternumber: e.target.value});
  }

  const handleDiscoChange = (e: any) => {
    setFormValues((prev) => ({...prev, disco: e.target.value})); 
  }

  const handlePaymentTypeChange = (e: any) => {
    setFormValues((prev) => ({...prev, vendType: e.target.value}));
  }

  const showEnterAmountForm = () => {
    const formValid = validateForm();
    
    if(!formValid){
      return;
    }
    
    displaySpinner();

    getCustomerDetails().then((response: AxiosResponse) => {
      if( !response.data ){
        toast.dismiss();
        toastFailure(response.data.result?.error);
        hideSpinner();
        return;
      }

      // Save the customer information
      setCustomerInformation(
        response.data.result
      );

      setTimeout(() => {
        setPaymentStage(ELECTRICITY_PAY_STAGE.ENTER_AMOUNT);
        hideSpinner();
      }, 2000);
    });
  }

  const handleTransactionResponse = (response: any) => {
    handleServerResponse(response, updateBalanceInStore);

    const {responseCode, responsecode} = response.data;

    displayTransactionResponse({
      ...response.data,
      success: successResponse([responseCode,responsecode])
    });
  }

  const payForElectricity = async () => {
    try{
      const requestPayload = getRequestPayLoad();

      const response = await electricitySinglePayment(requestPayload);
  
      handleTransactionResponse(response);

      hideSpinner();
    }catch(err: any){
      toast.dismiss();

      toastFailure(err.message);

      displayTransactionResponse(err.data);
      
      hideSpinner();
    }
  }

  const disablePayNowButton = ()=> {
    document.querySelector('.pay-now')?.setAttribute('disabled', 'disabled');
  }

  const handleElectricityPayment = (e: FormEvent) => {
    e.preventDefault();
    
    displaySpinner();

    disablePayNowButton();
   
    setTimeout(() => {
      payForElectricity();
    }, 2000)
  }
  
  const setSourceAccount = (event: ChangeEvent<HTMLSelectElement>) => {
    const { selectedIndex, options } = event.target;
    

    setFormValues((prev) => ({
      ...prev,
      agentcode: event.target.value,
      sourceAccountLabel: options[selectedIndex].innerHTML
    }))

  }

  
  return (
    <>
      <div className="row w-100 mt-5 justify-content-center align-items-center flex-column container-padding">
          
          <div className="col-md-9 col-lg-7 col-xl-6 mx-auto mb-5">
              <form onSubmit={ e => e.preventDefault() }>
                  {paymentStage === ELECTRICITY_PAY_STAGE.SELECT_DISCO &&
                    <>
                    <OperationHeader title={TRANSACTION_TYPE} />
                    <div className="mb-3">
                      <label htmlFor="sourceAccount" className="form-label">
                        Select Source Account
                      </label>
                      <select 
                        className="form-select source-account-type" 
                        name="sourceAccount" 
                        required 
                        ref={sourceAccountRef}
                        onChange={(e) => setSourceAccount(e) }
                        >
                        {/* <option value="">Source Account</option> */}
                        <RenderAllUserAccountOptions />
                      </select>
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="meternumber" className="form-label">Enter meter number</label>
                        <input
                          type="text"
                          placeholder="Meter Number"
                          className="form-control"
                          aria-label="meter-number"
                          name="meternumber"
                          required
                          value={ formValues.meternumber }
                          onChange={ (e) => { handleMeterNumberChange(e) } }
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="disco" className="form-label">Select Disco</label>
                        <select name="disco" className="form-select" onChange={handleDiscoChange}>
                          <option value="">--- Choose a disco ---</option>
                          {
                            discosList.length > 0 && 
                            discosList.map((list: any) => (<option value={ list } key={ list }>{ list }</option>))
                          }
                        </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="paymenttype" className="form-label">Select payment type</label>
                      <select name="paymenttype" className="form-select" onChange={handlePaymentTypeChange}>
                          <option value="">--- Choose a payment type ---</option>
                          <option value={ ELECTRICITY_VEND_TYPE.PREPAID }>{ ELECTRICITY_VEND_TYPE.PREPAID }</option>
                          <option value={ ELECTRICITY_VEND_TYPE.POSTPAID }>{ ELECTRICITY_VEND_TYPE.POSTPAID }</option>
                        </select>
                    </div>

                    <div className="d-grid">
                      <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={showEnterAmountForm}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : (
                          <Continue />
                        )}
                      </button>
                    </div>
                    </>
                  }

                  {
                    paymentStage === ELECTRICITY_PAY_STAGE.ENTER_AMOUNT && 
                    <>
                    <OperationHeader title={TRANSACTION_TYPE} />
                    <div className="mb-3">
                      <label htmlFor="amount" className="form-label">Enter Amount</label>
                      <input
                        type="text"
                        placeholder="Amount"
                        className="form-control"
                        name="amount"
                        minLength={2}
                        required
                        value={ formValues.amount }
                        onChange={ handleAmountInput }
                      />
                    </div>

                    <div className="d-grid">
                    <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToConfirmPage}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : (
                          <Continue />
                        )}
                    </button>
                    </div>
                    </>
                  }

                  {
                    paymentStage === ELECTRICITY_PAY_STAGE.CONFIRMATION_PAGE &&
                    <>
                    <Confirmation 
                      amount={String(formValues.amount)} 
                      number={customerInformation.meterNo}
                      operationType={SERVICE_TYPES.ELECTRICITY_PAYMENT}
                      disco={ formValues.disco } 
                      sourceAccount={ formValues.sourceAccountLabel }
                      beneficiaryName={ 
                        customerInformation.name
                      } />
                    <div className="d-grid">
                    <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToOTPForm}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : (
                          <Continue />
                        )}
                    </button>
                    </div>
                    </>
                  }

                  {
                    paymentStage === ELECTRICITY_PAY_STAGE.OTP_FORM &&
                    <>
                    <OperationHeader title={TRANSACTION_TYPE} />
                    <div className="mb-3">
                      <label htmlFor="otp" className="form-label">Enter OTP code</label>
                      <input
                        type="text"
                        maxLength={6}
                        placeholder="OTP"
                        className="form-control"
                        aria-label="otp"
                        name="otp"
                        required
                        value={ formValues.otp }
                        onChange={ (e) => { handleOTPChange(e) } }
                      />
                    </div>

                    <div className="d-grid">
                      <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToPinForm}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : (
                          <Continue />
                        )}
                      </button>
                    </div>
                    </>
                  }     
                  
                  { paymentStage === ELECTRICITY_PAY_STAGE.PIN_FORM &&
                    <>
                      <OperationHeader title={TRANSACTION_TYPE} />
                      <div className="mb-3">
                        <label htmlFor="pin" className="form-label">Enter Internet Banking PIN</label>
                        <input
                          type="password"
                          maxLength={4}
                          placeholder="PIN"
                          className="form-control"
                          aria-label="pin"
                          name="pin"
                          required
                          value={ formValues.pin }
                          onChange={ handlePinInput }
                        />
                      </div>

                      <div className="d-grid">
                      <button 
                        className="btn btn-primary pay-now" 
                        type='button'
                        onClick={handleElectricityPayment}
                        >
                          {loadSpinner ? (
                            <span className="d-flex justify-content-center w-auto">
                              <Spinner />
                            </span>
                          ) : (
                            <PayNow />
                          )}
                      </button>
                      </div>
                      </>
                  }              

                  {
                    paymentStage === ELECTRICITY_PAY_STAGE.TRANSACTION_RESPONSE &&
                    <TransactionResponse 
                      amount={String(formValues.amount)} 
                      operationType={SERVICE_TYPES.ELECTRICITY_PAYMENT} 
                      success={transactionResponse?.success}
                      walletbalance={transactionResponse?.walletbalance}
                      serverResponse={
                        transactionResponse.resultdescription 
                        ? transactionResponse.resultdescription
                        : transactionResponse.responseMessage
                      }
                      otherProps={
                        {
                          ...formValues, 
                          ...transactionResponse,
                          agentName: decryptReduxValue(authRedux.user).agentname
                        }
                      }
                    />
                  }
                  
                </form>
          </div>
      </div>
    </>
  )
}

export default Electricity;
