import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { BASE_URL, BASE_ACCESS_URL } from "./urlConfig";
import Crypto from "../utils/crypto";
import log from 'loglevel';
import { APP_CONSTANTS } from "../utils/constants";

const customAxiosApi = axios.create({
  baseURL: BASE_ACCESS_URL,
  withCredentials: true,
  headers: {
    'Accept': '*/*',
    'Content-Type': 'text/plain',
    'clientid': 'ib'
  },
});

interface RetryConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
}

const globalConfig: RetryConfig = {
  retry: APP_CONSTANTS.API_REQUEST_RETRIES,
  retryDelay: APP_CONSTANTS.REQUEST_RETRY_DELAY,
};

customAxiosApi.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {

    // if(config.retry === 0 || config.retry <= 3){
    //   return config;
    // }


    // Append a custom header to the request
    // config.headers['clientid'] = "ib";

    return config;
});

customAxiosApi.interceptors.response.use(
  async (response: AxiosResponse<any, any>) => {
    const resolvedResponseData = await response.data;

    if(typeof resolvedResponseData === "string"){
      response.data = JSON.parse(Crypto.decryptValue(resolvedResponseData));
    }
    
    return response;
  },
  async (error) => {
    const { config } = error;

    log.error(JSON.stringify(error));

    if (!config || !config.retry) {
      return Promise.reject(error);
    }
    config.retry -= 1
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        // console.log("retry the request", config.url);
        //
        resolve();
      }, config.retryDelay || 1000)
    })
    return delayRetryRequest.then(() => customAxiosApi(config));
  }
);

export { globalConfig };
export default customAxiosApi;
// End interceptor for token retrieval retries

