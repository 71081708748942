import { AxiosResponse } from "axios";
import { STORE_OTP_CODE } from "../redux/actions";
import { APP_RESPONSES, RESPONSE_CODES } from "../utils/constants";
import { toastFailure } from "../utils/toasts";
import { useAppDispatch, useAppSelector } from "./useStore";
import useAuth from "./useAuth";
import { decryptReduxValue, prependCountryCode, successResponse } from "../utils/functions";
import { RootState } from "../redux/store";
import Crypto from "../utils/crypto";

function useOTP(){
      const dispatch = useAppDispatch();
      const { sendOTPRequest, doValidateOTPToAccount } = useAuth();
      const authRedux: any = useAppSelector((state: RootState) => state.auth);

      const generateOTPCode = () => {
        // This generates a random number for the OTP
        return String(Math.ceil(Math.random() * 1000000000)).slice(0,6);
      }
    
      const sendOTP = (accountNumber = "") => {
    
        // const otpPayload = {
        //   "msisdn": prependCountryCode(agentcode ? agentcode : authRedux.user.wallet_accts.agentcode),
        // }
        
        const otpPayload = {
          accountNumber: accountNumber || decryptReduxValue(authRedux.authAccountNumber)
        }
    
        // try{

          sendOTPRequest( otpPayload);

          // Send the otpCode to the user      
          //sendOTPRequest(otpPayload).then((response: AxiosResponse) => {
            // const { responseCode, responsecode } = response.data;

            // if(!successResponse([responseCode, responsecode])){
            //   toastFailure(response.data.responseMessage);
            // }
          //})
    
        // }catch(err: any){
        //   // toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR)
        // }
        
      };

      const validateOTPReq = async (otpValue: string, agentcode: string = "") => {
        // const payload = {
        //   "msisdn": prependCountryCode(agentcode ? agentcode : authRedux.user.wallet_accts.agentcode),
        //   "otp": otpValue
        // }

        const payload = {
          accountNumber: agentcode || decryptReduxValue(authRedux.authAccountNumber),
          input: otpValue
        }

        return doValidateOTPToAccount(payload);
        // return validateOTPRequest(payload);

      }

      return {
        sendOTP,
        validateOTPReq
      }
}

export default useOTP;