import { useEffect, useState } from "react";
import { routes } from "../../../../utils/constants";
import { Link } from "react-router-dom";
import "./styles/navigationdrawer.scss";
import { capitalize } from "../../../../utils/functions";
import { useNavigate } from "react-router-dom";

function NavigationDrawer() {
  const [activeRoute, setActiveRoute] = useState("dashboard");
  const [activeSubMenu, setActiveSubMenu] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const PARENT_ROUTES = {
    DASHBOARD: "Dashboard",
    TRANSFER: "Transfer",
    AIRTIMEDATA: "Airtime/Data",
    BILLPAYMENTS: "Bill payments",
    SELFSERVICES: "Self Services",
  };

  useEffect(() => {
    setTimeout(() => {
      setActiveSubMenu((prev) => true);
    }, 100);
  }, [count]);

  const switchRoute = (route: string) => {
    // Only the dashboard menu item navigates to dashboard page
    if (route.toLowerCase() === PARENT_ROUTES.DASHBOARD.toLowerCase()) {
      navigate("");
    }

    setActiveSubMenu((prev) => false);
    setActiveRoute((prev) => route);
    setCount((prev) => (prev += 1));
  };

  const hideSubMenu = () => {
    setActiveSubMenu(false);
  };

  return (
    <>
      {/* Navigation drawer*/}
      <div className="menu">
        {/* Default menu items */}
        <ul className="w-100 p-0">
          {routes.map((route: any) => {
            return (
              <li
                key={route.main}
                onClick={() => {
                  switchRoute(route.main);
                }}
                className={`w-100 ${
                  route.main === activeRoute ? "active" : ""
                }`}
              >
                <div className="w-100 px-3 py-2 text-center d-flex flex-column align-items-center">
                  <i className={`fas fa-${route.icon} fs-4`}></i>
                  <span style={{ lineHeight: 1.4 }}>{route.main}</span>
                  <span></span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      {activeRoute === PARENT_ROUTES.TRANSFER && (
        <div className={`sub-menu ${activeSubMenu ? "active-sub-menu" : ""}`}>
          <ul className="px-0">
            <h6 className="text-white ps-3">{capitalize(activeRoute)}</h6>
            <li onClick={hideSubMenu}>
              <Link to="send-money/wallet">
                <div className="d-flex justify-content-between px-3 py-3">
                  To MoneyMaster Account <span>{">"}</span>
                </div>
              </Link>
            </li>
            <li onClick={hideSubMenu}>
              <Link to="send-money/bank-transfer">
                <div className="d-flex justify-content-between px-3 py-3">
                  To Other Banks <span>{">"}</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      )}

      {activeRoute === PARENT_ROUTES.AIRTIMEDATA && (
        <div className={`sub-menu ${activeSubMenu ? "active-sub-menu" : ""}`}>
          <ul className="px-0">
            <h6 className="text-white ps-3">{capitalize(activeRoute)}</h6>
            <li onClick={hideSubMenu}>
              <Link to="airtime-data/airtime">
                <div className="d-flex justify-content-between px-3 py-3">
                  Airtime <span>{">"}</span>
                </div>
              </Link>
            </li>
            <li onClick={hideSubMenu}>
              <Link to="airtime-data/data">
                <div className="d-flex justify-content-between px-3 py-3">
                  Data <span>{">"}</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      )}

      {activeRoute === PARENT_ROUTES.BILLPAYMENTS && (
        <div className={`sub-menu ${activeSubMenu ? "active-sub-menu" : ""}`}>
          <ul className="px-0">
            <h6 className="text-white ps-3">{capitalize(activeRoute)}</h6>
            <li onClick={hideSubMenu}>
              <Link to="pay-bills/cable-tv">
                <div className="d-flex justify-content-between px-3 py-3">
                  Cable Tv <span>{">"}</span>
                </div>
              </Link>
            </li>
            <li onClick={hideSubMenu}>
              <Link to="pay-bills/electricity">
                <div className="d-flex justify-content-between px-3 py-3">
                  Electricity <span>{">"}</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      )}

      {activeRoute === PARENT_ROUTES.SELFSERVICES && (
        <div className={`sub-menu ${activeSubMenu ? "active-sub-menu" : ""}`}>
          <ul className="px-0">
            <h6 className="text-white ps-3">{capitalize(activeRoute)}</h6>
            <li onClick={hideSubMenu}>
              <Link to="self-services/reset-pin">
                <div className="d-flex justify-content-between px-3 py-3">
                  Reset PIN <span>{">"}</span>
                </div>
              </Link>
            </li>
            <li onClick={hideSubMenu}>
              <Link to="self-services/update-nin">
                <div className="d-flex justify-content-between px-3 py-3">
                  Update NIN <span>{">"}</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      )}
      {/* Navigation Drawer End */}
    </>
  );
}

export default NavigationDrawer;
