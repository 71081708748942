import CryptoJS from "crypto-js";

const encryptValue = (valueToEncrypt: string) => {
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY!); //Length 32
    const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_IV!); //Length 16

    const cipherText = CryptoJS.AES.encrypt(valueToEncrypt, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString();

    return cipherText;
}

const decryptValue = (valueToDecrypt: string) => {
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY!); //Length 32
    const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_IV!); //Length 16

    const plainText = CryptoJS.AES.decrypt(valueToDecrypt, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return plainText;
}

export default {
    encryptValue,
    decryptValue
}