import { ReactElement, useEffect, useLayoutEffect } from "react";
import useDataInfo from "../../../../hooks/useDataInfo";
import { ACCOUNT_TYPES, APP_ACCT_TYPES, APP_RESPONSES, NAIRA_SYMBOL, TRANSACTION_TYPES, USER_ACCOUNT_TYPES } from "../../../../utils/constants";
import { capitalize, decryptReduxValue, formatAmount, getAllAccounts, successResponse } from "../../../../utils/functions";
import useAuth from "../../../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";

function RenderAllUserAccountOptions(){
    const { auth: authRedux, userAccounts: userAccountsRedux }: any = useAppSelector((state: RootState) => (state));
    
    const accCategorySupported = (value: string) => {
        return value.split("|")[7] === "ACTIVE"
    }

    return (
        <>
        { 
            Object.values(getAllAccounts(userAccountsRedux.accounts) as [])
            .reduce((prev: string[], next: string) => {
                // Check if the account category is not supported
                if(!accCategorySupported(next)) return prev;

                
                if((next.split("|")[1]).toUpperCase() === USER_ACCOUNT_TYPES.WALLET.toUpperCase()){
                    prev.unshift(next); // Ensures Wallet account is the first to be rendered
                }else{
                    prev.push(next);
                }

                return prev;
            },[])
            .map((account: any) => {
                const accountParams = account.split("|");
                
                return (
                    <option value={ accountParams[0] } key={accountParams[0]}>
                        {capitalize(accountParams[1])} - {accountParams[0]} - {NAIRA_SYMBOL}{ formatAmount(accountParams[2].split('.')[0]) }.{ accountParams[2].split('.')[1] }
                    </option> 
                )
            })
        }      
        </>
    )
}

export default RenderAllUserAccountOptions;