import { createSlice } from "@reduxjs/toolkit";
import Crypto from "../../utils/crypto";

const initialState: {accounts: string, details: string} = {
    accounts : "",
    details: ""
}

const userAccountsSlice = createSlice({
    name: "userAccounts",
    initialState,
    reducers: {
        setAccounts: (state, payload) => {
            return {
                ...state,
                accounts: Crypto.encryptValue(JSON.stringify(payload.payload))
            }
        },
        logout: (state, payload) => {
            return {...initialState}
        },
        saveAccountDetails: (state, payload) => {
            return {
                ...state,
                details: Crypto.encryptValue(JSON.stringify(payload.payload))
            }
        },
        userReset: (state, payload) => ({ ...initialState })
    }
});

export const userAccountsReducer = userAccountsSlice.reducer;

export const { logout, setAccounts, saveAccountDetails, userReset } = userAccountsSlice.actions;