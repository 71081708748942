import { useLocation, useNavigate } from "react-router-dom";
import { ITransactionResponseProps } from "../../../../interfaces/auth";
import {
  ACCOUNT_TYPES,
  NAIRA_SYMBOL,
  SELF_SERVICES,
  SERVICE_TYPES,
  SYMBOL_TYPES,
} from "../../../../utils/constants";
import {
  capitalize,
  decryptReduxValue,
  formatKoboValue,
  formatTableAmount,
  prependZero,
} from "../../../../utils/functions";
import "./styles/transaction-response.scss";
import { useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import { useRef } from "react";

function TransactionResponse(props: ITransactionResponseProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const authRedux = useAppSelector((state: RootState) => state.auth);

  const navigateTransactionHome = () => {
    const urlPath = location.pathname.split("/");
    urlPath.pop();
    navigate(urlPath.join("/"));
  };

  const FormulateMessage = (allProps: ITransactionResponseProps) => {
    let TransResIconAndText;
    let TransResBody;
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const amountRef = useRef<any>();
    amountRef.current = "";

    const { success, operationType } = allProps;

    // Set the amount to two decimal places if present
    if (typeof allProps.amount !== "undefined") {
      amountRef.current = formatTableAmount(allProps.amount);
    }

    const getTransId = () => {
      return allProps.otherProps.transid;
    };

    // let truthy = "true";

    // Choose icon based on operation status
    switch (success) {
      case true:
        TransResIconAndText = () => (
          <>
            <p className="text-success text-10 lh-1">
              <i className="fas fa-check-circle"></i>
            </p>
            <p className="text-success text-6 fw-500 lh-1 mb-0">Success!</p>
          </>
        );
        break;
      case false:
        TransResIconAndText = () => (
          <>
            <p className="text-danger text-10 lh-1">
              <i className="fas fa-exclamation-triangle"></i>
            </p>
            <p className="text-danger text-6 fw-500 lh-1 mb-0">Oops!</p>
          </>
        );
        break;
      default:
        TransResIconAndText = () => (
          <>
            <p className="text-danger text-10 lh-1">
              <i className="fas fa-exclamation-triangle"></i>
            </p>
            <p className="text-danger text-6 fw-500 lh-1 mb-0">Oops!</p>
          </>
        );
        break;
    }

    if (success) {
      // Build the transaction response
      const todays_date = new Date();
      // const dayPeriod = todays_date.toLocaleTimeString(undefined, {hour12: true}).split(" ")[1];

      let date_time = `${todays_date.getDate()}-${
        month[todays_date.getMonth()]
      }-${todays_date.getFullYear()} ${prependZero(
        todays_date.getHours()
      )}:${prependZero(todays_date.getMinutes())}`;

      switch (operationType) {
        // With transaction IDS
        case SERVICE_TYPES.WALLET_TRANSFER:
          const {
            otherProps: {
              sourceAccount: gKalaSenderAccountNumber,
              agentName: gKalaSenderName,
            },
          } = allProps;

          TransResBody = () => (
            <>
              {/* TxnID: {getTransId()} Payment Successful. <span className="text-4 fw-500">{NAIRA_SYMBOL}{amountRef.current}</span> sent to {allProps.otherProps.beneficiaryName} - {allProps.otherProps.destination} on {date_time}. */}

              {/* Brief summary */}
              <p className="bg-grey rounded px-1">
                Transfer Successful. {NAIRA_SYMBOL}
                {amountRef.current} sent to{" "}
                {capitalize(allProps.otherProps.beneficiaryName)} -{" "}
                {allProps.otherProps.destination}.
              </p>

              <div className="d-flex justify-content-between mb-2">
                <span>Transaction Id</span>{" "}
                <span className="fw-400">{getTransId()}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Amount</span>{" "}
                <span className="fw-400">
                  {NAIRA_SYMBOL}
                  {amountRef.current}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Sender Name</span>{" "}
                <span className="fw-400">{capitalize(gKalaSenderName)}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Sender Account No</span>{" "}
                <span className="fw-400 ">{gKalaSenderAccountNumber}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Date/Time</span>{" "}
                <span className="fw-400">{date_time}</span>
              </div>
            </>
          );
          break;
        case SERVICE_TYPES.AIRTIME:
          const {
            otherProps: {
              agentcode: airtimeSenderAccountNumber,
              agentName: airtimeSenderName,
            },
          } = allProps;

          TransResBody = () => (
            <>
              {/* TxnID: {getTransId()} {date_time}. You have purchased <span className="text-4 fw-500">{NAIRA_SYMBOL}{amountRef.current}</span> of {allProps.otherProps.network} airtime on behalf of {allProps.otherProps.activeAccType === ACCOUNT_TYPES.SELF ? allProps.otherProps.agentcode : allProps.otherProps.destination}. */}

              {/* Brief summary */}
              <p className="bg-grey rounded px-1 text-center">
                You have purchased {NAIRA_SYMBOL}
                {amountRef.current} of {allProps.otherProps.network} airtime on
                behalf of {allProps.otherProps.destination}.
              </p>

              <div className="d-flex justify-content-between mb-2">
                <span>Transaction Id</span>{" "}
                <span className="fw-400">{getTransId()}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Amount</span>{" "}
                <span className="fw-400">
                  {NAIRA_SYMBOL}
                  {amountRef.current}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Sender Name</span>{" "}
                <span className="fw-400">{airtimeSenderName}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Sender Account Number</span>{" "}
                <span className="fw-400">{airtimeSenderAccountNumber}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Date/Time</span>{" "}
                <span className="fw-400">{date_time}</span>
              </div>
            </>
          );
          break;
        case SERVICE_TYPES.DATA:
          const {
            otherProps: {
              agentcode: dataSenderNumber,
              agentName: dataSenderName,
            },
          } = allProps;

          TransResBody = () => (
            <>
              {/* TxnID: {getTransId()} {date_time}. You have purchased <span className="text-4 fw-500">{NAIRA_SYMBOL}{amountRef.current}</span> of {allProps.otherProps.network} Data on behalf of {allProps.otherProps.destination}. */}

              {/* Brief summary */}
              <p className="bg-grey rounded px-1 text-center">
                You have purchased {NAIRA_SYMBOL}
                {allProps.amount} of {allProps.otherProps.network} Data on
                behalf of {allProps.otherProps.destination}.
              </p>

              <div className="d-flex justify-content-between mb-2">
                <span>Transaction Id</span>{" "}
                <span className="fw-400">{getTransId()}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Amount</span>{" "}
                <span className="fw-400">
                  {NAIRA_SYMBOL}
                  {formatTableAmount(allProps.amount?.split("=")[0] || "")}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Network</span>{" "}
                <span className="fw-400">{allProps.otherProps.network}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Sender Name</span>{" "}
                <span className="fw-400">{dataSenderName}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Sender Account Number</span>{" "}
                <span className="fw-400">{dataSenderNumber}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Date/Time</span>{" "}
                <span className="fw-400">{date_time}</span>
              </div>
            </>
          );
          break;

        // No transaction IDS
        case SERVICE_TYPES.BANK_TRANSFER:
          const {
            otherProps: {
              destinationInstitutionName,
              sourceaccount: senderAccountNumber,
              agentName: senderName,
              accountRedux: { details },
            },
          } = allProps;

          const {
            accountName: beneficiaryAccountName,
            accountNumber: beneficiaryAccountNumber,
          } = decryptReduxValue(details);

          TransResBody = () => (
            <>
              {/* Payment Successful. <span className="text-4 fw-500">{NAIRA_SYMBOL}{amountRef.current}</span> sent to <strong>{destinationInstitutionName}</strong>, AccName <strong>{beneficiaryAccountName}</strong>, BankAcc <strong>{beneficiaryAccountNumber}</strong> on <strong>{date_time}</strong>. */}

              {/* Brief summary */}
              <p className="bg-grey rounded px-1 text-center">
                Transfer Successful. {NAIRA_SYMBOL}
                {amountRef.current} sent to {destinationInstitutionName},
                Account Name - {beneficiaryAccountName}, Bank Account -{" "}
                {beneficiaryAccountNumber}
              </p>

              {/* <div className="d-flex justify-content-between mb-2"><span>Amount</span> <span className="fw-400">{NAIRA_SYMBOL}{amountRef.current}</span></div>
            <div className="d-flex justify-content-between mb-2"><span>Beneficiary Bank</span> <span className="fw-400">{destinationInstitutionName}</span></div>
            <div className="d-flex justify-content-between mb-2"><span>Beneficiary Account Name</span> <span className="fw-400 text-end">{beneficiaryAccountName}</span></div>
            <div className="d-flex justify-content-between mb-2"><span>Beneficiary Account No</span> <span className="fw-400">{beneficiaryAccountNumber}</span></div>
            <div className="d-flex justify-content-between"><span>Date/Time</span> <span className="fw-400">{date_time}</span></div> */}
              <div className="d-flex justify-content-between mb-2">
                <span>Amount</span>{" "}
                <span className="fw-400">
                  {NAIRA_SYMBOL}
                  {amountRef.current}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Sender Name</span>{" "}
                <span className="fw-400 text-end">{senderName}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Sender Account No</span>{" "}
                <span className="fw-400">{senderAccountNumber}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Date/Time</span>{" "}
                <span className="fw-400">{date_time}</span>
              </div>
            </>
          );
          break;

        case SERVICE_TYPES.ELECTRICITY_PAYMENT:
          const {
            otherProps: { agentcode, disco, meternumber, agentName: payerName },
          } = allProps;

          TransResBody = () => (
            <>
              {/* Payment Successful. You have purchased <span className="text-4 fw-500">{NAIRA_SYMBOL}{amountRef.current}</span> of Electricity Token on behalf of {meternumber} - {disco} Disco. */}

              {/* Brief summary */}
              <p className="bg-grey rounded px-1">
                Payment Successful. You have purchased {NAIRA_SYMBOL}
                {amountRef.current} of Electricity Token on behalf of{" "}
                {meternumber} - {disco} Disco.
              </p>

              <div className="d-flex justify-content-between mb-2">
                <span>Amount</span>{" "}
                <span className="fw-400">
                  {NAIRA_SYMBOL}
                  {amountRef.current}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Meter Number</span>{" "}
                <span className="fw-400">{meternumber}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Disco</span> <span className="fw-400">{disco}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Payer Name</span>{" "}
                <span className="fw-400 text-end">{payerName}</span>
              </div>
            </>
          );
          break;
        case SERVICE_TYPES.CABLETV_SUBSCRIPTION:
          const {
            otherProps: {
              cardnumber,
              package: cableTvPackage,
              agentName: subscriberName,
              agentcode: subscriberAccountNumber,
            },
          } = allProps;

          TransResBody = () => (
            <>
              {/* Brief summary */}
              <p className="bg-grey rounded px-1">
                Payment Successful. You have purchased {cableTvPackage} -{" "}
                {NAIRA_SYMBOL}
                {amountRef.current} on behalf of {cardnumber} on {date_time}
              </p>

              <div className="d-flex justify-content-between mb-2">
                <span>Cable Tv Package</span>{" "}
                <span className="fw-400">
                  {NAIRA_SYMBOL}
                  {cableTvPackage}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Subscriber Name</span>{" "}
                <span className="fw-400">{subscriberName}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Subscriber Account Number</span>{" "}
                <span className="fw-400">{subscriberAccountNumber}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Date/Time</span>{" "}
                <span className="fw-400">{date_time}</span>
              </div>
            </>
          );
          break;
        case SELF_SERVICES.RESET_PIN:
          TransResBody = () => (
            <p className="d-flex justify-content-center">
              {props.serverResponse}
            </p>
          );
          break;
        case SELF_SERVICES.UPDATE_NIN:
          TransResBody = () => (
            <p className="d-flex justify-content-center">
              {props.serverResponse}
            </p>
          );
          break;
        default:
          TransResBody = () => (
            <>
              <span className="text-4 fw-500 text-center">NOT SUCCESSFUL:</span>
              <br />
              {props.serverResponse}
            </>
          );
          break;
      }
    } else {
      TransResBody = () => (
        <>
          <span className="text-4 fw-500 text-center">NOT SUCCESSFUL:</span>
          <br />
          {props.serverResponse}
        </>
      );
    }

    return (
      <>
        <div className="text-center mb-4 transaction-response">
          <div className="wrapper py-2">
            <div className="pt-2">
              <TransResIconAndText />
            </div>

            <div className="divider">
              <span></span>
              <span></span>
              <span></span>
            </div>

            <p
              className={`text-2 ${
                success ? "text-start" : "text-center"
              } response-body`}
            >
              <TransResBody />
            </p>

            <button
              className={`btn mb-2 mt-5 btn-primary`}
              onClick={() => navigateTransactionHome()}
            >
              <i className="fas fa-home mb-015 me-2 align-self-center"></i> Back
              to home
            </button>
          </div>
        </div>
      </>
    );
  };

  return <FormulateMessage {...props} />;
}

export default TransactionResponse;
