
import { useState, ChangeEvent, useEffect, FormEvent, useRef } from "react";
import useTransactions from "../../../../hooks/useTransactions";
import { toastFailure, toastSuccess } from "../../../../utils/toasts";
import CryptoJS from "../../../../utils/crypto";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import Spinner from "../../../../components/Spinner/Spinner";
import { ACCOUNT_TYPES, APP_ACCT_TYPES, APP_RESPONSES, BANK_TRANSFER_STAGE, NAIRA_SYMBOL, OPERATION_HEADERS, RESPONSE_CODES, SERVICE_TYPES, SYMBOL_TYPES, TRANSACTION_ENDPOINTS, TRANSACTION_TYPES } from "../../../../utils/constants";
import Confirmation from "../../components/ConfirmationPage/ConfirmationPage";
import TransactionResponse from "../../components/TransactionResponse/TransactionResponse";
import { IBank } from "../../../../interfaces/auth";
import useDataInfo from "../../../../hooks/useDataInfo";
import { SAVE_ACCOUNT_DETAILS, SET_TRANSACTION_ENDPOINT } from "../../../../redux/actions";
import "./styles/banktransfer.scss";
import OperationHeader from "../../components/OperationHeader/OperationHeader";
import useOTP from "../../../../hooks/useOTP";
import { capitalize, decimalPointEnd, decimalPointFound, decimalPointMiddle, decryptReduxValue, formatAmount, generateRandomNumber, removeCommas, successResponse } from "../../../../utils/functions";
import { toast } from "react-toastify";
import Symbols from "../../components/Symbols/Symbols";
import RenderAllUserAccountOptions from "../../components/RenderAllUserAccountOptions/RenderAccountOptions";
import { setTranEndpoint } from "../../../../redux/features/transactionSlice";
import { WALLET_ACCOUNT } from "../../../../utils/constants";
import { NON_WALLET_ACCOUNT } from "../../../../utils/constants";
import { saveAccountDetails } from "../../../../redux/features/userAccountsSlice";

const TRANSFER_PARAMETERS = {
  MINIMUM_AMOUNT : 10
};

function BankTransfer() {
  const [formValues, setFormValues] = useState({
    destinationInstitutionCode: "",
    destinationInstitutionName: "",
    accountNumber: "",
    amount: "",
    pin: "",
    otp:"",
    sourceaccount: "",
    sourceAccountLabel: "",
    originatorAccountNumber: "",
    kyc: "",
    bvn: ""
  });
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [isOperationActive, setOperationActive] = useState(false);
  const [transferStage, setTransferStage] = useState(BANK_TRANSFER_STAGE.SELECT_BANK);
  const [banksList, setBanksList] = useState([]);
  const { transferToBankAccount } = useTransactions();
  const { getBanksList, accountLookup, updateBalanceInStore, getSourceAccount } = useDataInfo();
  const authRedux: any = useAppSelector((state: RootState) => (state.auth));
  const accountRedux: any = useAppSelector((state: RootState) => (state.userAccounts));
  const dispatch = useAppDispatch();
  const [accountName, setAccountName] = useState("");
  const [formFieldDisabled, setFormFieldsetDisabled] = useState(false);
  const [isAccountLookup, setIsAccountLookup] = useState(false);
  const { sendOTP, validateOTPReq } = useOTP();
  const sourceAccountRef = useRef<any>(null);

  const [transactionResponse, setTransactionResponse] = useState({
    walletbalance: "",
    responseCode: "",
    responsecode: "",
    resultdescription: "",
    responseMessage: "",
    success: false,
  });
  const {transaction: transactionRedux, userAccounts: userAccountsRedux}: any = useAppSelector((state: RootState) => (state));

  useEffect(() => {
    const controller = new AbortController();

    initializeBanksList(controller.signal);
    
    resetFormState();

    initializeSourceAccount();

    return () => {
      controller.abort();
    }
  }, [])

  const initializeBanksList = async (abortSignal: AbortSignal) => {
    const list = await getBanksList(abortSignal);
    
    //Sorts the list in alphabetical order
    list.data.sort((a: any, b: any) => {
      let fb = b.institutionName.toUpperCase().replace(" ", "");
      let fa =  a.institutionName.toUpperCase().replace(" ", "");
      
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    setBanksList(list.data);
  }

  const resetFormState = () => {
    setFormValues({...formValues, accountNumber: ""})
  }

  const initializeSourceAccount = () => {
    setFormValues((prev) => ({
      ...prev,
      sourceaccount: sourceAccountRef.current?.options[0].value,
      sourceAccountLabel: sourceAccountRef.current?.options[0].innerHTML
    }))
  }

  const handleChange = (event: ChangeEvent<any>) => {
    setAccountName("");
    
    setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
    })
  }

  const validateForm = () => {
    const { accountNumber, destinationInstitutionCode, sourceaccount } = formValues;

    const errors = {
      accountNumber: "",
      destinationInstitutionCode: "",
      sourceaccount: "",
    }

    errors.sourceaccount = sourceaccount === "" ? "Source account not selected" : "";
    errors.destinationInstitutionCode = destinationInstitutionCode === "" ? "Bank not selected": "";
    errors.accountNumber = accountNumber === "" || accountNumber.length < 10 ? "Invalid account number": "";
    
    const allErrors = Object.values(errors).filter((error) => error !== "");
    
    return allErrors;
  }

  const getRequestPayLoad = () => {
    const details = decryptReduxValue(userAccountsRedux.details);

    return {
      "agentcode": formValues.sourceaccount,
      "amount": removeCommas(formValues.amount),
      "pin": CryptoJS.encryptValue(formValues.pin),
      "type": "1",
      "comments": `TRF FRM ${ decryptReduxValue(authRedux.user).agentname}`,
      "destinationInstitutionCode": formValues.destinationInstitutionCode,
      "channelcode": "3",
      "benefaccountname": details.accountName,
      "benefaccountno": details.accountNumber,
      "benefverificationno": "",
      "benefkyclevel": details.kyclevel,
      "originatorAccountName": decryptReduxValue(authRedux.user).agentname,
      "originatorAccountNumber": formValues.sourceaccount,
      "originatorKYCLevel": formValues.kyc,
      "originatorBankVerificationNumber" : formValues.bvn,
      "nameenquiryref": details.sessionID,
      "otp": formValues.otp,
      "clientType":"SELFCARE"
    } 
  }
  
  const transferMoney = async () => {
    try{
      const requestPayload = getRequestPayLoad();

      const response = await transferToBankAccount(requestPayload);
  
      handleTransferResponse(response);
      hideSpinner();
    }catch(err: any){
      toast.dismiss();

      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);

      displayTransactionResponse(err.data);
      
      hideSpinner();
    }
    
  }

  const handleTransferResponse = (response: any) => {
    const { responsecode, responseCode } = response.data;

    if(successResponse([responsecode, responseCode])){
      toastSuccess(!!response.data.resultdescription ? response.data.resultdescription : response.data.responseMessage);

      updateBalanceInStore(response.data.walletbalance);
      
    }else{
      toast.dismiss();

      toastFailure(!!response.data.responseMessage ? response.data.responseMessage : response.data.resultdescription);
    }

    displayTransactionResponse({
      ...response.data,
      success: successResponse([responsecode, responseCode])
    })
  }

  const displayTransactionResponse = (response : any) => {
    setTransactionResponse(response);
    setTransferStage(BANK_TRANSFER_STAGE.TRANSACTION_RESPONSE)
  }

  const validateOTP = async() => {
    toast.dismiss();

    if(formValues.otp === ""){
      toastFailure(APP_RESPONSES.INVALID_OTP_CODE);
      return false;
    }

    try {
      // Validate the otp through server request validation
      const response = await validateOTPReq(formValues.otp);
      
      const { responseCode, responsecode } = response.data;

      if(!successResponse([responseCode, responsecode])){
        toastFailure(response.data.responseMessage);
        return false;
      }

      return true;
    } catch (error: any) {
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR)
      return false;
    }
  }

  const disableTransferButton = ()=> {
    document.querySelector('.complete-transfer')?.setAttribute('disabled', 'disabled');
  }

  const handleBankTransfer: any = async (e : FormEvent) => {
    e.preventDefault();

    // Disable the complete transfer button
    disableTransferButton();

    // if any error is found, this returns true
    if(formValid().length > 0) return;
    
    displaySpinner();
   
    setTimeout( async () => {
      transferMoney();
    }, 2000)

  }

  const displaySpinner = () => {
    if (!loadSpinner) setLoadSpinner(true);
    setOperationActive(true);
  };

  const hideSpinner = () => setLoadSpinner(false);

  const switchToPinForm = async () => {
    displaySpinner();

    const otpValid = await validateOTP();

    // If OTP is not valid return
    if(!otpValid){
      hideSpinner();
      return;
    }

    setTimeout(() => {
      setTransferStage(BANK_TRANSFER_STAGE.PIN_FORM);
      hideSpinner();
    }, 2000);
  }

  const isInvalidAmount = () => (Number(formValues.amount) < TRANSFER_PARAMETERS.MINIMUM_AMOUNT);

  const formValid = () => {
    const errors = {
      sourceaccount: ""
    }

    errors.sourceaccount = formValues.sourceaccount === "" ? APP_RESPONSES.SOURCE_ACCOUNT_NOT_SELECTED: "";

    const errorResponse = Object.values(errors).filter((error) => error !== "");

    return errorResponse;
  };

  const switchToConfirmPage = () => {
    toast.dismiss();

    displaySpinner();

    if(isInvalidAmount()){
      toast.dismiss();

      toastFailure(APP_RESPONSES.INVALID_AMOUNT);

      hideSpinner();
      return;
    }

    setTimeout(() => {
      setTransferStage(BANK_TRANSFER_STAGE.CONFIRMATION_PAGE);
      hideSpinner();
    }, 2000);
  }

  const displayAccountName = (name: string) => {
    setAccountName(capitalize(name));
  }

  const doAccountLookup = async (abortSignal: AbortSignal) => {

    toast.info("Validating account ...", {
      isLoading: true
    });
    
    try{
      const lookupResponse = 
        await accountLookup({ 
          destinationInstitutionCode: formValues.destinationInstitutionCode, 
          accountNumber: formValues.accountNumber
        },
          abortSignal
          );
      
      toast.dismiss();

      if(lookupResponse.data.responseCode === RESPONSE_CODES.SUCCESS){
        // Save account details to store
        // dispatch({type: SAVE_ACCOUNT_DETAILS, payload: lookupResponse.data});
        dispatch(saveAccountDetails(lookupResponse.data));

        displayAccountName(lookupResponse.data.accountName);
      }else{
        toast.dismiss();

        toastFailure(lookupResponse.data.responseMessage === "" ? APP_RESPONSES.NIP_LOOKUP_FAILED : lookupResponse.data.responseMessage);
      }

      setIsAccountLookup(false);
    }catch(err: any){
      setIsAccountLookup(false);
      toast.dismiss();
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);
    }
    
  }

  const showEnterAmountForm = () => {
    toast.dismiss();

    displaySpinner();

    setTimeout(() => {
      setTransferStage(BANK_TRANSFER_STAGE.ENTER_AMOUNT);
      hideSpinner();
    }, 2000);
  }

  const switchToOTPForm = () => {
    displaySpinner();

    sendOTP();

    setTimeout(() => {
      setTransferStage(BANK_TRANSFER_STAGE.OTP_FORM);
      hideSpinner();
    }, 2000);
  }

  const handlePinInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      pin: e.target.value
    })
  };

  // This monitors the change in the select bank form input fields
  useEffect(() => {
    toast.dismiss();
    setAccountName("");
    const formErrors = validateForm();

    if(formErrors.length > 0) {
      return;
    } 

    const controller = new AbortController();
  
    (async(controller) => {
      await doAccountLookup(controller.signal); 
    })(controller)
    
  
    return () => {
      controller.abort();
    }
  }, [formValues.destinationInstitutionName, formValues.accountNumber, formValues.sourceaccount]);

  const handleBankChange = (event: any ) => {
    setAccountName("");
    let bankDetails: string[] = [];

    // If no bank is selected, do this
    if(event.target.value === ""){
      bankDetails = ["", ""]
    }else{
      bankDetails = JSON.parse(event?.target.value);
    }

    setFormValues({
        ...formValues,
        destinationInstitutionCode: bankDetails[0],
        destinationInstitutionName: bankDetails[1],
    })
  }

  const getBanksListOptions = () => {
    if(banksList.length === 0) return [];

    const options = banksList.reduce((prev: any, curr: any) => {
      return [...prev, {
        value: JSON.stringify([curr.institutionCode, curr.institutionName]),
        label: curr.institutionName
      }]
    },[]);
    return options;
  }

  const handleOTPChange = (event: any) => {
    const { value } = event.target;

    if(value.length > 0 && Number.isNaN(Number(value)))return;

    setFormValues((prev) => ({ ...prev, otp: event.target.value }))
  }

  const handleKoboInput = (userKoboInput: string) => {
    
    // Check if the values after decimal point is of length 2
    if(userKoboInput.length <= 2){
      setFormValues({
        ...formValues,
        amount: formValues.amount.replace(/\.[0-9]*$/, "." + userKoboInput),
      });
    }
  }

  const handleAmountInput = (event: ChangeEvent<HTMLInputElement>) => {
    if(loadSpinner) return
    
    const userInput = event.target.value;

    // Check if userInput has a dot value
    if(decimalPointFound(userInput) && decimalPointMiddle(userInput)){
      
      if(isNaN(Number(userInput.split(".")[1]))) return

      handleKoboInput(userInput.split(".")[1]);
      return
    }
    else if(decimalPointFound(userInput) && decimalPointEnd(userInput)) {
      
      setFormValues({
        ...formValues,
        amount: userInput
      })
      return
    }

    const formattedAmount = formatAmount(userInput);
    
    if(Number.isNaN(formattedAmount)) return;

    setFormValues({
      ...formValues,
      amount: formattedAmount as string,
    });
 }


  const setKycAndBvn = (event: any) => {
    if(event.target?.value === "") return;

    const {value} = event.target; // value is the account number

    const accDetails = decryptReduxValue(userAccountsRedux.accounts)[value].split("|");

    setFormValues((prev) => ({...prev, kyc: accDetails[5], bvn: accDetails[6]}));
  }

  const setSourceAccount = (event: ChangeEvent<HTMLSelectElement>) => {
    if(event.target.value === "") return // The first empty option was selected, hence no value. 

    const sourceAccountLabel = event.target.selectedOptions[0].innerHTML as string;

    setFormValues((prev) => ({
      ...prev,
      sourceAccountLabel,
      sourceaccount: event.target.value
    }));
  }

  return (
    <>
      <div className="row w-100 mt-5 justify-content-center align-items-center flex-column bank-transfer-container container-padding">
          
          <div className="col-md-9 col-lg-7 col-xl-7 mx-auto mb-5">
              <form id="form-send-money px-md-0" onSubmit={(e) => { e.preventDefault() }}>
                  {transferStage === BANK_TRANSFER_STAGE.SELECT_BANK &&
                    <>
                    <OperationHeader title={OPERATION_HEADERS.BANK_TRANSFER} />
                    <div className="mb-3">
                      <label htmlFor="sourceaccount" className="form-label">
                        Select Source Account
                      </label>
                      <select 
                        className="form-select" 
                        name="sourceaccount" 
                        required 
                        ref={ sourceAccountRef }
                        onChange={(e) => {
                          setSourceAccount(e);
                          setKycAndBvn(e);
                        }}>
                        {/* <option value="">Source Account</option> */}
                        <RenderAllUserAccountOptions />
                       </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="destinationInstitutionCode" className="form-label">Select Bank</label>
                        <select name="destinationInstitutionCode" className="form-select" id="bankslist"  onChange={handleBankChange}>
                          <option value="">Choose Bank</option>
                          {
                            banksList
                              .map((bank: IBank) => (<option key={bank.institutionCode} value={JSON.stringify([bank.institutionCode,bank.institutionName])}>
                                { bank.institutionName.toUpperCase() }
                                </option>))
                          }
                        </select>
                    </div>

                    <div className={`mb-3 ${ accountName ? "" : "mb-5" }`}>
                      <label htmlFor="accountNumber" className="form-label">Account Number</label>
                      <input
                          type="text"
                          maxLength={10}
                          placeholder="Account number"
                          className="form-control"
                          aria-label="accountNumber"
                          name="accountNumber"
                          required
                          value={ formValues.accountNumber }
                          onChange={ handleChange }
                      />
                    </div>

                    {/*  Displayed if an account is found */}
                    {
                      accountName &&
                      <>
                      <div className={accountName !== "" ? "mb-3": ""}>
                      {accountName !== "" ? <label className="form-label text-primary"><i className="fas fa-check-circle"></i> {accountName}</label> : ""}
                      </div>
                      <div className={`d-grid ${accountName !== "" ? "": ""}`}>
                        <button 
                          className="btn btn-primary" 
                          type='button'
                          onClick={ showEnterAmountForm }
                          >
                          {loadSpinner ? (
                            <span className="d-flex justify-content-center w-auto">
                              <Spinner />
                            </span>
                          ) : 
                          <span className="d-flex justify-content-center"><i className="fas fa-arrow-alt-circle-right mb-015 me-2 align-self-center"></i> Continue</span>
                          }
                        </button>
                      </div>
                      </>
                    }
                    </>
                  }

                  {
                    transferStage === BANK_TRANSFER_STAGE.ENTER_AMOUNT && 
                    <>
                    <OperationHeader title={OPERATION_HEADERS.BANK_TRANSFER} />
                    <div className="mb-3">
                      <label htmlFor="amount" className="form-label">Enter Amount</label>
                      <input
                        type="text"
                        placeholder="0.00"
                        className="form-control"
                        name="amount"
                        required
                        value={ formValues.amount }
                        onChange={ handleAmountInput }
                      />
                    </div>

                    <div className="d-grid">
                    <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToConfirmPage}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : 
                          <span className="d-flex justify-content-center"><i className="fas fa-arrow-alt-circle-right mb-015 me-2 align-self-center"></i> Continue</span>
                        }
                    </button>
                    </div>
                    </>
                  }

                  {
                    transferStage === BANK_TRANSFER_STAGE.CONFIRMATION_PAGE &&
                    <>
                    <Confirmation 
                      amount={String(formValues.amount)} 
                      operationType={SERVICE_TYPES.BANK_TRANSFER} 
                      number={formValues.accountNumber}
                      bankName={formValues.destinationInstitutionName}
                      beneficiaryName={accountName}
                      sourceAccount={ formValues.sourceAccountLabel }
                      sourceName={ authRedux.user.agentname }
                      />
                    <div className="d-grid">
                    <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToOTPForm}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : 
                        <span className="d-flex justify-content-center"><i className="fas fa-arrow-alt-circle-right mb-015 me-2 align-self-center"></i> Continue</span>
                        }
                    </button>
                    </div>
                    </>
                  }

                  {
                    transferStage === BANK_TRANSFER_STAGE.OTP_FORM &&
                    <>
                    <OperationHeader title={OPERATION_HEADERS.BANK_TRANSFER} />
                    <div className="mb-3">
                      <label htmlFor="otp" className="form-label">Enter OTP code</label>
                      <input
                        type="text"
                        maxLength={6}
                        placeholder="OTP"
                        className="form-control"
                        aria-label="otp"
                        name="otp"
                        required
                        value={ formValues.otp }
                        onChange={ (e) => { handleOTPChange(e) } }
                      />
                    </div>

                    <div className="d-grid">
                      <button 
                      className="btn btn-primary" 
                      type='button'
                      onClick={switchToPinForm}
                      >
                        {loadSpinner ? (
                          <span className="d-flex justify-content-center w-auto">
                            <Spinner />
                          </span>
                        ) : 
                          <span className="d-flex justify-content-center"><i className="fas fa-arrow-alt-circle-right mb-015 me-2 align-self-center"></i> Continue</span>
                        }
                      </button>
                    </div>
                    </>
                  }     
                  
                  { transferStage === BANK_TRANSFER_STAGE.PIN_FORM &&
                    <>
                      <OperationHeader title={OPERATION_HEADERS.BANK_TRANSFER} />
                      <div className="mb-3">
                        <label htmlFor="pin" className="form-label">Enter Internet Banking PIN</label>
                        <input
                          type="password"
                          maxLength={4}
                          placeholder="PIN"
                          className="form-control"
                          aria-label="pin"
                          name="pin"
                          required
                          value={ formValues.pin }
                          onChange={ handlePinInput }
                        />
                      </div>

                      <div className="d-grid">
                      <button 
                        className="btn btn-primary complete-transfer" 
                        type='button'
                        onClick={handleBankTransfer}
                        >
                          {loadSpinner ? (
                            <span className="d-flex justify-content-center w-auto">
                              <Spinner />
                            </span>
                          ) : (
                            <span className="d-flex justify-content-center"><i className="fas fa-share-square mb-015 me-2 align-self-center"></i> Complete Transfer</span> 
                          )}
                      </button>
                      </div>
                      </>
                  }              

                  {
                    transferStage === BANK_TRANSFER_STAGE.TRANSACTION_RESPONSE &&
                    <TransactionResponse 
                      amount={String(formValues.amount)} 
                      operationType={SERVICE_TYPES.BANK_TRANSFER} 
                      success={transactionResponse?.success}
                      walletbalance={transactionResponse?.walletbalance}
                      serverResponse={
                        transactionResponse?.resultdescription 
                        ? transactionResponse?.resultdescription
                        : transactionResponse?.responseMessage
                       }
                      otherProps={
                        {
                          ...formValues, 
                          accountRedux, 
                          agentName: decryptReduxValue(authRedux.user).agentname
                        }
                      } 
                      />
                  }
                  
                </form>
          </div>
      </div>
    </>
  )
}

export default BankTransfer;
