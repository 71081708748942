import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import SiteLogo from "../../assets/mmpsb-logo.png";
import useAuth from "../../hooks/useAuth";
import CryptoEncrypt from "../../utils/crypto"
import { useAppDispatch } from "../../hooks/useStore";
import { toastFailure, toastSuccess } from "../../utils/toasts";
import { APP_RESPONSES, PIN_TYPES, SECURITY_QUESTIONS, TOOLTIP_ERROR_MESSAGE } from "../../utils/constants";
import "./styles/bvnsignup.scss";
import { encryptData, prependCountryCode, successResponse } from "../../utils/functions";
import { AxiosResponse } from "axios";
import DatePicker from 'react-datepicker';
import { REGISTER_STAGE } from "../../utils/constants";
import { NewUserRecord } from "../../interfaces/auth";
import useOTP from "../../hooks/useOTP";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import StageBC from "../../components/StageBreadCrumb/StageBC";

// const tooltipStyles = { backgroundColor: "#f0ad4e", color: "#000", opacity: 1 };

const tooltipStyles = { backgroundColor: "#2dbe60", color: "#FFF", opacity: 1 };

function BVNSignup() {
  const [formValues, setFormValues] = useState({
    accountNumber: "",
    otp: "",
    password: "",
    confirmPassword: "",
    pin: "",
    transactionPin: "",
    confirmTransactionPin: "",
    dob: "",
    bvn: "",
    bvnPhoneNumber: "",
    question1: "",
    answer1: "",
    question2: "",
    answer2: "",
    email: "",
    confirmEmail: "",
    pinTypeToValidate: "",
    channelPin: "",
    digitalAccessCode: ""
  });
  const [registerStage, setRegisterStage] = useState<string>(REGISTER_STAGE.ACCOUNT_NUMBER_SCREEN);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const { 
    saveUserRecord, 
    doValidateBVNDetails, 
    getSecurityQuestions,
    validateUSSDPin,
    doSendDigitalAccessCode,
    doValidateDigitalAccessCode,
    checkIfRegistered
  } = useAuth();

  const { sendOTP, validateOTPReq } = useOTP();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [defaultSecurityQuestions, setDefaultSecurityQuestions] = useState<string[]>([]);
  const [securityQuestions1, setSecurityQuestions1] = useState<string[]>([]);
  const [securityQuestions2, setSecurityQuestions2] = useState<string[]>([]);
  const [tier, setKycTier] = useState<string>("");
  const [passwordErrors, setPasswordErrors] = useState<string>("");
  const [tooltipMessage, setTooltipMessage] = useState<string>("");
  const [currStageNum, setCurrStageNum] = useState<number>(0);

  // Get the list of security questions
  useEffect(() => {
    getSecurityQuestions().then((response: AxiosResponse) => {
      const array = flattenQuestionsArray([{"0": "--select--"}, ...response.data]);

      setDefaultSecurityQuestions([...array]);
      setSecurityQuestions1([...array]);
      setSecurityQuestions2([...array]);
    }).catch((err: any) => {
      toastFailure(err.response.data.responseMessage);
    })
  },[])

  const flattenQuestionsArray = (questions: {[key: string]: string}[]) => {
    return questions.map((question: { [key: string]: string }) => ((Number(Object.keys(question))) + "||" + Object.values(question)[0]));
  }

  const handleDOBSelect = (date: Date) => {
    setFormValues((prev: any) => { return {
        ...prev,
        dob: new Date(date.getTime() + (24*60*60*1000)).toISOString().slice(0,10)
      }
    })
  }

  const validatePassword = (passwordVal: string) => {
    /**
     *  Password cannot be less than 8
        Must contain an Uppercase
        Must contain a lowercase
        Must contain a special character
     */
        const errors = {
          lessThan8: "",
          uppercase: "",
          lowercase: "",
          special: ""
        }
    
        const { password } = formValues;
    
        errors.lessThan8 = password.length < 8 ? 'must contain at least 8 characters' : '';
        errors.uppercase = !Boolean(password.match(/[A-Z]+/g)) ? 'must contain an upper case character' : '';
        errors.lowercase = !Boolean(password.match(/[a-z]+/g)) ? 'must contain a lower case character' : '';
        errors.special = !Boolean(password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g)) ? 'must contain a special character' : '';
    
        const allErrors = Object.values(errors).filter(error => error !== "");

        return allErrors;
  }

  const handlePasswordValidation = () => {
    // This should return an array with errors or empty array if none
    const allErrors = validatePassword(formValues.password);

    let formattedErrorMsg = "";

    if(allErrors.length){
      formattedErrorMsg = `<section> ${allErrors.length > 0 ? 'Password: <br><span>x</span>&nbsp; ': ''} ${ allErrors.join('<br><span>x</span>&nbsp; ') } </section>`
    }
    
    // Set the password errors value
    setPasswordErrors(formattedErrorMsg)
  }

  const isValidEmail = (email: string) => {
    // Checks if the email address is valid
    return Boolean(email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
  }
  
  // Monitors all form input changes and validates for each user entry
  // This hook focuses on getting information to be displayed on react tooltip 
  useEffect(() => {
    const { accountNumber, otp, digitalAccessCode, question1, answer1, question2, answer2, pin, email } = formValues;

    // This section determines what is shown in the tooltip
    switch (registerStage) {
      case REGISTER_STAGE.ACCOUNT_NUMBER_SCREEN:
        // if account number's length is less than 10, display tooltip
        if(accountNumber && accountNumber.length < 10) {
          setTooltipMessage(TOOLTIP_ERROR_MESSAGE.ACCOUNT_NUMBER)
        }else {
          setTooltipMessage("")
        }
        break;

      case REGISTER_STAGE.OTP_SCREEN:
        // if otp value length is less than 6, display tooltip
        if(otp && otp.length < 6){
          setTooltipMessage(TOOLTIP_ERROR_MESSAGE.OTP)
        }else {
          setTooltipMessage("")
        }
        break;

      case REGISTER_STAGE.DIGITAL_ACCESS_CODE_SCREEN:
        // if Digital Access Code value length is less than 12 digits, display tooltip
        if(digitalAccessCode && digitalAccessCode.length < 12){
          setTooltipMessage(TOOLTIP_ERROR_MESSAGE.DAC)
        }else {
          setTooltipMessage("")
        }
        break;

      case REGISTER_STAGE.PASSWORD_SCREEN:
        handlePasswordValidation()
        break;

      case REGISTER_STAGE.SECURITY_QUESTIONS:
        // If any of the answers is empty, display tooltip
        if((question1 && !answer1) || (question2 && !answer2)){
          setTooltipMessage(TOOLTIP_ERROR_MESSAGE.NO_QUESTION_ANSWER)
        }
        break;


      case REGISTER_STAGE.PIN_SCREEN:
        // if PIN length is less than 4, display tooltip
        if(pin && pin.length < 4){
          setTooltipMessage(TOOLTIP_ERROR_MESSAGE.PIN)
        }else {
          setTooltipMessage("")
        }
        break;

      case REGISTER_STAGE.EMAIL_SCREEN:
        // if not valid email, display tooltip
        if(email && !isValidEmail(email)){
          setTooltipMessage(TOOLTIP_ERROR_MESSAGE.EMAIL)
        }else{
          setTooltipMessage("")
        }
        break;

      default:
        break;
    }
    
  },[
    formValues, 
    registerStage
  ])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // This check ensures that only numbers are entered
    if((name === 'bvnPhoneNumber') 
    || (name === 'phoneNo') 
    || (name === 'bvn')
    || (name === 'transactionPin')
    || (name === 'confirmTransactionPin')
    || (name === 'accountNumber')
    || (name === 'otp')
    || (name === 'digitalAccessCode')
    || (name === 'channelPin')
    ) {

      if(isNaN(Number(value))) return

    }

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetLoading = () => {
    setLoadSpinner(false);
  };

  const handleServerResponseError = (error: any) => {
    toastFailure(error.response.data.responseMessage);
    resetLoading();
  }

  const displayPasswError = (errArray: string[]) => {
    toastFailure(errArray.join(' -> '));
  }

  const showPINScreen = () => {
    // A single space is left in the passwordErrors string, if no errors are found
    // Hence having the length of 1
    // If Errors are present, length would be greater than 1
    if(passwordErrors.length > 1 ) {
      resetLoading();
      return;
    }

    if (formValues.password !== formValues.confirmPassword) {
      toastFailure(APP_RESPONSES.PASSWORD_MISMATCH);
      resetLoading();
      return;
    }

    setRegisterStage(REGISTER_STAGE.PIN_SCREEN);

    resetLoading();
  }

  // Sets the tooltip message for Transaction PIN validation
  const validateTransactionPINLength = (e: FormEvent<HTMLInputElement>) => {
    if(e.currentTarget.value.length < 4){
      setTooltipMessage(TOOLTIP_ERROR_MESSAGE.PIN);
    }else{
      setTooltipMessage("");
    }
  } 

  // Ensures the internet banking PIN is valid
  const validateIBPin = () => {

    if(formValues.transactionPin.length < 4
      || formValues.confirmTransactionPin.length < 4
      ){
      toastFailure(APP_RESPONSES.INVALID_PIN);
      resetLoading();
      return;
    }

    // Check for a mismatch
    if(formValues.transactionPin !== formValues.confirmTransactionPin){
      toastFailure(APP_RESPONSES.PIN_MISMATCH);
      resetLoading();
      return;
    }

    resetLoading();

    setRegisterStage(REGISTER_STAGE.EMAIL_SCREEN);
  }

  const validateBVNDetails = async() => {
    const { bvn, dob, bvnPhoneNumber, accountNumber } = formValues;

    if(bvn === "" || dob === "" || bvnPhoneNumber === ""){
      toastFailure(APP_RESPONSES.INCORRECT_DETAILS);
      resetLoading();
      return;
    }

    try {
      const response = await doValidateBVNDetails({bvn, dob, bvnPhoneNumber, accountNumber});

      const { responsecode, responseCode} = response.data;
      
      resetLoading();

      if(!successResponse([responsecode, responseCode])){
        toastFailure(response.data.responseMessage);
        return;
      }

      sendOTP(formValues.accountNumber);

      setRegisterStage(REGISTER_STAGE.OTP_SCREEN);  
    } catch (error) {
      handleServerResponseError(error)
    }
    
  }

  const validateOTP = async() => {
    if(formValues.otp === ""){
      toastFailure(APP_RESPONSES.INVALID_OTP_CODE);
      resetLoading();
      return;
    }

    try {
      // Check tier to determine how to get the user's phone number
      // const accountMatched = tier === "3" ? formValues.accountNumber : formValues.bvnPhoneNumber;

      const response = await validateOTPReq(formValues.otp.toString(), formValues.accountNumber)
      
      const { responsecode, responseCode} = response.data;

      resetLoading();

      if(!successResponse([responsecode, responseCode])){
        toastFailure(response.data.responseMessage);
        return;
      }

      // sendDigitalAccessCode();

      setRegisterStage(REGISTER_STAGE.SECURITY_QUESTIONS);

      setCurrStageNum(currStageNum + 1);
    } catch (error: any) {
      toastFailure(error.response.data.responseMessage);
      resetLoading();
    }
    
  }

  const getUserPhoneNo = () => {
    return prependCountryCode(tier === "1" ? formValues.accountNumber : formValues.bvnPhoneNumber);
  }

  const sendDigitalAccessCode = () => {
    doSendDigitalAccessCode({accountNumber: formValues.accountNumber}).then(res => {
      // console.log('Digital access code response: ', res.data);
    });
  }

  const validateDigitalAccessCode = async () => {
    return doValidateDigitalAccessCode({ 
      accountNumber: formValues.accountNumber, 
      input: formValues.channelPin, 
      clientType: PIN_TYPES.DAC
    });
  }

  const validateSecurityQuestions = () => {
    toast.dismiss();
    
    const {question1, answer1, question2, answer2} = formValues;

    // Check if answers to the questions have been submitted
    if(!(question1 && answer1.length && question2 && answer2.length)){
      toastFailure('Please complete the security questions before submitting');
      resetLoading();
      return;
    }
    
    resetLoading();

    setRegisterStage(REGISTER_STAGE.PASSWORD_SCREEN);

    // This call here is to initialize the required constraints for a user's password
    handlePasswordValidation();
  }

  const validateEmailAndActivate = () => {
    
    // Checks if the email address is valid
    if(!formValues.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
      toastFailure(APP_RESPONSES.INVALID_EMAIL);
      resetLoading();
      return;
    }

    // Check if both email address does not match
    if(formValues.email !== formValues.confirmEmail){
      toastFailure(APP_RESPONSES.EMAIL_MISMATCH);
      resetLoading();
      return;
    }

    activateAccount();
  }

  const hideSpinner = () => setLoadSpinner(false);

  const handleUserTier = (userTier: string) => {
    
    setRegisterStage(REGISTER_STAGE.OTP_SCREEN); 

    // switch(userTier){
    //   case "1":
    //     setRegisterStage(REGISTER_STAGE.PIN_VALIDATION_SCREEN);
    //     break;
    //   case "3":
    //   case "2":
    //     setRegisterStage(REGISTER_STAGE.OTP_SCREEN);
    //     break;
    //   default:
    //     break;
    // }
  }

  const checkAccountValidity = async () => {
    if (
      isNaN(Number(formValues.accountNumber)) ||
      formValues.accountNumber.length !== 10
    ) {
      toastFailure(APP_RESPONSES.INVALID_ACCOUNT_NUMBER);

      resetLoading();
      return;
    }

    displaySpinner();

    // Check if registered
    try{
      const response = await checkIfRegistered(formValues.accountNumber);
      const { responseCode, responsecode, tier } = response.data;
      
      // console.log("If registered user:", response.data);

      if(!successResponse([responsecode, responseCode])){
        toastFailure(response.data.responseMessage);
        resetLoading();
        return
      }
       
      setRegisterStage(REGISTER_STAGE.PIN_VALIDATION_SCREEN);

      setKycTier(() => {
        return tier;
      });

      hideSpinner();
    }catch(err: any){
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);
      resetLoading();
      return;
    }
    
  }

  const handlePinToValidate = (e: ChangeEvent<HTMLSelectElement>) => {
    setFormValues({
      ...formValues,
      pinTypeToValidate: e.target.value
    })
  }

  const validateExistingPin = async () => {
    toast.dismiss();
    
    displaySpinner();

    if(formValues.pinTypeToValidate === ""){
      toastFailure(APP_RESPONSES.PIN_TYPE_NOT_SELECTED);
      hideSpinner();
      return
    }

    try {
      let { pinTypeToValidate } = formValues;

      const reqPayload = {
        pin: encryptData(formValues.channelPin), 
        accountNumber: "0" + formValues.accountNumber, 
        clientType: PIN_TYPES.USSD.toLowerCase() === pinTypeToValidate.toLowerCase() ? PIN_TYPES.USSD : PIN_TYPES.DAC
      }

      const response = PIN_TYPES.USSD === pinTypeToValidate 
        ? await validateUSSDPin(reqPayload)
        : await validateDigitalAccessCode();

      const {responsecode, responseCode} = response?.data;

      if(!successResponse([responsecode, responseCode])){
        toastFailure(response?.data.responseMessage);
        hideSpinner();
        return;
      }

      sendOTP(formValues.accountNumber);

      setRegisterStage(REGISTER_STAGE.OTP_SCREEN);
      hideSpinner();
    } catch (error: any) {
      toastFailure(error.response?.data?.responseMessage);
      hideSpinner();
    }
    
  }

  const handleSubmit = async () => {
    displaySpinner();

    setTimeout(async() => {
      switch (registerStage) {
        case REGISTER_STAGE.ACCOUNT_NUMBER_SCREEN:
          await checkAccountValidity();
          break;
        case REGISTER_STAGE.PIN_VALIDATION_SCREEN:
          await validateExistingPin();
          break;
        case REGISTER_STAGE.OTP_SCREEN:
          validateOTP();
          break;
        // case REGISTER_STAGE.DIGITAL_ACCESS_CODE_SCREEN:
        //   validateDigitalAccessCode();
        //   break;
        case REGISTER_STAGE.SECURITY_QUESTIONS:
          validateSecurityQuestions();
          break;
        case REGISTER_STAGE.PASSWORD_SCREEN:
          showPINScreen();
          break;
        case REGISTER_STAGE.PIN_SCREEN:
          validateIBPin();
          break;
        case REGISTER_STAGE.EMAIL_SCREEN:
          validateEmailAndActivate();
          break;
        default:
          break;
      }
    }, 2000);
    
  };

  const removeSelectedOption = (ev: ChangeEvent<HTMLSelectElement>, questionNumber: number) => {
    if(questionNumber === SECURITY_QUESTIONS.ONE){
      const newSecurityQuestions2 = [...defaultSecurityQuestions];
      
      const selectedOptionIndex = newSecurityQuestions2.reduce((prev: number, question: string) => {
        const actualQuestion = question.split("||")[1];
        const questionIndex = Number(question.split("||")[0]);

        return  (ev.target.selectedOptions[0].innerText === actualQuestion ? questionIndex : prev)
      }, 0);
      
      newSecurityQuestions2.splice(selectedOptionIndex, 1);

      setSecurityQuestions2(newSecurityQuestions2);

    }else if(questionNumber === SECURITY_QUESTIONS.TWO){
      const newSecurityQuestions1 = [...defaultSecurityQuestions];

      const selectedOptionIndex = newSecurityQuestions1.findIndex((question: string) => (ev.target.selectedOptions[0].innerText === question.split("||")[1]));

      newSecurityQuestions1.splice(selectedOptionIndex, 1);
      
      setSecurityQuestions1(newSecurityQuestions1);
    }
  }

  const handleQuestionChange = (e: ChangeEvent<HTMLSelectElement>, questionNumber: number) => {
    const {question1, question2} = formValues;
    const {value} = e.target;

    if(value === "0") return;

    // On change remove the question from security questions array
    removeSelectedOption(e, questionNumber);

    setFormValues((prev: any) => {
      return {
        ...prev,
        [`question${questionNumber}`]: Number(value)
      }
    })
    
  }

  const activateAccount = async () => {
    const {password, transactionPin, accountNumber, question1, answer1, question2, answer2, bvn, email} = formValues;

    const requestPayload: NewUserRecord = {
      "password": CryptoEncrypt.encryptValue(password),
      "pin": CryptoEncrypt.encryptValue(transactionPin),
      "userId": accountNumber,
      "clientType": "SELFCARE",
      "question1": question1,
      "answer1": answer1,
      "question2": question2,
      "answer2": answer2,
      "bvn": bvn,
      "email": email
    }

    try {
      const response = await saveUserRecord(requestPayload);

      const { responseCode, responsecode } = response.data;

      if(successResponse([responseCode, responsecode])){
        toastSuccess(`Welcome, Your account has been registered on internet banking. Kindly login into your dashboard.`);

        resetLoading();
        
        navigate("/login");
      }else{
        resetLoading();
        toastFailure(response.data.responseMessage);
      }
    } catch (error: any) {
      resetLoading();
      toastFailure(error.response.data.responseMessage);
    }
  }

  const displaySpinner = () => {
    if (!loadSpinner) setLoadSpinner(true);
  };

  // This hooks monitors the registration stage and updates necessary dependencies
  useEffect(() => {
    setCurrStageNum(currStageNum + 1);
  },[registerStage]);

  // This is the number of stages in the registration process
  const getRegStageLength = () => (Object.keys(REGISTER_STAGE).length - 1);

  return (
    <>
    <div id="main-wrapper">
      <div id="content" className="container-padding-x">
 
          <div className="container-fluid px-0">
            <div className="row g-0 min-vh-100">
              <div className="col-md-6 img-div">
                <div className="hero-wrap d-flex align-items-center h-100">
                  <div
                    className="hero-bg hero-bg-scroll"
                    style={{
                      backgroundImage: "url('/images/bg/image-9.jpg')",
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="container my-4">
                  <div className="row g-0">
                    <div className="col-10 col-lg-9 col-xl-8 mx-auto container-width">
                      <div className="w-100 text-center">
                        <Link to="/" className="site-logo">
                          <img src={SiteLogo} className="w-50 my-4" />
                        </Link>
                      </div>
                      <h3 className="fw-400 mb-4">Sign Up</h3>
                      
                      {/* This stage breadcrumb shows number of steps completed and left */}
                      {/* <StageBC stageCount={getRegStageLength()} currentStage={currStageNum} className="mb-4" /> */}

                      <form id="registerForm" onSubmit={ e => {e.preventDefault()} }>
                        {
                          registerStage === REGISTER_STAGE.ACCOUNT_NUMBER_SCREEN &&
                          <>
                            <div className="mb-3">
                              <label htmlFor="account-number" className="form-label text-lh">
                                <p style={{ marginBottom: "0rem" }}>Enter your 10-digit account number.</p>
                              </label>
                            
                              <input
                                type="text"
                                maxLength={10}
                                className="form-control"
                                id="account-number"
                                name="accountNumber"
                                required
                                placeholder="Enter your Account Number"
                                value={formValues.accountNumber}
                                onChange={(e) => handleChange(e)}
                                data-tooltip-id="account-number-tooltip" 
                                data-tooltip-content={tooltipMessage}
                              />
                            </div>
      
                            <ReactTooltip 
                              id="account-number-tooltip" 
                              isOpen={tooltipMessage.length > 0}
                              style={tooltipStyles}
                              />
                          </>
                        }

                        {
                          registerStage === REGISTER_STAGE.PIN_VALIDATION_SCREEN &&
                            <>
                              <div className="mb-3">
                                <label htmlFor="pinvalidation" className="form-label">
                                  Access Validation
                                </label>
                                <select onChange={(e) => handlePinToValidate(e)} name="pinvalidation" id="pinvalidation" data-style="form-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control bg-white form-select" required={true}>
                                  <option value="">--Select Validate Type--</option>
                                  <option value={PIN_TYPES.USSD}>with USSD PIN</option> 
                                  <option value={PIN_TYPES.DAC}>with Digital Access Code</option>
                                </select>
                              </div>

                              <div className="mb-3">
                                {/* <label htmlFor="channelPin" className="form-label text-lh ">
                                  <p style={{ marginBottom: "0rem" }}>Enter PIN</p>
                                </label> */}
                                <input
                                  type="password"
                                  className="form-control"
                                  id="channelPin"
                                  name="channelPin"
                                  required
                                  placeholder={
                                    formValues.pinTypeToValidate === PIN_TYPES.USSD 
                                    ? `Enter USSD PIN`
                                    : formValues.pinTypeToValidate === PIN_TYPES.DAC
                                      ? `Enter Digital Access Code`
                                      : ``
                                  }
                                  value={formValues.channelPin}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>

                            </>
                        }

                        {registerStage === REGISTER_STAGE.OTP_SCREEN && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="otp" className="form-label">
                                Enter OTP
                              </label>
                              <input
                                type="text"
                                maxLength={6}
                                className="form-control"
                                id="otp"
                                name="otp"
                                required
                                placeholder="OTP"
                                value={formValues.otp}
                                onChange={handleChange}
                                data-tooltip-id="otp-tooltip" 
                                data-tooltip-content={tooltipMessage}
                              />
                            </div>

                            {/* <ReactTooltip id="otp-tooltip" isOpen={tooltipMessage.length > 0} style={tooltipStyles} /> */}
                            {/* <p>
                              Dial <span className="text-success">*995#</span> to get your OTP.
                            </p> */}
                          </>
                        )}
                        {registerStage === REGISTER_STAGE.DIGITAL_ACCESS_CODE_SCREEN && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="digitalaccesscode" className="form-label">
                                Enter Digital Access Code
                              </label>
                              <p className="text-muted">
                              Check your email OR Dial <span className="fw-500 text-primary">*995#</span> via USSD to retrieve your Digital Access Code.
                              </p>
                              <input
                                type="password"
                                className="form-control"
                                id="digitalAccessCode"
                                name="digitalAccessCode"
                                required
                                placeholder=""
                                maxLength={12}
                                value={formValues.digitalAccessCode}
                                onChange={handleChange}
                                data-tooltip-id="dac-tooltip" 
                                data-tooltip-content={tooltipMessage}
                              />
                            </div>

                            {/* <ReactTooltip id="dac-tooltip" isOpen={tooltipMessage.length > 0} style={tooltipStyles} /> */}
                            
                          </>
                        )}
                        {registerStage === REGISTER_STAGE.SECURITY_QUESTIONS && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="question1" className="form-label">
                                Choose Security Question
                              </label>
                              <select onChange={(e) => handleQuestionChange(e, 1)} value={formValues.question1} name="question1" id="question1" data-style="form-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control bg-white form-select" required={true}>
                                
                                {
                                    securityQuestions1.length > 0 && securityQuestions1.map((question: any, index: number) => {
                                        return <option value={question.split('||')[0]} key={index}>{ question.split('||')[1] }</option>
                                    })
                                }
                              </select>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="answer1" className="form-label">
                                Answer to Secret Question
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="answer1"
                                name="answer1"
                                required
                                placeholder=""
                                onChange={handleChange}
                                data-tooltip-id="answer1-tooltip" 
                                data-tooltip-content={tooltipMessage}
                              />
                            </div>
                            
                            {/* <ReactTooltip id="answer1-tooltip" isOpen={Boolean(formValues.question1) && (formValues.answer1.length === 0)} /> */}

                            <hr style={{ border: '2px solid #006010', opacity: 1 }} />

                            <div className="mb-3">
                            <label htmlFor="question2" className="form-label">
                              Choose Security Question
                            </label>
                            <select onChange={(e) => handleQuestionChange(e, 2)} value={formValues.question2} name="question2" id="question2" data-style="form-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control bg-white form-select" required={true}>
                              
                              {
                                  securityQuestions2.length > 0 && securityQuestions2.map((question: any, index: number) => {
                                    return <option value={question.split('||')[0]} key={index}>{ question.split('||')[1] }</option>
                                  })
                              }
                            </select>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="answer2" className="form-label">
                                Answer to Secret Question
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="answer2"
                                name="answer2"
                                required
                                placeholder=""
                                onChange={handleChange}
                                data-tooltip-id="answer2-tooltip"
                                data-tooltip-content={tooltipMessage}
                              />
                            </div>

                            {/* <ReactTooltip id="answer2-tooltip" isOpen={Boolean(formValues.question2) && (formValues.answer2.length === 0)} /> */}
                            </>
                        )}
                        {registerStage === REGISTER_STAGE.PASSWORD_SCREEN && (
                          <>
                            {/* This conditions show up when the user password has not met the required criteria */}
                            <p className={`password-rules ${passwordErrors.length ? '':'d-none'}`} dangerouslySetInnerHTML={{__html: passwordErrors}}>
                              
                            </p>

                            <div className="mb-3">
                              <label htmlFor="password" className="form-label">
                                Create new Internet Banking Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                required
                                placeholder="Password"
                                onChange={handleChange}
                                data-tooltip-id="password-tooltip" 
                                data-tooltip-html={passwordErrors}
                              />
                            </div>

                            {/* <ReactTooltip id="password-tooltip" isOpen={passwordErrors.length > 1} style={tooltipStyles} /> */}

                            <div className="mb-3">
                              <label htmlFor="confirmPassword" className="form-label">
                               Confirm Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                name="confirmPassword"
                                required
                                placeholder="Confirm Password"
                                onChange={handleChange}
                              />
                            </div>
                          </>
                        )}

                        {registerStage === REGISTER_STAGE.PIN_SCREEN && (
                          <>
                            <div className="mb-3" data-tooltip-id="pin-tooltip" data-tooltip-content={tooltipMessage}>
                              <label htmlFor="transactionPin" className="form-label">
                                Create your new Internet Banking PIN
                              </label>
                              <input
                                type="password"
                                maxLength={4}
                                className="form-control"
                                id="transactionPin"
                                name="transactionPin"
                                required
                                placeholder="Transaction PIN"
                                value={formValues.transactionPin}
                                onChange={handleChange}
                                onKeyUp={validateTransactionPINLength}
                              />
                            </div>

                            <ReactTooltip id="pin-tooltip" isOpen={tooltipMessage.length > 1} style={tooltipStyles} />

                            <div className="mb-3">
                              <label htmlFor="confirmTransactionPin" className="form-label">
                                Confirm Internet Banking PIN
                              </label>
                              <input
                                type="password"
                                maxLength={4}
                                className="form-control"
                                id="confirmTransactionPin"
                                name="confirmTransactionPin"
                                required
                                placeholder="Confirm Transaction PIN"
                                value={formValues.confirmTransactionPin}
                                onChange={handleChange}
                              />
                            </div>
                          </>
                        )}

                        {registerStage === REGISTER_STAGE.EMAIL_SCREEN && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="email" className="form-label">
                                Enter your email address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder=""
                                value={formValues.email}
                                onChange={handleChange}
                                data-tooltip-id="email-tooltip" 
                                data-tooltip-html={tooltipMessage}
                              />
                            </div>

                            <ReactTooltip id="email-tooltip" isOpen={tooltipMessage.length > 1} style={tooltipStyles} />

                            <div className="mb-3">
                              <label htmlFor="email" className="form-label">
                                Confirm email address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="confirmEmail"
                                name="confirmEmail"
                                placeholder=""
                                value={formValues.confirmEmail}
                                onChange={handleChange}
                              />
                            </div>
                          </>
                        )}
                        
                        {/* This button is displayed for every form page */}
                        <div className="d-grid mt-4 mb-3">
                          <button
                            className="btn btn-primary d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={handleSubmit}
                          >
                            {loadSpinner ? (
                              <span className="d-flex justify-content-center w-auto">
                                <Spinner />
                              </span>
                            ) : (
                              registerStage !== REGISTER_STAGE.EMAIL_SCREEN 
                              ? <span className="d-flex"><i className="fas fa-arrow-alt-circle-right mb-015 me-2 align-self-center"></i> Continue</span> 
                              : <span className="d-flex"><i className="fas fa-upload mb-015 me-2 align-self-center"></i> Submit</span>
                            )}
                          </button>
                        </div>

                        <p className="text-3 text-center text-muted">Already Registered? <Link to="/login" className="btn-link">Log In</Link></p>
                        
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BVNSignup;