import { createSlice } from "@reduxjs/toolkit";
import Crypto from "../../utils/crypto";
import { decryptReduxValue } from "../../utils/functions";
import { NIN_STATUS } from "../../utils/constants";
const initialState = {
  user: "",
  tokens: "",
  otpCode: "",
  sessionTimeout: false,
  isLoggedIn: false,
  authAccountNumber: "",
  autoLogout: false,
  ninRegistered: NIN_STATUS.UNREGISTERED,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAccessToken: (state, payload) => {
      return {
        ...state,
        tokens: Crypto.encryptValue(
          JSON.stringify({ accessToken: payload.payload })
        ),
      };
    },
    setAuthAccountNumber: (state, payload) => {
      return {
        ...state,
        authAccountNumber: Crypto.encryptValue(String(payload.payload)),
      };
    },
    signIn: (state, payload) => {
      return {
        ...state,
        user: Crypto.encryptValue(JSON.stringify(payload.payload)),
        isLoggedIn: true,
      };
    },
    logout: (state, payload) => {
      state = { ...initialState };
      return state;
    },
    updateStoreBalance: (state, payload) => {
      // decrypt, save new encrypted data
      let tempData = decryptReduxValue(state.user);
      tempData.walletbalance = payload.payload;

      return {
        ...state,
        user: Crypto.encryptValue(tempData),
      };
    },
    authReset: (state, payload) => ({ ...initialState }),
    autoLogout: (state, payload) => {
      return { ...initialState, ...payload.payload };
    },
    setNinRegistered: (state, payload) => {
      return {
        ...state,
        ninRegistered: Crypto.encryptValue(payload.payload),
      };
    },
  },
});

export const authReducer = authSlice.reducer;

export const {
  updateAccessToken,
  setAuthAccountNumber,
  signIn,
  logout,
  authReset,
  autoLogout,
  setNinRegistered,
} = authSlice.actions;
