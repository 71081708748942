import {
  ChangeEvent,
  FormEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./styles/update-nin.scss";
import {
  APP_RESPONSES,
  OPERATION_HEADERS,
  RESET_PIN_STAGE,
  SELF_SERVICES,
  UPDATE_NIN_STAGE,
} from "../../../../utils/constants";
import OperationHeader from "../../components/OperationHeader/OperationHeader";
import DatePicker from "react-datepicker";
import Spinner from "../../../../components/Spinner/Spinner";
import { toast } from "react-toastify";
import { toastFailure, toastSuccess } from "../../../../utils/toasts";
import { useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import {
  VALIDATE_RESET_BY_BVN,
  VALIDATE_RESET_BY_SA,
} from "../../../../api/urlConfig";
import useDataInfo from "../../../../hooks/useDataInfo";
import {
  decryptReduxValue,
  encryptData,
  successResponse,
} from "../../../../utils/functions";
import UpdateNinServerResponse from "../../components/TransactionResponse/TransactionResponse";

const UpdateNin = () => {
  const { auth: authRedux } = useAppSelector((state: RootState) => state);
  const [formValues, setFormValues] = useState({
    pin: "",
    nin: "",
    ninUpdateMessage: "",
    ninUpdateSuccessful: false,
  });
  const [updateNinStage, setUpdateNinStage] = useState(
    UPDATE_NIN_STAGE.INPUT_NIN
  );
  const [loadSpinner, setLoadSpinner] = useState(false);
  const { doUpdateNin } = useDataInfo();

  const validatePinInput = () => {
    if (isNaN(parseInt(formValues.pin))) {
      return false;
    }

    return true;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const displaySpinner = () => setLoadSpinner(true);

  const hideSpinner = () => setLoadSpinner(false);

  const validateNIN = () => {
    return !isNaN(parseInt(formValues.nin)) && formValues.nin.length === 11;
  };

  const showAllErrors = (errStatuses: Record<string, boolean>) => {
    let errString = "";
    const { isValidNIN, isValidPIN } = errStatuses;

    if (!isValidNIN) errString += APP_RESPONSES.INVALID_NIN + " -> ";

    if (!isValidPIN) errString += APP_RESPONSES.INVALID_BLANK_PIN;

    toastFailure(errString);
  };

  const handleUpdateNIN = async () => {
    toast.dismiss();

    displaySpinner();

    const isValidNIN = validateNIN();
    const isValidPIN = validatePinInput();

    if (!isValidNIN || !isValidPIN) {
      showAllErrors({ isValidNIN, isValidPIN });
      hideSpinner();
      return;
    }

    try {
      const response = await doUpdateNin({
        msisdn: String(
          decryptReduxValue(authRedux.user).wallet_accts.agentcode
        ),
        nin: formValues.nin,
        pin: encryptData(formValues.pin),
        clientType: "SELFCARE",
      });

      // Display Final Response screen
      const { responsecode, responseCode, responseMessage } = response.data;

      const operationStatus = successResponse([responsecode, responseCode]);

      hideSpinner();

      setFormValues({
        ...formValues,
        ninUpdateMessage: responseMessage,
        ninUpdateSuccessful: operationStatus,
      });

      setUpdateNinStage(UPDATE_NIN_STAGE.SERVER_RESPONSE);

      if (true === operationStatus) {
        toastSuccess(responseMessage);
      } else {
        toastFailure(responseMessage);
      }
    } catch (error: any) {
      console.log("Nin update error: ", error);
      toastFailure(error.response?.data?.responseMessage);
      hideSpinner();
    }
  };

  return (
    <div className="row w-100 mt-3 justify-content-center align-items-center flex-column mb-3 container-padding">
      <div className="col-md-9 col-lg-7 col-xl-7 mx-auto container-padding">
        <form
          id="form-update-nin"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {updateNinStage === UPDATE_NIN_STAGE.INPUT_NIN && (
            <OperationHeader title={OPERATION_HEADERS.UPDATE_NIN} />
          )}

          {updateNinStage === UPDATE_NIN_STAGE.INPUT_NIN && (
            <>
              <div className="mb-3">
                <label htmlFor="" className="form-label">
                  Enter your National Identification Number (NIN)
                </label>
                <input
                  type="text"
                  placeholder="Enter NIN"
                  className="form-control"
                  aria-label=""
                  maxLength={11}
                  name={"nin"}
                  required
                  value={formValues.nin}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="" className="form-label">
                  Enter PIN
                </label>
                <input
                  type="password"
                  placeholder="Enter PIN"
                  className="form-control"
                  aria-label=""
                  name={"pin"}
                  required
                  value={formValues.pin}
                  onChange={handleChange}
                />
              </div>

              <div className="d-grid">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleUpdateNIN}
                >
                  {
                    <span className="d-flex justify-content-center w-auto">
                      {loadSpinner ? <Spinner /> : "Submit"}
                    </span>
                  }
                </button>
              </div>
            </>
          )}

          {updateNinStage === UPDATE_NIN_STAGE.SERVER_RESPONSE && (
            <UpdateNinServerResponse
              operationType={SELF_SERVICES.UPDATE_NIN}
              walletbalance={""}
              success={formValues.ninUpdateSuccessful}
              serverResponse={formValues.ninUpdateMessage}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default UpdateNin;
