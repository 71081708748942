import React from "react";
import { classNames } from "./../../../../utils/functions";
import "./styles/button.scss";

export function Button({ children, className, ...rest }: any) {
  return (
    <button
      type="button"
      className={`mod-button ${classNames(className)}`}
      {...rest}
    >
      {children}
    </button>
  );
}

export function PageButton({ children, className, ...rest }: any) {
  return (
    <button
      type="button"
      className={`mod-page-button ${classNames(
        className
      )}`}
      {...rest}
    >
      {children}
    </button>
  );
}