import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios"
import { APP_CONSTANTS } from "../utils/constants";

function useTimeout(){
    const timeoutFunc = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
        const cancelToken = axios.CancelToken.source();

        const timeoutId = setTimeout(
          () => {
            // console.log('Cancelling request')
            return cancelToken.cancel("SORRY, PROCESS TIMEOUT");
          },
          APP_CONSTANTS.REQUEST_TIMEOUT
        )
      
        return {
          ...config,
          timeoutId,
          cancelToken: cancelToken.token,
        } as InternalAxiosRequestConfig
    }
      
    const handleTimeout = (error: AxiosError): Promise<any> =>
        Promise.reject(
          error.message === "SORRY, PROCESS TIMEOUT" ? { ...error, code: "ETIMEDOUT" } : error,
        )
      
    const useClearTimeout = (response: any): AxiosResponse => {
        clearTimeout(response?.config?.timeoutId)
        return response
    }

    return {
        timeoutFunc,
        handleTimeout,
        useClearTimeout
    }
}

export default useTimeout;