import {
  useState,
  ChangeEvent,
  useEffect,
  FormEvent,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import useTransactions from "../../../../hooks/useTransactions";
import { toastFailure, toastSuccess } from "../../../../utils/toasts";
import CryptoJS from "../../../../utils/crypto";
import "./styles/wallet.scss";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import Spinner from "../../../../components/Spinner/Spinner";
import {
  APP_ACCT_TYPES,
  APP_RESPONSES,
  NAIRA_SYMBOL,
  OPERATION_HEADERS,
  RESPONSE_CODES,
  SERVICE_TYPES,
  SYMBOL_TYPES,
  TRANSACTION_ENDPOINTS,
  TRANSACTION_TYPES,
  WALLET_ACCOUNT,
  WALLET_TRANSFER_STAGE,
} from "../../../../utils/constants";
import Confirmation from "../../components/ConfirmationPage/ConfirmationPage";

import TransactionResponse from "../../components/TransactionResponse/TransactionResponse";
import OperationHeader from "../../components/OperationHeader/OperationHeader";
import useAuth from "../../../../hooks/useAuth";
import {
  SAVE_ACCOUNT_DETAILS,
  SET_TRANSACTION_ENDPOINT,
  STORE_OTP_CODE,
} from "../../../../redux/actions";
import { AxiosResponse } from "axios";
import useOTP from "../../../../hooks/useOTP";
import {
  decimalPointEnd,
  decimalPointFound,
  decimalPointMiddle,
  decryptReduxValue,
  formatAmount,
  generateRandomNumber,
  hasNoValue,
  removeCommas,
  successResponse,
} from "../../../../utils/functions";
import useDataInfo from "../../../../hooks/useDataInfo";
import Symbols from "../../components/Symbols/Symbols";
import { toast } from "react-toastify";
import RenderAllUserAccountOptions from "../../components/RenderAllUserAccountOptions/RenderAccountOptions";
import { setTranEndpoint } from "../../../../redux/features/transactionSlice";
import Continue from "../../../../components/ButtonsLabel/Continue";
import Crypto from "../../../../utils/crypto";

function Wallet() {
  const [formValues, setFormValues] = useState({
    agentcode: "",
    destination: "",
    amount: "",
    pin: "",
    otp: "",
    sourceAccount: "",
    sourceAccountLabel: "",
  });
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [walletTransferActive, setWalletTransferActive] = useState(false);
  const [transferStage, setTransferStage] = useState(0);
  const { transferToGKalaAccount } = useTransactions();
  const authRedux: any = useAppSelector((state: RootState) => state.auth);
  const { getBeneficiaryProfile } = useAuth();
  const { sendOTP, validateOTPReq } = useOTP();
  const { updateBalanceInStore, getSourceAccount } = useDataInfo();

  // Transaction Id is added to this state on successful operation
  const [transactionResponse, setTransactionResponse] = useState({
    walletbalance: "",
    resultdescription: "",
    responseMessage: "",
    responsecode: "",
    responseCode: "",
    success: false,
  });
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const dispatch = useAppDispatch();
  const { transaction: transactionRedux, userAccounts: userAccountsRedux } =
    useAppSelector((state: RootState) => state);
  const sourceAccountRef = useRef<HTMLSelectElement>(null);
  const [canTransferMoney, setCanTransferMoney] = useState(false);

  // Set the phone number of the user, using the agent code, in redux store.
  // Sets the default source account to Wallet account.
  useEffect(() => {
    setFormValues({
      ...formValues,
      agentcode: decryptReduxValue(authRedux.user).wallet_accts.agentcode,
      sourceAccount: sourceAccountRef.current?.options[0].value!,
      sourceAccountLabel: sourceAccountRef.current?.options[0].innerHTML!,
    });
  }, []);

  const handleKoboInput = (userKoboInput: string) => {
    // Check if the values after decimal point is of length 2
    if (userKoboInput.length <= 2) {
      setFormValues({
        ...formValues,
        amount: formValues.amount.replace(/\.[0-9]*$/, "." + userKoboInput),
      });
    }
  };

  const onChangeAmountValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (loadSpinner) return;

    const userInput = event.target.value;

    // Check if userInput has a dot value
    if (decimalPointFound(userInput) && decimalPointMiddle(userInput)) {
      if (isNaN(Number(userInput.split(".")[1]))) return;

      handleKoboInput(userInput.split(".")[1]);
      return;
    } else if (decimalPointFound(userInput) && decimalPointEnd(userInput)) {
      setFormValues({
        ...formValues,
        amount: userInput,
      });
      return;
    }

    const formattedAmount = formatAmount(userInput);

    if (Number.isNaN(formattedAmount)) return;

    setFormValues({
      ...formValues,
      amount: formattedAmount as string,
    });

    // Validate source account funds to be greater than amount
    // const canTransferAmount = validateCanTransferAmount(formattedAmount as number);

    // toast.dismiss();

    // if(!canTransferAmount && (formattedAmount !== "")){
    //   toastFailure(APP_RESPONSES.INSUFFICIENT_FUNDS);
    // }

    // // If canTransferAmount, enable continue button
    setCanTransferMoney(true);
  };

  const validateCanTransferAmount = (amount: number) => {
    if (String(amount) === "") return false;

    // This extracts the  balance of the selected source account
    const selAccBalance = decryptReduxValue(userAccountsRedux.accounts)[
      formValues.sourceAccount
    ].split("|")[2];

    // Get and compare the main value of userInput [amount] and selected account [selAccBalance]
    const inputMainVal = removeCommas(amount as unknown as string);

    const selAccMainVal = removeCommas(selAccBalance);

    if (Number(inputMainVal) <= Number(selAccMainVal)) {
      return true;
    } else if (Number(inputMainVal) > Number(selAccMainVal)) {
      return false;
    }
  };

  const onChangePIN = (event: any) => {
    let newValue = event.target.value;

    if (newValue === "") {
      toast.dismiss();
      toastFailure(APP_RESPONSES.INCORRECT_PIN);
    }

    setFormValues({
      ...formValues,
      pin: newValue,
    });
  };

  const handleChange = (event: ChangeEvent<any>) => {
    if (loadSpinner) return;
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const validateForm = () => {
    const errors = {
      destination: "",
      amount: "",
      sourceAccount: "",
    };

    errors.sourceAccount =
      formValues.sourceAccount === "" ? "Source Account not selected" : "";

    errors.destination =
      formValues.destination.length < 10 ||
      Number.isNaN(Number(formValues.destination))
        ? "Invalid beneficiary number"
        : "";

    errors.amount =
      Number.isNaN(formValues.amount) || Number(formValues.amount) <= 0
        ? "Invalid amount"
        : "";

    const errorResponse = Object.values(errors).filter((error) => error !== "");

    return errorResponse;
  };

  const displayErrors = (formErrors: string[]) => {
    toast.dismiss();
    toastFailure(
      ["Please fill in the form below correctly.", ...formErrors].join(" -> ")
    );
    setLoadSpinner(false);
  };

  const sendMoney = async (requestPayload: any) => {
    try {
      const response = await transferToGKalaAccount(requestPayload);

      handleTransferResponse(response);
      hideSpinner();
    } catch (err: any) {
      toast.dismiss();

      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);

      displayTransactionResponse(err.data);

      hideSpinner();
    }
  };

  const handleTransferResponse = (response: any) => {
    toast.dismiss();

    const { responseCode, responsecode } = response.data;

    if (successResponse([responseCode, responsecode])) {
      toastSuccess(response.data.responseMessage);

      updateBalanceInStore(response.data.walletbalance);
    } else {
      toastFailure(
        response.data.responseMessage || response.data.resultdescription
      );
    }

    displayTransactionResponse({
      ...response.data,
      success: successResponse([responseCode, responsecode]),
    });
  };

  const displayTransactionResponse = (response: any) => {
    setTransactionResponse((prev) => ({
      ...prev,
      ...response,
    }));

    setTransferStage(WALLET_TRANSFER_STAGE.TRANSACTION_RESPONSE);
  };

  const validateOTP = async () => {
    toast.dismiss();

    if (hasNoValue(formValues.otp)) {
      toastFailure(APP_RESPONSES.INVALID_OTP_CODE);
      return false;
    }

    try {
      // Validate the otp through server request validation
      const response = await validateOTPReq(formValues.otp);

      const { responseCode, responsecode } = response.data;

      if (!successResponse([responseCode, responsecode])) {
        toastFailure(response.data.responseMessage);
        return false;
      }

      return true;
    } catch (error: any) {
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);
      return false;
    }
  };

  const disableTransferButton = () => {
    document
      .querySelector(".complete-transfer")
      ?.setAttribute("disabled", "disabled");
  };

  const handleWalletTransfer: any = async (e: FormEvent) => {
    e.preventDefault();

    if (walletTransferActive) return;

    displaySpinner();

    disableTransferButton();

    const randomNumber = generateRandomNumber();

    const requestPayload = {
      clientTrxId: randomNumber,
      sourceAccount: formValues.sourceAccount,
      amount: formValues.amount.replace(/,/g, ""),
      destinationAccount: formValues.destination,
      pin: CryptoJS.encryptValue(formValues.pin),
      clientType: "SELFCARE",
      otp: formValues.otp,
      paymentRef: randomNumber,
    };

    setTimeout(() => {
      sendMoney(requestPayload);
    }, 2000);
  };

  const displaySpinner = () => {
    if (!loadSpinner) setLoadSpinner(true);
    setWalletTransferActive(true);
  };

  const hideSpinner = () => {
    setLoadSpinner(false);
    setWalletTransferActive(false);
  };

  const switchToPinForm = async () => {
    displaySpinner();

    const otpValid = await validateOTP();

    // If OTP is not correct
    if (!otpValid) {
      hideSpinner();
      return;
    } else {
      setTimeout(() => {
        setTransferStage(WALLET_TRANSFER_STAGE.PIN_FORM);
        hideSpinner();
      }, 2000);
    }
  };

  const switchToConfirmPage = async () => {
    displaySpinner();

    // Returns errors from form validation
    const errors = validateForm();
    if (errors.length > 0) {
      displayErrors(errors);
      return;
    }

    try {
      // Get the account profile of the beneficiary
      const profile = await getBeneficiaryProfile(formValues.destination);

      const { responseCode, responsecode } = profile.data;

      // If no profile is found, display an error message
      if (!successResponse([responsecode, responseCode])) {
        toast.dismiss();
        toastFailure(profile.data.resultdescription);
        hideSpinner();
        return;
      }

      // Save the name of the beneficiary to a state variable
      setBeneficiaryName(profile.data.wallet_accts.agentname);

      setTimeout(() => {
        setTransferStage(WALLET_TRANSFER_STAGE.CONFIRMATION_PAGE);
        hideSpinner();
      }, 2000);
    } catch (err: any) {
      hideSpinner();
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);
    }
  };

  const switchToOTPForm = () => {
    displaySpinner();

    // Send OTP to the authorized user
    sendOTP();

    setTimeout(() => {
      setTransferStage(WALLET_TRANSFER_STAGE.OTP_FORM);
      hideSpinner();
    }, 2000);
  };

  const handleOTPChange = (event: any) => {
    const { value } = event.target;

    if (value.length > 0 && Number.isNaN(Number(value))) return;

    setFormValues((prev) => ({ ...prev, otp: value }));
  };

  const setSourceAccount = (event: any) => {
    setFormValues((prev: any) => {
      return {
        ...prev,
        sourceAccountLabel: event.target.selectedOptions[0].innerHTML,
        sourceAccount: event.target.value,
      };
    });
  };

  return (
    <>
      <div className="row w-100 mt-5 justify-content-center align-items-center flex-column mb-3 container-padding">
        <div className="col-md-9 col-lg-7 col-xl-7 mx-auto container-padding">
          <form
            id="form-send-money"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {transferStage === WALLET_TRANSFER_STAGE.AMOUNT_FORM && (
              <>
                <OperationHeader title={OPERATION_HEADERS.GKALA_TRANSFER} />
                {/* <div className="mb-3">
                  <label htmlFor="transferType" className="form-label">
                    Select Transfer Type
                  </label>
                  <select 
                    className="form-select" 
                    name="transferType" 
                    required 
                    onChange={(e) => setSourceAccount(e.target.value) }>
                    {/* <option value="">Transfer Type</option> */}
                {/* {
                      transferTypes.map((transferType) => (<option value={transferType.source}>{transferType.value}</option>))
                    } 
                  </select>
                </div> */}

                <div className="mb-3">
                  <label htmlFor="sourceAccount" className="form-label">
                    Select Source Account
                  </label>
                  <select
                    className="form-select source-account-type"
                    name="sourceAccount"
                    required
                    ref={sourceAccountRef}
                    onChange={(e) => setSourceAccount(e)}
                  >
                    {/* <option value="">Source Account</option> */}
                    <RenderAllUserAccountOptions />
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="form-label">
                    Enter Beneficiary Account Number
                  </label>
                  <input
                    type="text"
                    maxLength={10}
                    placeholder="Account number"
                    className="form-control"
                    aria-label="phoneNumber"
                    name="destination"
                    required
                    value={formValues.destination}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="amount" className="form-label">
                    Enter Amount
                  </label>
                  <input
                    type="text"
                    placeholder="0.00"
                    className="form-control"
                    aria-label="amount"
                    name="amount"
                    // minLength={2}
                    required
                    value={formValues.amount}
                    onChange={onChangeAmountValue}
                  />
                </div>

                <div className="d-grid">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={switchToConfirmPage}
                    disabled={!canTransferMoney}
                  >
                    {loadSpinner ? (
                      <span className="d-flex justify-content-center w-auto">
                        <Spinner />
                      </span>
                    ) : (
                      <Continue />
                    )}
                  </button>
                </div>
              </>
            )}

            {transferStage === WALLET_TRANSFER_STAGE.CONFIRMATION_PAGE && (
              <>
                <Confirmation
                  amount={formValues.amount}
                  operationType={SERVICE_TYPES.WALLET_TRANSFER}
                  number={formValues.destination}
                  beneficiaryName={beneficiaryName}
                  sourceAccount={formValues.sourceAccountLabel}
                />
                <div className="d-grid">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={switchToOTPForm}
                  >
                    {loadSpinner ? (
                      <span className="d-flex justify-content-center w-auto">
                        <Spinner />
                      </span>
                    ) : (
                      <Continue />
                    )}
                  </button>
                </div>
              </>
            )}

            {transferStage === WALLET_TRANSFER_STAGE.OTP_FORM && (
              <>
                <OperationHeader title={OPERATION_HEADERS.GKALA_TRANSFER} />
                <div className="mb-3">
                  <label htmlFor="otp" className="form-label">
                    Enter OTP code
                  </label>
                  <input
                    type="text"
                    maxLength={6}
                    placeholder="OTP"
                    className="form-control"
                    aria-label="otp"
                    name="otp"
                    required
                    value={formValues.otp}
                    onChange={handleOTPChange}
                  />
                </div>
                <div className="d-grid">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={switchToPinForm}
                  >
                    {loadSpinner ? (
                      <span className="d-flex justify-content-center w-auto">
                        <Spinner />
                      </span>
                    ) : (
                      <Continue />
                    )}
                  </button>
                </div>
              </>
            )}

            {transferStage === WALLET_TRANSFER_STAGE.PIN_FORM && (
              <>
                <OperationHeader title={OPERATION_HEADERS.GKALA_TRANSFER} />
                <div className="mb-3">
                  <label htmlFor="pin" className="form-label">
                    Enter Internet Banking PIN
                  </label>
                  <input
                    type="password"
                    maxLength={4}
                    placeholder="PIN"
                    className="form-control"
                    aria-label="pin"
                    name="pin"
                    required
                    value={formValues.pin}
                    onChange={onChangePIN}
                  />
                </div>

                <div className="d-grid">
                  <button
                    className="btn btn-primary complete-transfer"
                    type="button"
                    onClick={handleWalletTransfer}
                  >
                    {loadSpinner ? (
                      <span className="d-flex justify-content-center w-auto">
                        <Spinner />
                      </span>
                    ) : (
                      <span className="d-flex justify-content-center">
                        <i className="fas fa-share-square mb-015 me-2 align-self-center"></i>{" "}
                        Complete Transfer
                      </span>
                    )}
                  </button>
                </div>
              </>
            )}

            {transferStage === WALLET_TRANSFER_STAGE.TRANSACTION_RESPONSE && (
              <TransactionResponse
                amount={formValues.amount}
                operationType={SERVICE_TYPES.WALLET_TRANSFER}
                success={transactionResponse?.success}
                walletbalance={transactionResponse?.walletbalance}
                serverResponse={
                  transactionResponse.resultdescription
                    ? transactionResponse.resultdescription
                    : transactionResponse.responseMessage
                }
                otherProps={{
                  ...formValues,
                  ...transactionResponse,
                  beneficiaryName,
                  agentName: decryptReduxValue(authRedux.user).agentname,
                }}
              />
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default Wallet;
