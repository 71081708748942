import { Link } from "react-router-dom";
import "./styles/sendmoney.scss";

function SendMoney() {
  return (
      <div className="mb-4 p-4 w-100 send-money">
            <div className="row gy-4 profile-completeness w-100 justify-content-center mx-0 container-padding">
              <div className="col-5 col-sm-6 col-md-3">
                <Link to="wallet">
                  <div className="border rounded text-center px-3 py-4" id="wallet-box"> <span className="d-block text-10 text-light mt-2 mb-3"><i className="fas fa-wallet"></i></span> <span className="text-5 d-block text-success mt-4 mb-3"><i className="fas fa-check-circle"></i></span>
                    <p className="mb-0">To MoneyMaster Account</p>
                  </div>
                </Link>
              </div>
              <div className="col-5 col-sm-6 col-md-3">
                <Link to="bank-transfer">
                  <div className="border rounded text-center px-3 py-4" id="bank-transfer-box"> <span className="d-block text-10 text-light mt-2 mb-3"><i className="fas fa-university"></i></span> <span className="text-5 d-block text-success mt-4 mb-3"><i className="fas fa-check-circle"></i></span>
                    <p className="mb-0">To Other Banks</p>
                  </div>
                </Link>
              </div>
            </div>
      </div>
  );
}

export default SendMoney;
