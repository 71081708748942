import { useEffect, useState } from "react";
import { routes } from "../../../../utils/constants";
import { Link } from "react-router-dom";
import './styles/navigationdrawermobile.scss';


function NavigationDrawerMobile(){

    const hideSubMenu = () => { 
        const navbarToggler = document.querySelector(".navbar-toggler") as HTMLDivElement;

        if(navbarToggler) navbarToggler.classList.remove('open');

        // Get the reference to the navigation drawer mobile-sub-menu
        const mobileSubMenu = document.querySelector('.mobile-sub-menu');

        if(!mobileSubMenu) return;

        // if the mobile-sub-menu is visible, hide it
        if(mobileSubMenu.classList.contains('mobile-active-sub-menu')) {
            mobileSubMenu.classList.remove('mobile-active-sub-menu');
        }
    }

    return (
        <>
            {/* Navigation drawer for mobile*/}
            
            <div className="mobile-sub-menu">
                <ul className="px-0">
                    {
                        routes.map((route: any) => {
                            return <li onClick={ hideSubMenu } key={route.main}>
                                <Link to={ route.path }>
                                    <div className="d-flex px-3 py-3 position-relative">
                                        <i className={`fas fa-${route.icon} fs-6`}></i> 
                                        <span className="position-absolute" style={{ left: '3rem', marginTop: "-0.1rem" }}>{ route.main }</span>
                                    </div>
                                </Link>
                            </li>
                        })
                    }
                </ul>
            </div>

          {/* Navigation Drawer End */}
        </>
    )
}

export default NavigationDrawerMobile;