import {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Table from "../Table/Table";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import {
  ACCOUNT_TRAN_TYPE,
  APP_ACCT_TYPES,
  APP_RESPONSES,
  NAIRA_SYMBOL,
  NON_WALLET_ACCOUNT,
  RESPONSE_CODES,
  SYMBOL_TYPES,
  TRANSACTIONS_HISTORY,
  WALLET_ACCOUNT,
} from "../../../../utils/constants";
import useDataInfo from "../../../../hooks/useDataInfo";
import { toast } from "react-toastify";
import { setTranQueryAccount } from "../../../../redux/features/transactionSlice";
import {
  addOneHourToDate,
  decryptReduxValue,
  formatAmount,
  formatTableAmount,
  getAllAccounts,
  successResponse,
} from "../../../../utils/functions";
import RenderAllUserAccountOptions from "../RenderAllUserAccountOptions/RenderAccountOptions";
import Crypto from "../../../../utils/crypto";
import "./styles/transactionshistory.scss";
import { useSearchParams } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";

function TransactionsHistory() {
  const [data, setData] = useState<any[]>([]);
  const { transaction: transactionRedux, userAccounts }: any = useAppSelector(
    (state: RootState) => state
  );
  const userAccountsRedux = useMemo(() => userAccounts, [userAccounts]);
  const { getTransactionHistory, getAllUserAccounts } = useDataInfo();
  const dispatch = useAppDispatch();
  const sourceAccountRef = useRef<any>();
  sourceAccountRef.current = null;
  const columns = useMemo(() => {
    const transactionHeaders = [
      {
        id: 1,
        Header: "Date",
        accessor: (row: any) => {
          const date = moment(row.tran_date).format("MMM Do YYYY, h:mm a");
          return date === "Invalid date"
            ? moment(row.tran_date.split(" ")[0]).format("MMM Do YYYY")
            : date;
        },
      },
      {
        id: 2,
        Header: "Description",
        accessor: "tran_desc",
      },
      {
        id: 3,
        Header: `Credit(${NAIRA_SYMBOL})`,
        accessor: (row: any) => {
          const { tran_type, debit_amount, credit_amount } = row;
          return tran_type === ACCOUNT_TRAN_TYPE.CREDIT
            ? formatTableAmount(credit_amount.toString())
            : "";
        },
      },
      {
        id: 4,
        Header: `Debit(${NAIRA_SYMBOL})`,
        accessor: (row: any) => {
          const { tran_type, debit_amount, credit_amount } = row;
          return tran_type === ACCOUNT_TRAN_TYPE.DEBIT
            ? formatTableAmount(debit_amount.toString())
            : "";
        },
      },
      {
        id: 5,
        Header: "Type",
        accessor: (row: any) => {
          return (
            <div
              className="d-flex w-100 justify-content-center justify-sm-content-start"
              style={{ height: "100%", position: "relative" }}
            >
              <span
                className={`tran-type-container rounded-pill d-flex justify-content-center align-items-center bg-${
                  row.tran_type.toUpperCase() ===
                  ACCOUNT_TRAN_TYPE.CREDIT.toUpperCase()
                    ? "light-success"
                    : "light-danger"
                }`}
              >
                <span
                  className={`tran-type-text fw-700 mod-p-[0.7rem] ${
                    row.tran_type.toUpperCase() ===
                    ACCOUNT_TRAN_TYPE.CREDIT.toUpperCase()
                      ? "tran-type-text-success"
                      : "text-danger"
                  }`}
                >
                  {row.tran_type.toUpperCase()}
                </span>
              </span>
            </div>
          );
        },
      },
      {
        id: 6,
        Header: `Balance(${NAIRA_SYMBOL})`,
        accessor: (row: any) => {
          return (
            <span className="w-100 d-flex justify-content-start">
              {formatTableAmount(row.balance.toString())}
            </span>
          );
        },
      },
    ];

    return transactionHeaders;
  }, [data]);
  const [accountHistoryDetails, setAccountHistoryDetails] = useState({
    accountType: "",
    accountNumber: "",
    balance: 0,
  });
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(new Date().getTime() - 7 * 60 * 60 * 24 * 1000)
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [pageVisitCount, setPageVisitCount] = useState<number>(0);
  const [isDateRangeRequest, setIsDateRangeRequest] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [accountToQuery, setAccountToQuery] = useState({
    accountNumber: "",
    isDefaultPage: false,
  });
  const [searchParams] = useSearchParams();
  const [isDefaultPage, setIsDefaultPage] = useState(false);
  const { getAccountProfile } = useAuth();

  const initStartAndEndDate = () => {
    const startDate = new Date().getTime() - 7 * 60 * 60 * 24 * 1000;
    setStartDate(() => new Date(startDate));
    setEndDate(() => new Date());
  };

  // set default page to be true
  useEffect(() => {
    if (data.length && pageVisitCount === 1) {
      setIsDefaultPage(true);
    }
  }, [data]);

  // Called when coming from Dashboard home page.
  useEffect(() => {
    const accNumber = searchParams.get("q");

    if (accNumber === null) return;

    if (
      !isDateRangeRequest.startDate &&
      sourceAccountRef.current &&
      !isDefaultPage
    ) {
      setDefaultSourceAccount(accNumber); // sets the dropdown value for selected account from dashboard
      setPageVisitCount(1);
      setAccountToQuery({ accountNumber: accNumber, isDefaultPage: false });
      // queryAccount(startDate as Date, endDate as Date, accNumber);
    }
  }, [startDate, endDate, sourceAccountRef.current]);

  // This hook is called when the user selects an account from dashboard page or
  // selects an account from the source account dropdown
  useEffect(() => {
    if (accountToQuery.accountNumber !== "") {
      // const [sD, eD] = resetDate();

      queryAccount(startDate!, endDate!);
    }
  }, [accountToQuery]);

  // This hook is called when the user changes the date (either start date or end date)
  // useEffect(() => {
  //   const { startDate: sD, endDate: eD } = isDateRangeRequest;

  //   if(sD && eD && isDefaultPage){
  //     queryAccount(sD as unknown as Date, eD as unknown as Date);
  //   }

  // },[isDateRangeRequest]);

  const resetDate = () => {
    const startDate = new Date(new Date().getTime() - 7 * 60 * 60 * 24 * 1000);
    const endDate = new Date();

    // The array contains initial default values of startDate and endDate respectively
    return [startDate, endDate];
  };

  const setDefaultSourceAccount = (accNumber: string) => {
    const options: any[] = Array.from(sourceAccountRef.current?.options!);

    for (let x = 0; x < options.length; x++) {
      if (options[x].value === accNumber) {
        sourceAccountRef.current!.selectedIndex = x;
        break;
      }
    }
  };

  const getSourceAccount = () => {
    return accountToQuery.accountNumber;
  };

  const queryAccount = async (
    sD?: Date | undefined,
    eD?: Date | undefined,
    accNumber?: string
  ) => {
    // sD and eD represents startDate and endDate
    if (sD == undefined || eD == undefined) {
      return;
    }

    toast.dismiss();

    toast.info("Fetching transactions", { isLoading: true });

    try {
      // Get the account number from the url(accNumber) via useEffect hook or state variable(getSourceAccount)
      const accountNumber: string = accNumber || getSourceAccount()!;

      const accountDetails: string = decryptReduxValue(
        userAccountsRedux.accounts
      )[accountNumber];

      const accountParams: any = accountDetails.split("|");

      // Show the details of the account history being fetched
      setAccountHistoryDetails({
        accountType: accountParams[1],
        accountNumber: accountParams[0],
        balance: accountParams[2],
      });

      const reqPayload = getReqPayload(
        accountNumber,
        addOneHourToDate(sD),
        addOneHourToDate(eD)
      );

      getTransactionHistory(reqPayload)
        .then(handleServerResponse)
        .catch(handleServerError);
    } catch (error) {
      toast.dismiss();
    }
  };

  const handleServerError = (err: any) => {
    toast.dismiss();
    setIsLoading(false);
    setIsDateRangeRequest({ startDate: "", endDate: "" });
  };

  const handleServerResponse = (response: any) => {
    toast.dismiss();
    setIsLoading(false);

    const { responsecode, responseCode } = response.data;

    if (successResponse([responseCode, responsecode])) {
      setData(response.data.data);

      return;
    } else {
      setData([]);
    }

    setIsDateRangeRequest({ startDate: "", endDate: "" });

    if (false === isDefaultPage) setIsDefaultPage(true);
  };

  const setQueryAccountInfo = (accountNumber: string) => {
    setAccountToQuery({ accountNumber, isDefaultPage: true });
  };

  const getReqPayload = (accountNumber: string, sD: Date, eD: Date) => {
    return {
      startDate: (sD ? sD : startDate)!.toISOString().slice(0, 10),
      endDate: (eD ? eD : endDate)!.toISOString().slice(0, 10),
      accountNumber: accountNumber,
      withDateRange: true,
    };
  };

  const fetchTransactionHistory = (sD: any, eD: any) => {
    queryAccount(sD, eD);
    // setIsDateRangeRequest(prev => ({startDate: sD, endDate:eD}));
  };

  const getTodaysDate = () => {
    return new Date();
  };

  const saveAccountsUpdates = (updates: any) => {
    // Extract and save all user accounts to the redux store
    getAllUserAccounts({ ...updates });
  };

  const fetchUpdatedAccountsInfo = async () => {
    return getAccountProfile(accountToQuery.accountNumber);
  };

  const refreshTranHistory = () => {
    fetchUpdatedAccountsInfo().then((updates) => {
      saveAccountsUpdates(updates.data);

      queryAccount(startDate!, getTodaysDate());
    });
  };

  const getBalanceForSelectedAccount = () => {
    return String(
      decryptReduxValue(userAccountsRedux.accounts)[getSourceAccount()]
    ).split("|")[2];
  };

  return (
    <div
      className="bg-white py-4 mb-4 w-100 container-padding"
      id="recent-activity"
    >
      {/* This section displays detailed information of the account transaction history being retrieved*/}
      {accountHistoryDetails.accountNumber && (
        <div className="d-flex flex-column align-items-start w-100">
          <h3 className="text-start mb-0">
            {accountHistoryDetails.accountType} -{" "}
            {accountHistoryDetails.accountNumber}
          </h3>
          {/* This section displays the balance of the currently selected account */}
          <p className="fs-4 mb-0">
            {NAIRA_SYMBOL}
            {formatTableAmount(getBalanceForSelectedAccount())}
          </p>
        </div>
      )}

      {/* This dropdown lists all the account types of the user for which a transaction history can be fetched */}
      <div className="row flex-sm-column flex-md-row justify-content-start px-0">
        <label htmlFor="account" className="form-label text-start px-0">
          Select Account
        </label>
        <div className="col-12 mb-3 d-flex justify-content-start align-items-center select-account-container px-0">
          <select
            className="form-select account-options"
            name="account"
            required
            ref={sourceAccountRef}
            onChange={(e) => {
              setQueryAccountInfo(e.target.value);
            }}
          >
            {/* <option value={""}>Source Account</option> */}
            <RenderAllUserAccountOptions />
          </select>

          <button
            onClick={refreshTranHistory}
            className="btn btn-primary btn-sm ms-3 refresh-tran-history-btn"
            style={{
              height: "3rem",
              backgroundColor: "#2dbe60",
              fontSize: "1rem",
              padding: "1rem 2rem",
              width: "auto",
            }}
          >
            <i
              className="fas fa-sync me-2"
              style={{ height: "0.875rem", width: "0.875rem" }}
            ></i>{" "}
            Load
          </button>
        </div>
      </div>

      <hr className="before-date-pickers" />

      <Table
        isLoading={isLoading}
        fetchTransactionHistory={fetchTransactionHistory}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setIsDateRangeRequest={setIsDateRangeRequest}
        columns={columns}
        data={data}
      />
    </div>
  );
}

export default TransactionsHistory;
