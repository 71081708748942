import "./styles/operation-response.scss";

function OperationResponse(props: any) {


  const FormulateMessage = (allProps: any) => {
    let TransResIconAndText;

    const {
      success,
      transResBody,
      resetOperationProcess
    } = allProps;


    // let truthy = "true";

    // Choose icon based on operation status
    switch(success){
      case true:
        TransResIconAndText = () => (<>
          <p className="text-success text-10 lh-1"><i className="fas fa-check-circle"></i></p>
          <p className="text-success text-6 fw-500 lh-1 mb-0">Password Successfully Changed!</p>
        </>)
        break;
      case false:
        TransResIconAndText = () => (<>
          <p className="text-danger text-10 lh-1"><i className="fas fa-exclamation-triangle"></i></p>
          <p className="text-danger text-6 fw-500 lh-1 mb-0">Operation Failed!</p>
        </>)
      break;
      default:
        TransResIconAndText = () => (<>
          <p className="text-danger text-10 lh-1"><i className="fas fa-exclamation-triangle"></i></p>
          <p className="text-danger text-6 fw-500 lh-1 mb-0">Operation Failed!</p>
        </>)
        break;
    }    

    return <>
      <div className="text-center mb-4 transaction-response">
        <div className="wrapper py-2 px-2">
          <div className="pt-2 mb-2">
            <TransResIconAndText />
          </div>

          <div className="divider mb-2">
            <span></span><span></span><span></span>
          </div>
          
          <p className="text-2 text-center response-body">
            { transResBody }
          </p>

          <p className='text-center pt-3'><button className='btn btn-primary w-auto' style={{ padding: '0.5rem 1rem !important' }} onClick={resetOperationProcess}>Back to Homepage</button></p>
        </div>
      </div>
    </>
  }

  return <FormulateMessage {...props} />
  
}

export default OperationResponse;
